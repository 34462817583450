import { ReferralStatusIndicator } from '@strata/react-status-indicators';
import PropTypes from 'prop-types';

const StatusTooltipMap = {
    [ReferralStatusIndicator.Statuses.PHARM]: 'Active referral that a pharmacy is servicing.',
    [ReferralStatusIndicator.Statuses.EXPIRED]: 'Referral is no longer active.',
    [ReferralStatusIndicator.Statuses.PENDREAUTH]:
        'The auth for this referral expires within 30 days.',
    [ReferralStatusIndicator.Statuses.DISCONTD]: 'Patient therapy was discontinued.',
};

export const ApertureReferralStatusIndicator = ({ status = undefined }) => (
    <ReferralStatusIndicator status={status} overrideTooltipText={StatusTooltipMap[status]} />
);

ApertureReferralStatusIndicator.propTypes = {
    status: PropTypes.oneOf(Object.values(ReferralStatusIndicator.Statuses)),
};
