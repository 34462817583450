import { ProfileDialog } from '@strata/react-dialog';
import { Grid, PrimaryData } from '@strata/react-grid';
import { SuccessToast } from '@strata/react-toast-message';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { useGetUserProfileQuery, usePutUserProfileMutation } from 'store/api/authApi';
import { useGetSegmentsQuery } from 'store/api/referenceApi';

const columns = [
    {
        accessorKey: 'organizationName',
        header: 'Segment Name',
        cell: ({ getValue }) => <PrimaryData data={getValue()} />,
    },
    {
        accessorKey: 'role',
        header: 'Role',
    },
];

export const UserProfileDialog = ({ isOpen, onSecondaryButtonClick }) => {
    const { payerId } = useSelector((state) => state.auth);

    const { data: profile, isFetching: isProfileFetching } = useGetUserProfileQuery();
    const [putUserProfile] = usePutUserProfileMutation();
    const { data: segments, isFetching: isSegmentsFetching } = useGetSegmentsQuery({ payerId });

    const organizations = profile?.segmentIds?.map((segmentId) => ({
        organizationName: segments?.find((segment) => segment.id === segmentId)?.name,
        role: profile.role,
    }));

    return (
        <ProfileDialog
            isOpen={isOpen}
            isLoading={isProfileFetching || isSegmentsFetching}
            onSecondaryButtonClick={onSecondaryButtonClick}
            onSubmit={async (values) => {
                await putUserProfile(values).unwrap();
                SuccessToast({ message: 'Your profile was updated successfully.' });
                onSecondaryButtonClick();
            }}
            userFullName={profile?.fullName}
            userEmailAddress={profile?.email}
            additionalDialogContent={
                organizations && (
                    <Grid
                        data={organizations}
                        sortProperties={[{ id: 'organizationName', desc: false }]}
                        columns={columns}
                    />
                )
            }
        />
    );
};

UserProfileDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSecondaryButtonClick: PropTypes.func.isRequired,
};
