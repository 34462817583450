import { FormSection } from '@strata/react-form-section';
import { ReferralTimeline } from '@strata/react-timeline';
import * as PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LiveReferralDetails } from 'components/referrals/LiveReferralDetails';
import { MemberDetails } from 'components/referrals/MemberDetails';
import { PharmacyDetails } from 'components/referrals/PharmacyDetails';
import { PrescriberDetails } from 'components/referrals/PrescriberDetails';
import { ReferralDetails } from 'components/referrals/ReferralDetails';
import { useGetPrescriberDetailsQuery, useGetSupplierDetailsQuery } from 'store/api/referenceApi';
import {
    fetchReferralDetails,
    fetchReferralMilestones,
} from 'store/referrals/referralDetailsSlice';

export const ReferralDetailsOverview = ({ referralId, showMemberDetails = false }) => {
    const dispatch = useDispatch();
    const { payerId } = useSelector((state) => state.auth);

    const referralDetails = useSelector(
        (state) => state.referralDetails.referralDetailsData[referralId],
    );
    const referralMilestones = useSelector(
        (state) => state.referralDetails.referralMilestones[referralId],
    );
    const { data: supplierDetails, isFetching: isSupplierDetailsLoading } =
        useGetSupplierDetailsQuery(
            { npi: referralDetails?.supplierNpi },
            { skip: !referralDetails?.supplierNpi },
        );
    const { data: prescriberDetails, isFetching: isPrescriberDetailsLoading } =
        useGetPrescriberDetailsQuery(
            { npi: referralDetails?.prescriberNpi },
            { skip: !referralDetails?.prescriberNpi },
        );

    useEffect(() => {
        if (!referralDetails) {
            dispatch(fetchReferralDetails({ payerId, referralId }));
        }
    }, [dispatch, referralDetails, payerId, referralId]);

    useEffect(() => {
        if (referralDetails?.referralId) {
            dispatch(fetchReferralMilestones({ payerId, referralId: referralDetails.referralId }));
        }
    }, [dispatch, payerId, referralDetails]);

    return (
        <div className="sds_referral-details-overview">
            <div className="sds_referral-details-overview__container" style={{ flex: '2 0' }}>
                {showMemberDetails && <MemberDetails referralDetails={referralDetails} />}
                <LiveReferralDetails referralDetails={referralDetails} />
                <ReferralDetails referralDetails={referralDetails} />
                <div className="sds_details-page-overview__container--row">
                    <PrescriberDetails
                        prescriberDetails={prescriberDetails}
                        isLoading={isPrescriberDetailsLoading}
                    />
                    <PharmacyDetails
                        supplierDetails={supplierDetails}
                        isLoading={isSupplierDetailsLoading}
                        referralStatus={referralDetails?.referralStatus}
                    />
                </div>
            </div>
            <div className="sds_referral-details-overview__container" style={{ flex: '1 0' }}>
                <FormSection
                    sectionTitle="Referral Timeline"
                    isLoading={!referralMilestones}
                    content={<ReferralTimeline referralMilestones={referralMilestones} />}
                />
            </div>
        </div>
    );
};

ReferralDetailsOverview.propTypes = {
    referralId: PropTypes.string.isRequired,
    showMemberDetails: PropTypes.bool,
};
