import { addCommas } from '@strata/helper-functions';
import { FormPageContainer } from '@strata/react-form-header-and-content';
import { GridWithFilters, PrimaryData, SecondaryData } from '@strata/react-grid';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TimeFrameBanner } from 'components/shared/TimeframeBanner';
import { ViewsControls } from 'components/shared/ViewsControls';
import { AppRoutes } from 'services/routes';
import { useGetPrescribersQuery } from 'store/api/apertureApi';
import { useGetPayerDetailsQuery } from 'store/api/referenceApi';
import { Grids, useGridState } from 'store/grids/gridsSlice';

const initialSortProperties = [{ id: 'name', desc: false }];

const kpiData = [
    {
        label: 'Prescribers',
        value: (data) => data?.length,
    },
    {
        label: 'Active Referrals',
        value: (data) =>
            data?.reduce((total, prescriber) => total + (prescriber.referrals ?? 0), 0),
    },
];

const columns = [
    {
        accessorKey: 'name',
        header: 'Prescriber',
        size: 200,
        cell: ({ getValue }) => <PrimaryData data={getValue()} />,
    },
    {
        accessorKey: 'referrals',
        header: 'Active Referrals',
        size: 120,
        cell: ({ getValue }) => <SecondaryData data={addCommas(getValue())} />,
    },
    {
        accessorKey: 'specialization',
        header: 'Specialty',
        size: 120,
    },
    {
        accessorKey: 'topDrugName',
        header: 'Most Prescribed Product',
        size: 120,
    },
];

export const PrescribersPage = () => {
    const navigate = useNavigate();
    const { payerId } = useSelector((state) => state.auth);

    const { data: payerDetails } = useGetPayerDetailsQuery({ payerId });

    const { segmentId } = useSelector((state) => state.views);
    const { data, isFetching } = useGetPrescribersQuery({ segmentId });

    const {
        sort,
        search,
        activeFilters,
        filterPaneWidth,
        updateGridSort,
        updateGridSearch,
        updateGridActiveFilters,
        updateFilterPaneWidth,
    } = useGridState(Grids.PRESCRIBERS, initialSortProperties);

    return (
        <FormPageContainer>
            <GridWithFilters
                title="Prescribers"
                secondaryTitle={payerDetails?.payerName}
                headerContent={<TimeFrameBanner />}
                viewsPaneContent={<ViewsControls />}
                data={data}
                isLoading={isFetching}
                sortProperties={sort}
                onSort={updateGridSort}
                enableSearch
                searchText={search}
                onSearch={updateGridSearch}
                activeFilters={activeFilters}
                onFilter={updateGridActiveFilters}
                filterPaneRenderWidth={filterPaneWidth}
                onFilterPaneResize={updateFilterPaneWidth}
                kpiData={kpiData}
                onRowClick={(rowData) =>
                    navigate(AppRoutes.prescriberDetails(rowData.npi), {
                        state: {
                            backToDestination: {
                                text: 'Prescribers',
                                route: AppRoutes.prescribers,
                            },
                        },
                    })
                }
                columns={columns}
            />
        </FormPageContainer>
    );
};
