import { createApi } from '@reduxjs/toolkit/query/react';

import { ANNOUNCEMENT_API_URL } from 'api/APIBase';
import { handleRTKQueryError } from 'services/Error';
import { getBaseQuery } from 'store/api/apiBaseQuery';

const TagType = { ANNOUNCEMENTS: 'ANNOUNCEMENTS' };

export const announcementApi = createApi({
    baseQuery: getBaseQuery(ANNOUNCEMENT_API_URL),
    refetchOnMountOrArgChange: true,
    reducerPath: 'announcementApi',
    tagTypes: Object.values(TagType),
    endpoints: (build) => ({
        getHomeScreenMessages: build.query({
            query: () => 'home_screen/APERTURE',
            providesTags: [TagType.ANNOUNCEMENTS],
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving home screen messages.',
            ),
        }),
        postCarouselViewed: build.mutation({
            query: ({ messageId }) => ({
                url: 'carousel_views',
                method: 'POST',
                body: { message_id: messageId },
            }),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred confirming carousel was viewed.',
            ),
            invalidatesTags: (_, error) => (!error ? [TagType.ANNOUNCEMENTS] : undefined),
        }),
    }),
});

export const { useGetHomeScreenMessagesQuery, usePostCarouselViewedMutation } = announcementApi;
