import { IconNames } from '@blueprintjs/icons';
import { formatDate } from '@strata/helper-functions';
import { Grid, SecondaryData } from '@strata/react-grid';
import { MessagingBlock } from '@strata/react-messaging-block';
import PropTypes from 'prop-types';

import { useGetPrescriberDetailsQuery } from 'store/api/referenceApi';

const initialSortProperties = [{ id: 'authStart', desc: true }];

/* eslint-disable react/prop-types */
const NPICell = ({ getValue }) => {
    const { data: prescriberDetails } = useGetPrescriberDetailsQuery(
        { npi: getValue() },
        { skip: !getValue() },
    );

    const getDisplayText = (npi) => {
        if (prescriberDetails?.name) {
            return `${prescriberDetails.name} (${npi})`;
        }
        return npi;
    };

    return <SecondaryData data={getDisplayText(getValue()?.trim())} />;
};
/* eslint-enable react/prop-types */

const columns = [
    {
        accessorKey: 'authStart',
        header: 'Auth Start',
        size: 110,
        cell: ({ getValue }) => <SecondaryData data={formatDate(getValue())} />,
    },
    {
        accessorKey: 'authExpiration',
        header: 'Auth End',
        size: 110,
        cell: ({ getValue }) => <SecondaryData data={formatDate(getValue())} />,
    },
    {
        accessorKey: 'caseId',
        header: 'Auth ID',
        size: 150,
        enableSorting: false,
    },
    {
        accessorKey: 'requestingProvider',
        header: 'Requesting Provider',
        size: 150,
        enableSorting: false,
        cell: NPICell,
    },
];

export const AuthDetails = ({ auths = undefined, isLoading = true }) => (
    <Grid
        data={auths}
        isLoading={isLoading}
        sortProperties={initialSortProperties}
        noResults={
            <div className="padding__around--large">
                <MessagingBlock
                    size={MessagingBlock.Sizes.SMALL}
                    icon={IconNames.DOCUMENT}
                    primaryText="No Authorizations available"
                />
            </div>
        }
        columns={columns}
    />
);

AuthDetails.propTypes = {
    auths: PropTypes.arrayOf(
        PropTypes.shape({
            caseId: PropTypes.string,
            authStart: PropTypes.string,
            authExpiration: PropTypes.string,
            billingProvider: PropTypes.string,
            servicingProvider: PropTypes.string,
            requestingProvider: PropTypes.string,
            servicingFacility: PropTypes.string,
        }),
    ),
    isLoading: PropTypes.bool,
};
