import { formatPhoneNumber } from '@strata/helper-functions';
import { FormSectionWithMessage, FormSectionWithRows } from '@strata/react-form-section';
import { MailToHyperlink } from '@strata/react-hyperlink';
import { AddressDetailLabelValue, DetailLabelValuePair } from '@strata/react-label-value-pairs';
import { FIcon } from '@strata/react-status-icons';
import PropTypes from 'prop-types';

import { ReferralStatusMessageMap } from 'services/referralHelpers';

export const PharmacyDetails = ({
    supplierDetails = undefined,
    isLoading = true,
    referralStatus = undefined,
}) => {
    const referralStatusMessage = ReferralStatusMessageMap[referralStatus];

    if (referralStatusMessage) {
        return (
            <FormSectionWithMessage
                sectionTitle="Pharmacy Details"
                icon={<FIcon />}
                primaryText={referralStatusMessage}
                secondaryText="No details to show."
            />
        );
    }
    return (
        <FormSectionWithRows
            sectionTitle="Pharmacy Details"
            isLoading={isLoading}
            rowOneContent={
                <>
                    <DetailLabelValuePair
                        style={{ width: '50%' }}
                        label="Pharmacy"
                        value={supplierDetails?.name}
                    />
                    <DetailLabelValuePair
                        label="Phone"
                        value={formatPhoneNumber(supplierDetails?.phone)}
                    />
                </>
            }
            rowTwoContent={
                <>
                    <DetailLabelValuePair
                        style={{ width: '50%' }}
                        label="Fax"
                        value={formatPhoneNumber(supplierDetails?.fax)}
                    />
                    <DetailLabelValuePair
                        label="Email"
                        value={<MailToHyperlink emailAddress={supplierDetails?.email} />}
                    />
                </>
            }
            rowThreeContent={
                <AddressDetailLabelValue
                    label="Pharmacy Address"
                    address1={supplierDetails?.address1}
                    address2={supplierDetails?.address2}
                    city={supplierDetails?.city}
                    state={supplierDetails?.state}
                    zip={supplierDetails?.zip}
                />
            }
        />
    );
};

PharmacyDetails.propTypes = {
    supplierDetails: PropTypes.shape({
        name: PropTypes.string,
        phone: PropTypes.string,
        fax: PropTypes.string,
        email: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
    referralStatus: PropTypes.string,
};
