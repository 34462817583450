import { GridWithFilters } from '@strata/react-grid';
import { InlineMessage } from '@strata/react-inline-message';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import {
    referralGridColumns,
    referralGridOnRowClick,
    referralGridRowCssClass,
} from 'components/shared/ReferralGridLayout';
import { ViewsControls } from 'components/shared/ViewsControls';
import { Grids, resetGrid, useGridState } from 'store/grids/gridsSlice';
import { fetchReferrals, resetReferralData, updatePage } from 'store/referrals/referralListSlice';

/** Note: only one of supplierNpi or prescriberNpi should be supplied */
export const ReferralGrid = ({
    supplierNpi = undefined,
    prescriberNpi = undefined,
    title = undefined,
    parentLocationName,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { permissions } = useSelector((state) => state.auth);

    const { segmentId } = useSelector((state) => state.views);

    const { pages, currentPage, referralCount, npi, filteredCount, filterCategories } = useSelector(
        (state) => state.referralList,
    );
    const currentPageData = pages[currentPage];

    useEffect(() => {
        if (npi !== (supplierNpi ?? prescriberNpi) && currentPageData) {
            dispatch(resetGrid(Grids.REFERRALS));
            dispatch(resetReferralData());
        }
    }, [dispatch, npi, supplierNpi, prescriberNpi, currentPageData]);

    const {
        search,
        activeFilters,
        filterPaneWidth,
        updateGridSearch,
        updateGridActiveFilters,
        updateFilterPaneWidth,
    } = useGridState(Grids.REFERRALS);

    const [searching, setSearching] = useState(false);
    useEffect(() => {
        const handler = setTimeout(() => {
            if (searching) {
                setSearching(false);
                dispatch(resetReferralData());
            }
        }, 1000);
        return () => clearTimeout(handler);
    }, [search, searching, dispatch]);

    useEffect(() => {
        if (!currentPageData) {
            dispatch(
                fetchReferrals({
                    segmentId,
                    searchText: search,
                    supplierNpi,
                    prescriberNpi,
                    activeFilters,
                }),
            );
        }
    }, [dispatch, currentPageData, search, supplierNpi, prescriberNpi, segmentId, activeFilters]);

    const backToDestination = {
        text: parentLocationName,
        route: location.pathname,
    };

    return (
        <GridWithFilters
            title={title}
            headerContent={
                filteredCount > 0 ? (
                    <div className="margin__bottom--medium">
                        <InlineMessage
                            intent={InlineMessage.Intent.INFO}
                            label={`The ${referralCount} referrals viewable below do not include ${filteredCount} restricted referrals hidden from view.`}
                        />
                    </div>
                ) : undefined
            }
            viewsPaneContent={<ViewsControls />}
            data={currentPageData}
            isLoading={!currentPageData || searching}
            enableSorting={false}
            enableSearch
            searchText={search}
            onSearch={(searchText) => {
                setSearching(true);
                updateGridSearch(searchText);
            }}
            filterCategories={filterCategories}
            activeFilters={activeFilters}
            onFilter={(filters) => {
                setSearching(true);
                updateGridActiveFilters(filters);
            }}
            filterPaneRenderWidth={filterPaneWidth}
            onFilterPaneResize={updateFilterPaneWidth}
            currentPage={currentPage}
            totalCount={referralCount}
            onPageChange={(page) => dispatch(updatePage(page))}
            onRowClick={referralGridOnRowClick(navigate, backToDestination)}
            rowCssClass={referralGridRowCssClass}
            columns={referralGridColumns(permissions, backToDestination)}
        />
    );
};

ReferralGrid.propTypes = {
    supplierNpi: PropTypes.string,
    prescriberNpi: PropTypes.string,
    title: PropTypes.string,
    parentLocationName: PropTypes.string.isRequired,
};
