import { FormPageContainer } from '@strata/react-form-header-and-content';
import { GridWithFilters } from '@strata/react-grid';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ProductStatsColumns } from 'components/products/ProductStatsColumns';
import { ProductCell } from 'components/shared/ProductCell';
import { TimeFrameBanner } from 'components/shared/TimeframeBanner';
import { ViewsControls } from 'components/shared/ViewsControls';
import { AppRoutes } from 'services/routes';
import { useGetMedicationsQuery } from 'store/api/apertureApi';
import { useGetPayerDetailsQuery } from 'store/api/referenceApi';
import { Grids, useGridState } from 'store/grids/gridsSlice';

const initialSortProperties = [{ id: 'drugName', desc: false }];

const filterCategories = [
    { key: 'therapeuticCategory', title: 'Therapeutic Category' },
    { key: 'drugName', title: 'Product' },
    {
        key: 'isBrand',
        title: 'Brand/Generic',
        valueDisplayTextMap: { true: 'Brand', false: 'Generic' },
    },
];

const kpiData = [
    {
        label: 'Active Referrals',
        value: (data) =>
            data?.reduce((total, product) => total + (product.numberOfReferrals ?? 0), 0),
    },
];

const columns = [
    {
        accessorKey: 'drugName',
        header: 'Product',
        size: 150,
        cell: ({ row, getValue }) => (
            <ProductCell product={getValue()} isBrand={row.original.isBrand} />
        ),
    },
    ...ProductStatsColumns,
];

export const ProductsPage = () => {
    const navigate = useNavigate();
    const { payerId } = useSelector((state) => state.auth);

    const { data: payerDetails } = useGetPayerDetailsQuery({ payerId });

    const { segmentId } = useSelector((state) => state.views);
    const { data, isFetching } = useGetMedicationsQuery({ segmentId });

    const {
        sort,
        search,
        activeFilters,
        filterPaneWidth,
        updateGridSort,
        updateGridSearch,
        updateGridActiveFilters,
        updateFilterPaneWidth,
    } = useGridState(Grids.PRODUCTS, initialSortProperties);

    return (
        <FormPageContainer>
            <GridWithFilters
                title="Products"
                secondaryTitle={payerDetails?.payerName}
                headerContent={<TimeFrameBanner />}
                viewsPaneContent={<ViewsControls />}
                data={data}
                isLoading={isFetching}
                sortProperties={sort}
                onSort={updateGridSort}
                enableSearch
                searchText={search}
                onSearch={updateGridSearch}
                filterCategories={filterCategories}
                activeFilters={activeFilters}
                onFilter={updateGridActiveFilters}
                filterPaneRenderWidth={filterPaneWidth}
                onFilterPaneResize={updateFilterPaneWidth}
                kpiData={kpiData}
                onRowClick={(rowData) =>
                    navigate(
                        AppRoutes.productDetails(
                            rowData.productServiceOid,
                            rowData.isBrand,
                            rowData.referentPriceType,
                        ),
                    )
                }
                columns={columns}
            />
        </FormPageContainer>
    );
};
