import { Auth0Provider } from '@auth0/auth0-react';
import { BlankScreen } from '@strata/react-page-templates';
import PropTypes from 'prop-types';

import { useAuth0Info } from 'services/environment';
import { AppRoutes } from 'services/routes';

export const AuthProvider = ({ children }) => {
    const AUTH0_INFO = useAuth0Info();
    return !AUTH0_INFO?.clientId ? (
        <BlankScreen />
    ) : (
        <Auth0Provider
            domain={AUTH0_INFO.domain}
            clientId={AUTH0_INFO.clientId}
            authorizationParams={{
                audience: AUTH0_INFO.audience,
                redirect_uri: `${window.location.origin}${AppRoutes.callback}`,
            }}
        >
            {children}
        </Auth0Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
