import { createApi } from '@reduxjs/toolkit/query/react';

import { PERFORMANCE_API_URL } from 'api/APIBase';
import { handleRTKQueryError } from 'services/Error';
import { getBaseQuery } from 'store/api/apiBaseQuery';

/**
 * A mapped Needs By Date object.
 * @typedef {Object} NeedsByDate
 * @property {string} needsByDate - The needs by date.
 */

export const performanceApi = createApi({
    baseQuery: getBaseQuery(PERFORMANCE_API_URL),
    reducerPath: 'performanceApi',
    endpoints: (build) => ({
        getNeedsByDate: build.query({
            query: ({ payerId, treatmentId }) => ({
                url: `payers/needs_by_date/${treatmentId}`,
                headers: { 'x-npi': payerId },
            }),
            /**
             * Maps a needs by date from the API to a needs by date object.
             * @param {Object} response - The response to map.
             * @returns {NeedsByDate} The mapped needs by date object.
             */
            transformResponse: (response) => ({
                needsByDate: response?.needs_by_date,
            }),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving needs by date for the treatment.',
            ),
        }),
    }),
});

export const { useGetNeedsByDateQuery } = performanceApi;
