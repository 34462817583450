const encodeParam = (param, routerKey) =>
    param != null ? encodeURIComponent(param) : `:${routerKey}`;

export const AppRoutes = {
    callback: '/callback',
    loggedOut: '/logged-out',
    home: '/home',
    products: '/products',
    productDetails: (
        oid = ':oid',
        isBrand = ':isBrand',
        referentPriceType = ':referentPriceType',
    ) => `/products/${oid}/${isBrand}/${referentPriceType}`,
    prescribers: '/prescribers',
    prescriberDetails: (npi = ':npi') => `/prescribers/${npi}`,
    treatments: '/treatments',
    treatmentDetails: (treatmentId) => `/treatments/${encodeParam(treatmentId, 'treatmentId')}`,
    referrals: '/referrals',
    referralDetails: (referralId = ':referralId') => `/referrals/${referralId}`,
    pharmacies: '/pharmacies',
    pharmacyDetails: (npi = ':npi') => `/pharmacies/${npi}`,
    requests: '/requests',
    requestDetails: (requestId = ':requestId') => `/requests/${requestId}`,
    clinicalPolicies: '/admin/policies',
    clinicalPolicyDetails: (draftId = ':draftId') => `/admin/policies/${draftId}`,
    termsOfUse: '/terms-of-use',
    privacyPolicy: '/privacy-policy',
    updatesHistory: '/updates-history',
};
