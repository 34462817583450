import { RequestTypeDisplayTextMap } from '@strata/react-chat';
import { CheckboxListFormik } from '@strata/react-checkbox-list';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { NotificationTypes } from 'components/notifications/NotificationComponentMap';
import { ReadPermissionRequestTypeMap } from 'services/constants';

const PayerRequestSummaryCategoryOptions = (permissions) =>
    Object.entries(ReadPermissionRequestTypeMap)
        .filter(([permission]) => permissions.includes(permission))
        .map(([_, value]) => ({ value, text: `${RequestTypeDisplayTextMap[value]} Requests` }));

export const PayerRequestSummarySettings = () => {
    const formik = useFormikContext();
    const { permissions } = useSelector((state) => state.auth);

    const options = PayerRequestSummaryCategoryOptions(permissions);

    return options.length > 1 ? (
        <div className="padding__bottom--medium">
            <CheckboxListFormik
                name="payerRequestSummaryTypes"
                options={options}
                disabled={
                    formik.values.email?.includes(NotificationTypes.PAYREQSUM_APERTURE) === false
                }
                onChange={(values) => {
                    (async () => {
                        await formik.setFieldValue('payerRequestSummaryTypes', values);
                        if (values.length === 0) {
                            await formik.setFieldValue(
                                'email',
                                formik.values.email?.filter(
                                    (item) => item !== NotificationTypes.PAYREQSUM_APERTURE,
                                ),
                            );
                        }
                    })();
                }}
                inline
            />
        </div>
    ) : null;
};

PayerRequestSummarySettings.Options = PayerRequestSummaryCategoryOptions;

PayerRequestSummarySettings.initialValues = (preferences) => ({
    payerRequestSummaryTypes:
        preferences?.payer_request_types?.find(
            (item) => item.code === NotificationTypes.PAYREQSUM_APERTURE,
        )?.types ?? [],
});

PayerRequestSummarySettings.submitValues = (values) => ({
    payer_request_types: [
        {
            code: NotificationTypes.PAYREQSUM_APERTURE,
            types: values.payerRequestSummaryTypes,
        },
    ],
});
