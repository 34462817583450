import { formatDate } from '@strata/helper-functions';
import { ActionButtonText } from '@strata/react-buttons';
import { ButtonWithDropdownMenu } from '@strata/react-dropdown-menu';
import { FormHeaderAndTabbedContent } from '@strata/react-form-header-and-content';
import { FormSection } from '@strata/react-form-section';
import { InlineMessage } from '@strata/react-inline-message';
import { FormTitleLabelValuePair, HeaderLabelValuePair } from '@strata/react-label-value-pairs';
import { QuestionForm, QuestionGraph } from '@strata/react-question-tree';
import { ClinicalPolicyStatusIndicator } from '@strata/react-status-indicators';
import { Tooltip } from '@strata/react-tooltip';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ApprovePolicyDialog } from 'components/admin/dialogs/ApprovePolicyDialog';
import { RequestChangesDialog } from 'components/admin/dialogs/RequestChangesDialog';
import { ViewRequestedChangesDialog } from 'components/admin/dialogs/ViewRequestedChangesDialog';
import { PayerPolicyStatusIndicator } from 'components/admin/PayerPolicyStatusIndicator';
import { AppRoutes } from 'services/routes';
import { fetchPolicyDetails, usePolicyListData } from 'store/policies/policiesSlice';

export const ClinicalPolicyDetailsPage = () => {
    const { draftId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { payerId } = useSelector((state) => state.auth);

    const policyListData = usePolicyListData();
    const policyData = policyListData?.find((policy) => policy.draftId === draftId);

    // navigate back to policy list if draftId is invalid
    useEffect(() => {
        if (policyListData && !policyData) {
            navigate(AppRoutes.clinicalPolicies, { replace: true });
        }
    }, [policyListData, policyData, navigate]);

    const policyDetails = useSelector((state) => state.policies.policyDetails[draftId]);
    useEffect(() => {
        dispatch(fetchPolicyDetails({ payerId, draftId }));
    }, [dispatch, payerId, draftId]);

    const [showApprovePolicyDialog, setShowApprovePolicyDialog] = useState(false);
    const [showRequestChangesDialog, setShowRequestChangesDialog] = useState(false);
    const [showViewRequestedChangesDialog, setShowViewRequestedChangesDialog] = useState(false);

    const menuItems = [
        ...(policyData?.policyStatus === ClinicalPolicyStatusIndicator.Statuses.EXTREVIEW
            ? [
                  { text: 'Approve Policy', onItemClick: () => setShowApprovePolicyDialog(true) },
                  { text: 'Request Changes', onItemClick: () => setShowRequestChangesDialog(true) },
              ]
            : []),
        ...(policyDetails?.changeRequests.length > 0
            ? [
                  {
                      text: 'View Requested Changes',
                      onItemClick: () => setShowViewRequestedChangesDialog(true),
                  },
              ]
            : []),
    ];

    const [testAnswers, setTestAnswers] = useState([]);

    const showApprovedHeaderDetails =
        policyData?.policyStatus === ClinicalPolicyStatusIndicator.Statuses.EXTAPPROVE ||
        policyData?.policyStatus === ClinicalPolicyStatusIndicator.Statuses.PEND_PROD ||
        policyData?.policyStatus === ClinicalPolicyStatusIndicator.Statuses.IN_PROD;

    return (
        <>
            <ApprovePolicyDialog
                isOpen={showApprovePolicyDialog}
                onSecondaryButtonClick={() => setShowApprovePolicyDialog(false)}
                policyData={policyData}
            />
            <RequestChangesDialog
                isOpen={showRequestChangesDialog}
                onSecondaryButtonClick={() => setShowRequestChangesDialog(false)}
                policyData={policyData}
            />
            <ViewRequestedChangesDialog
                isOpen={showViewRequestedChangesDialog}
                onClose={() => setShowViewRequestedChangesDialog(false)}
                policyDetails={policyDetails}
            />
            <FormHeaderAndTabbedContent
                rowOneContentHeader={
                    <>
                        <ActionButtonText
                            label="Back To Clinical Policies"
                            isBackNavigationButton
                            onClick={() => navigate(AppRoutes.clinicalPolicies)}
                        />
                        {menuItems.length > 0 && (
                            <div style={{ marginLeft: 'auto' }}>
                                <ButtonWithDropdownMenu
                                    label="Actions"
                                    menuItems={menuItems}
                                    intent={ButtonWithDropdownMenu.Intent.PRIMARY}
                                />
                            </div>
                        )}
                    </>
                }
                rowTwoContentHeader={
                    policyData && (
                        <>
                            <FormTitleLabelValuePair
                                label={policyData.productName}
                                value={
                                    <Tooltip
                                        className="sds_clinical-policy__diagnosis-header"
                                        content={policyData.diagnoses}
                                    >
                                        {policyData.diagnoses}
                                    </Tooltip>
                                }
                            />
                            <HeaderLabelValuePair
                                label="Status"
                                value={
                                    <PayerPolicyStatusIndicator status={policyData.policyStatus} />
                                }
                            />
                            <HeaderLabelValuePair
                                label="Effective Date"
                                value={formatDate(policyData.effectiveDate)}
                            />
                            {policyData.assignedTo && (
                                <HeaderLabelValuePair
                                    label="Assigned To"
                                    value={policyData.assignedTo}
                                />
                            )}
                            {showApprovedHeaderDetails && (
                                <>
                                    <HeaderLabelValuePair
                                        label="Approved Date"
                                        value={formatDate(
                                            policyDetails?.statusHistory?.find(
                                                (change) =>
                                                    change.policy_status ===
                                                    ClinicalPolicyStatusIndicator.Statuses
                                                        .EXTAPPROVE,
                                            )?.status_date,
                                        )}
                                    />
                                    <HeaderLabelValuePair
                                        label="Approved By"
                                        value={
                                            policyDetails?.statusHistory?.find(
                                                (change) =>
                                                    change.policy_status ===
                                                    ClinicalPolicyStatusIndicator.Statuses
                                                        .EXTAPPROVE,
                                            )?.username
                                        }
                                    />
                                </>
                            )}
                        </>
                    )
                }
                rowThreeContentHeader={
                    policyData?.policyStatus ===
                        ClinicalPolicyStatusIndicator.Statuses.EXTREVIEW && (
                        <InlineMessage
                            intent={InlineMessage.Intent.WARNING}
                            label="This policy is pending review, approve or request changes from the actions menu."
                        />
                    )
                }
                tabs={[
                    {
                        title: 'Clinical Policy Logic',
                        mainContent: (
                            <FormSection
                                sectionTitle="Policy Flow"
                                className="sds_clinical-policy sds_clinical-policy__flow-pane"
                                isLoading={!policyDetails}
                                content={<QuestionGraph questions={policyDetails?.questions} />}
                            />
                        ),
                    },
                    {
                        title: 'Test Policy',
                        mainContent: (
                            <FormSection
                                sectionTitle="Test Policy"
                                className="sds_clinical-policy"
                                isLoading={!policyDetails}
                                content={
                                    <QuestionForm
                                        nodes={policyDetails?.questions}
                                        answers={testAnswers}
                                        onSectionSubmit={(newAnswers) =>
                                            setTestAnswers((prev) => [...prev, ...newAnswers])
                                        }
                                        onReset={() => setTestAnswers([])}
                                    />
                                }
                            />
                        ),
                    },
                ]}
                activeTab={location.hash.replace(/^#/, '').replace(/-/g, ' ')}
                onTabSelect={(tab) =>
                    navigate({
                        ...location,
                        hash: tab.replace(/\s/g, '-').toLocaleLowerCase(),
                    })
                }
            />
        </>
    );
};
