import { FormSection, FormSectionRow } from '@strata/react-form-section';
import { PerformanceManagementBase } from '@strata/react-performance';
import { ProgressBar } from '@strata/react-progress-bar';
import { PerformanceStatusIndicator } from '@strata/react-status-indicators';
import classNames from 'classnames';
import PropTypes from 'prop-types';

export const PerformanceProgress = ({ performanceEntries = undefined }) => {
    // calc total & completed incentive amounts
    let incentiveTotal = 0;
    let incentiveComplete = 0;
    performanceEntries?.forEach((entry) => {
        incentiveTotal += entry.incentive ?? 0;
        if (entry.status === PerformanceStatusIndicator.Statuses.SUCCESSFUL) {
            incentiveComplete += entry.incentive ?? 0;
        }
    });

    return (
        <FormSection
            sectionTitle="Performance Progress"
            content={
                <>
                    {incentiveTotal > 0 && (
                        <FormSectionRow
                            rowContent={
                                <div className="margin__top--medium" style={{ width: 'auto' }}>
                                    <ProgressBar
                                        completed={incentiveComplete}
                                        total={incentiveTotal}
                                        labelText="Achieved"
                                        dollarSign
                                    />
                                </div>
                            }
                        />
                    )}
                    {performanceEntries?.map((performanceEntry, index) => (
                        <div
                            className={classNames({
                                'padding__bottom--medium': index < performanceEntries.length - 1,
                            })}
                            key={performanceEntry?.value_case_id}
                        >
                            <PerformanceManagementBase
                                performanceRequirementId={performanceEntry?.value_case_id}
                                performanceStatus={performanceEntry?.status}
                                incentiveAmount={performanceEntry?.incentive}
                                performanceName={performanceEntry?.value_case_name}
                                performanceDescription={performanceEntry?.value_case_description}
                                performanceDueDate={performanceEntry?.performance_end_date}
                                performanceEvaluationDate={performanceEntry?.evaluation_end_date}
                                banners={performanceEntry?.banners}
                            />
                        </div>
                    ))}
                </>
            }
        />
    );
};

PerformanceProgress.propTypes = {
    performanceEntries: PropTypes.arrayOf(
        PropTypes.shape({
            value_case_id: PropTypes.string.isRequired,
            status: PropTypes.oneOf(Object.values(PerformanceStatusIndicator.Statuses)).isRequired,
            incentive: PropTypes.number.isRequired,
            value_case_name: PropTypes.string.isRequired,
            value_case_description: PropTypes.string.isRequired,
            performance_end_date: PropTypes.string,
            evaluation_end_date: PropTypes.string,
            banners: PropTypes.arrayOf(
                PropTypes.shape({
                    message: PropTypes.string,
                    status: PropTypes.string,
                }),
            ),
        }),
    ),
};
