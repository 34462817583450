import { combineSlices, compose, configureStore } from '@reduxjs/toolkit';
import persistState from 'redux-sessionstorage';

import socketMiddleware from 'services/socketMiddleware';
import { announcementApi } from 'store/api/announcementApi';
import { apertureApi } from 'store/api/apertureApi';
import { authApi } from 'store/api/authApi';
import { notificationApi } from 'store/api/notificationApi';
import { payerRequestApi } from 'store/api/payerRequestApi';
import { performanceApi } from 'store/api/performanceApi';
import { referenceApi } from 'store/api/referenceApi';
import { scopeAssessorApi } from 'store/api/scopeAssessorApi';
import { treatmentApi } from 'store/api/treatmentApi';
import { authSlice } from 'store/auth/authSlice';
import { gridsSlice } from 'store/grids/gridsSlice';
import { notificationSlice } from 'store/notification/notificationSlice';
import { policiesSlice } from 'store/policies/policiesSlice';
import { referenceSlice } from 'store/reference/referenceSlice';
import { referralDetailsSlice } from 'store/referrals/referralDetailsSlice';
import { referralListSlice } from 'store/referrals/referralListSlice';
import { treatmentListSlice } from 'store/treatments/treatmentListSlice';
import { viewsSlice } from 'store/views/viewsSlice';

const sessionStorageEnhancer = compose(
    persistState([gridsSlice.name, viewsSlice.name], { key: 'store' }),
);

const slices = [
    authSlice,
    gridsSlice,
    notificationSlice,
    policiesSlice,
    referenceSlice,
    referralDetailsSlice,
    referralListSlice,
    treatmentListSlice,
    viewsSlice,
];

const apiSlices = [
    authApi,
    announcementApi,
    apertureApi,
    notificationApi,
    payerRequestApi,
    performanceApi,
    referenceApi,
    scopeAssessorApi,
    treatmentApi,
];

const rootReducer = combineSlices(...slices, ...apiSlices);

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            socketMiddleware,
            apiSlices.map((slice) => slice.middleware),
        ),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().concat(sessionStorageEnhancer),
    devTools: process.env.REACT_APP_ENV !== 'prd',
});

/** Used for configuration of redux store in tests */
export const MockStore = {
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({ serializableCheck: false }).concat(
            socketMiddleware,
            apiSlices.map((slice) => slice.middleware),
        ),
};
