import { SuggestSingleSelect } from '@strata/react-selects';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGetSegmentsQuery } from 'store/api/referenceApi';
import { Grids, resetGrid } from 'store/grids/gridsSlice';
import { resetReferralData } from 'store/referrals/referralListSlice';
import { setSegmentId } from 'store/views/viewsSlice';

export const ViewsControls = () => {
    const dispatch = useDispatch();
    const { segmentId } = useSelector((state) => state.views);
    const { payerId, segmentIds } = useSelector((state) => state.auth);

    const { data: segments, isLoading } = useGetSegmentsQuery({ payerId });
    const segmentOptions = useMemo(
        () => [
            { name: 'All Business Segments', value: undefined },
            ...(segments ?? [])
                .filter(({ id }) => segmentIds.includes(id))
                .map((segment) => ({ ...segment, value: segment.id })),
        ],
        [segmentIds, segments],
    );

    const resetData = () => {
        // Reset grid data so it will be refetched
        dispatch(resetGrid(Grids.REFERRALS));
        dispatch(resetReferralData());
    };

    return (
        <div className="sds_views-controls">
            <SuggestSingleSelect
                id="segment"
                name="segment"
                label="Business Segment"
                verticalSpacingSmall
                required
                isLoading={isLoading}
                values={segmentOptions}
                selectedItem={segmentOptions.find(({ value }) => value === segmentId)}
                onChange={(newVal) => {
                    if (!isLoading) {
                        resetData();
                        dispatch(setSegmentId({ segmentId: newVal?.value }));
                    }
                }}
            />
        </div>
    );
};
