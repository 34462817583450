import { Button } from '@strata/react-buttons';
import { RequestType, RequestTypeDisplayTextMap } from '@strata/react-chat';
import { FormHeaderAndTabbedContent } from '@strata/react-form-header-and-content';
import { PageTitle } from '@strata/react-page-title';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { SubmitRequestDialog } from 'components/requests/dialogs/SubmitRequestDialog';
import { RequestArchiveGrid } from 'components/requests/RequestArchiveGrid';
import { RequestsGrid } from 'components/requests/RequestsGrid';
import { PayerRequestWritePermissions } from 'services/constants';
import { ReadPermissionRequestTypeMap } from 'services/payerRequests';
import { useGetOpenRequestCountsQuery } from 'store/api/payerRequestApi';
import { useGetPayerDetailsQuery } from 'store/api/referenceApi';

export const RequestsPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const [isSubmitRequestDialogOpen, setIsSubmitRequestDialogOpen] = useState(false);

    const { payerId, permissions } = useSelector((state) => state.auth);

    const { data: payerDetails } = useGetPayerDetailsQuery({ payerId });

    const requestTypes = useMemo(
        () => [
            ...Object.entries(ReadPermissionRequestTypeMap)
                // Only show requestTypes user has permissions for
                .filter(([permission]) => permissions.includes(permission))
                .map(([_, requestType]) => requestType),
            // If user has access to page they can see archive
            RequestType.ARCHIVE,
        ],
        [permissions],
    );

    const activeRequestType =
        location.hash.replace(/^#/, '').toLocaleUpperCase() ?? requestTypes[0];

    useEffect(() => {
        if (!requestTypes.includes(activeRequestType)) {
            navigate({
                ...location,
                hash: requestTypes[0]?.toLocaleLowerCase(),
            });
        }
    }, [activeRequestType, requestTypes, location, navigate]);

    const { data: requestCounts } = useGetOpenRequestCountsQuery({ payerId });

    return (
        <FormHeaderAndTabbedContent
            rowOneContentHeader={
                <div
                    className="flex__gap--medium"
                    style={{
                        flexGrow: 1,
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <PageTitle title="Requests" secondaryTitle={payerDetails?.payerName} />
                    <SubmitRequestDialog
                        isOpen={isSubmitRequestDialogOpen}
                        onClose={() => setIsSubmitRequestDialogOpen(false)}
                    />
                    <Button
                        label="Submit Request"
                        intent={Button.Intent.PRIMARY}
                        disabled={Object.values(PayerRequestWritePermissions).every(
                            (permission) => !permissions.includes(permission),
                        )}
                        onClick={() => setIsSubmitRequestDialogOpen(true)}
                    />
                </div>
            }
            tabs={[
                ...requestTypes.map((requestType) => ({
                    title: RequestTypeDisplayTextMap[requestType],
                    className: 'tabbed-grid-content',
                    badgeCount: requestCounts?.[requestType],
                    mainContent:
                        requestType === RequestType.ARCHIVE ? (
                            <RequestArchiveGrid />
                        ) : (
                            <RequestsGrid requestType={requestType} />
                        ),
                })),
            ]}
            activeTab={RequestTypeDisplayTextMap[activeRequestType]}
            onTabSelect={(tab) =>
                navigate({
                    ...location,
                    hash: Object.keys(RequestTypeDisplayTextMap)
                        .find((requestType) => RequestTypeDisplayTextMap[requestType] === tab)
                        ?.toLocaleLowerCase(),
                })
            }
        />
    );
};
