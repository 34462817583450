import { useAuth0 } from '@auth0/auth0-react';
import { FeedbackButton } from '@strata/react-buttons';
import { BlankScreen } from '@strata/react-page-templates';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { Header } from 'components/header/Header';
import { LOGIN_REDIRECT_KEY, Permissions } from 'services/constants';
import { usePayerFeatures } from 'services/FeatureFlags';
import { AppRoutes } from 'services/routes';

/** Routes that header is hidden on */
const NoHeaderRoutes = [AppRoutes.termsOfUse, AppRoutes.privacyPolicy, AppRoutes.updatesHistory];

/**
 * Component to determine if a user is authenticated and
 * if they are allowed to visit the page they navigated to.
 *
 * If they are: they proceed to the page
 * If not: they are redirected to the login page.
 */
export const RequireAuth = ({
    requiredPayerFeature = undefined,
    permissionsToCheck = undefined,
}) => {
    const { isAuthenticated, loginWithRedirect } = useAuth0();
    const location = useLocation();

    // Ensure permissions, profile, and payer details are loaded
    const { permissions } = useSelector((state) => state.auth);
    const payerFeatures = usePayerFeatures();
    const userDataLoaded = !!payerFeatures;

    if (!isAuthenticated) {
        sessionStorage.setItem(LOGIN_REDIRECT_KEY, location.pathname);
        loginWithRedirect();
        return <BlankScreen />;
    }

    // Redirect to home if payer doesn't have required feature
    if (
        userDataLoaded &&
        ((requiredPayerFeature && !payerFeatures?.[requiredPayerFeature]) ||
            (permissionsToCheck &&
                !permissionsToCheck.some((permission) => permissions.includes(permission))))
    ) {
        return <Navigate replace to={AppRoutes.home} />;
    }

    return (
        <>
            {!NoHeaderRoutes.includes(location.pathname) && <Header />}
            {!userDataLoaded ? <BlankScreen /> : <Outlet />}
            <FeedbackButton hideButton />
        </>
    );
};

RequireAuth.propTypes = {
    requiredPayerFeature: PropTypes.string,
    /** User must have at least one of the provided permissions. */
    permissionsToCheck: PropTypes.arrayOf(PropTypes.oneOf(Object.values(Permissions))),
};
