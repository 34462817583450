/* eslint-disable no-console */
// Inspired by: https://dev.to/aduranil/how-to-use-websockets-with-redux-a-step-by-step-guide-to-writing-understanding-connecting-socket-middleware-to-your-project-km3
import { getSocketURL, SocketType } from 'services/socketHelpers';
import { receiveNewNotifications } from 'store/notification/notificationSlice';

const wsConnected = (socketType, host) => ({ type: 'WS_CONNECTED', socketType, host });
const wsDisconnected = (socketType, host) => ({ type: 'WS_DISCONNECTED', socketType, host });
const wsReconnect = (socketType) => ({ type: 'WS_RECONNECT', socketType });

const sockets = { [SocketType.NOTIFICATION]: null };

const socketMap = {
    [SocketType.NOTIFICATION]: {
        template: {
            store: null,
            socketType: SocketType.NOTIFICATION,
            socketUrl: getSocketURL(SocketType.NOTIFICATION),
            messageMap: {
                STATUS: (_store, message) =>
                    console.log(`Notification WebSocket Status: ${message.message}`),
                NOTIFICATION: (store, message) =>
                    store.dispatch(receiveNewNotifications(message.payload)),
            },
        },
        actions: {
            WS_CONNECT: (store, _action, template) => ({
                ...template,
                store,
            }),
        },
    },
};

const openSocket = ({
    store,
    socketType,
    socketUrl,
    messageMap,
    onOpenAction,
    onCloseAction,
    onReconnectAttempt,
    checkIfDisconnected,
}) => {
    let socket = null;
    let timerId = 0;
    let pingSendsCounter = 0; // "unacknowledged" ping sends

    const cancelKeepAlive = () => {
        pingSendsCounter = 0;
        if (timerId) {
            clearTimeout(timerId);
        }
    };

    const keepAlive = () => {
        const timeout = 20000;
        if (socket.readyState === socket.OPEN) {
            socket.send(JSON.stringify({ type: 'KEEP-ALIVE' }));
        }
        pingSendsCounter += 1;
        if (pingSendsCounter >= 3) {
            console.warn(
                `No Server Response in ${
                    (timeout * pingSendsCounter) / 1000
                } seconds - Reconnecting ${socketType}`,
            );
            onReconnectAttempt?.();
            store.dispatch(wsReconnect(socketType));
        } else if (checkIfDisconnected?.() && pingSendsCounter >= 1) {
            console.warn(`Server unexpectedly disconnected - Reconnecting ${socketType}`);
            onReconnectAttempt?.();
            store.dispatch(wsReconnect(socketType));
        } else {
            timerId = setTimeout(() => keepAlive(), timeout);
        }
    };

    const isOpen = () => socket != null;

    const close = () => {
        socket.close();
    };

    const send = (message) => {
        if (socket.readyState === socket.OPEN) {
            socket.send(message);
        } else {
            setTimeout(() => socket.send(message), 2000);
        }
    };

    // make sure any old sockets aren't doing any work
    cancelKeepAlive();

    // connect to the remote host
    socket = new WebSocket(`${socketUrl}/ws`);

    // websocket handlers
    socket.onopen = (event) => {
        // authenticate
        const token = store.getState().auth.accessToken;
        const authBody = JSON.stringify({ type: 'AUTH', token });
        socket.send(authBody);

        console.log('websocket open', event);
        store.dispatch(wsConnected(socketType, event));

        keepAlive();

        onOpenAction?.();
    };

    socket.onclose = () => {
        console.log(`${socketType} was closed.`);
        onCloseAction?.();
        store.dispatch(wsDisconnected(socketType));
    };

    socket.onmessage = (event) => {
        // reset ping sends counter
        pingSendsCounter = 0;

        const message = JSON.parse(event.data);
        if (message && messageMap[message.type]) {
            messageMap[message.type](store, message);
        } else {
            console.log('Unknown message type', message);
        }
    };

    return {
        isOpen,
        close,
        send,
    };
};

const socketMiddleware = () => {
    const isSocketOpen = (socketType) => sockets[socketType]?.isOpen();

    const openMappedSocket = (store, action) => {
        sockets[action.socketType] = openSocket(
            socketMap[action.socketType].actions.WS_CONNECT(
                store,
                action,
                socketMap[action.socketType].template,
            ),
        );
    };

    const sendOnMappedSocket = (store, action) => {
        console.log(
            `sending a message ${action.messageType} to socket ${action.socketType}`,
            action.message,
        );
        const socketWSSend = socketMap[action.socketType].actions.WS_SEND;
        sockets[action.socketType].send(
            JSON.stringify(
                socketWSSend
                    ? socketWSSend(store, action)
                    : { type: action.messageType, message: action.message },
            ),
        );
    };

    const actionMap = {
        WS_CONNECT: (store, action) => {
            if (isSocketOpen(action.socketType)) {
                sockets[action.socketType].close();
            }

            openMappedSocket(store, action);
        },
        WS_ENSURE_CONNECTED: (store, action) => {
            if (!isSocketOpen(action.socketType)) {
                openMappedSocket(store, action);
            }
        },
        WS_RECONNECT: (store, action) => {
            openMappedSocket(store, action);
        },
        WS_DISCONNECT: (_store, action) => {
            if (isSocketOpen(action.socketType)) {
                sockets[action.socketType].close();
            }
        },
        WS_SEND: (store, action) => {
            if (!isSocketOpen(action.socketType)) {
                setTimeout(() => sendOnMappedSocket(store, action), 2000);
            } else {
                sendOnMappedSocket(store, action);
            }
        },
    };

    // the middleware part of this function
    return (store) => (next) => (action) => {
        if (action && actionMap[action.type]) {
            return actionMap[action.type](store, action);
        }
        return next(action);
    };
};

export default socketMiddleware();
