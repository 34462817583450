import { IconNames } from '@blueprintjs/icons';
import { DropdownMenu } from '@strata/react-dropdown-menu';
import { Icon } from '@strata/react-status-icons';
import classNames from 'classnames';
import * as PropTypes from 'prop-types';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'services/routes';

export const ReferralRowMenu = ({ treatmentId = undefined, backToDestination }) => {
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);

    const dropdownMenuOptions = [
        {
            text: 'Go to Member Treatment',
            disabled: !treatmentId,
            onItemClick: () =>
                navigate(AppRoutes.treatmentDetails(treatmentId), {
                    state: {
                        backToDestination,
                    },
                }),
        },
    ];

    return (
        <DropdownMenu
            isOpen={showDropdown}
            menuItemArray={dropdownMenuOptions}
            onInteraction={(nextOpenState, e) => {
                e?.stopPropagation();
                setShowDropdown(nextOpenState);
            }}
        >
            <Icon
                icon={IconNames.MORE}
                intent={Icon.Intent.SECONDARY}
                title="Referral Menu"
                className={classNames(
                    'sds_referral-grid-row__menu-button',
                    'padding__around--x-small',
                )}
            />
        </DropdownMenu>
    );
};

ReferralRowMenu.propTypes = {
    treatmentId: PropTypes.string,
    backToDestination: PropTypes.shape({
        text: PropTypes.string,
        route: PropTypes.string,
    }).isRequired,
};
