import { createApi } from '@reduxjs/toolkit/query/react';
import { formatNameLastFirst } from '@strata/helper-functions';

import { APERTURE_API_URL } from 'api/APIBase';
import { handleRTKQueryError } from 'services/Error';
import { getBaseQuery } from 'store/api/apiBaseQuery';

export const apertureApi = createApi({
    baseQuery: getBaseQuery(APERTURE_API_URL),
    reducerPath: 'apertureApi',
    endpoints: (build) => ({
        /** Tests connection to data lake */
        testLakeConnection: build.query({ query: () => 'prescribers/lake-test' }),
        /** Gets medications summary stats */
        getMedications: build.query({
            query: ({ segmentId }) => ({
                url: 'medications',
                params: { segment_id: segmentId },
            }),
            transformResponse: (response) =>
                response?.map((product) => ({
                    productServiceOid: product.product_service_oid,
                    drugName: product.drug_name,
                    isBrand: product.is_brand,
                    numberOfReferrals: product.number_of_referrals,
                    baselineRate: product.baseline_rate,
                    avgWinningBidRate: product.avg_winning_bid_rate,
                    referentPriceType: product.referent_price_type,
                    therapeuticCategory: product.therapeutic_category,
                })),
            transformErrorResponse: handleRTKQueryError('An error occurred retrieving products.'),
        }),
        /** Gets medication detail stats */
        getMedicationDetails: build.query({
            query: ({ segmentId, productServiceOid, isBrand, referentPriceType }) => ({
                url: `medications/${productServiceOid}`,
                params: {
                    segment_id: segmentId,
                    is_brand: isBrand,
                    referent_price_type: referentPriceType,
                },
            }),
            transformResponse: (response) => {
                const product = response?.[0];
                return {
                    productServiceOid: product?.product_service_oid,
                    drugName: product?.drug_name,
                    isBrand: product?.is_brand,
                    numberOfReferrals: product?.number_of_referrals,
                    baselineRate: product?.baseline_rate,
                    avgWinningBidRate: product?.avg_winning_bid_rate,
                    referentPriceType: product?.referent_price_type,
                    stats: product?.stats?.map((stat) => ({
                        key: stat.title,
                        values: stat.values?.map((val) => ({
                            value: val.value?.trim(),
                            numberOfReferrals: val.number_of_referrals,
                            baselineRate: val.baseline_rate,
                            avgWinningBidRate: val.avg_winning_bid_rate,
                            referentPriceType: val.referent_price_type,
                        })),
                    })),
                };
            },
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving product details.',
            ),
        }),
        getServicingPharmaciesForProduct: build.query({
            query: ({ segmentId, productServiceOid, isBrand, referentPriceType }) => ({
                url: `medications/bypharmacy/${productServiceOid}`,
                params: {
                    segment_id: segmentId,
                    is_brand: isBrand,
                    referent_price_type: referentPriceType,
                },
            }),
            transformResponse: (response) =>
                response?.map((pharmacy) => ({
                    name: pharmacy.organization_name,
                    referrals: pharmacy.number_of_referrals,
                    npi: pharmacy.pharmacy_npi,
                })),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving servicing pharmacies.',
            ),
        }),
        /** Gets pharmacies summary stats */
        getPharmacies: build.query({
            query: ({ segmentId }) => ({
                url: 'pharmacies',
                params: { segment_id: segmentId },
            }),
            transformResponse: (response) =>
                response?.map((pharmacy) => ({
                    npi: pharmacy.pharmacy_npi,
                    name: pharmacy.organization_name,
                    status: pharmacy.network_status,
                    referrals: pharmacy.number_of_referrals,
                    winningPct: pharmacy.referral_win_percentage,
                })),
            transformErrorResponse: handleRTKQueryError('An error occurred retrieving pharmacies.'),
        }),
        /** Gets prescribers summary stats */
        getPrescribers: build.query({
            query: ({ segmentId }) => ({
                url: 'prescribers',
                params: { segment_id: segmentId },
            }),
            transformResponse: (response) =>
                response?.map((prescriber) => ({
                    npi: prescriber.prescriber_npi,
                    name: formatNameLastFirst(prescriber.last_name, prescriber.first_name),
                    topDrugName: prescriber.top_drug_name,
                    referrals: prescriber.number_of_referrals,
                    specialization: prescriber.specialization,
                })),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving prescribers.',
            ),
        }),
    }),
});

export const {
    useGetMedicationsQuery,
    useGetMedicationDetailsQuery,
    useGetServicingPharmaciesForProductQuery,
    useGetPharmaciesQuery,
    useGetPrescribersQuery,
    useTestLakeConnectionQuery,
} = apertureApi;
