import { createApi } from '@reduxjs/toolkit/query/react';

import { NOTIFICATION_API_URL } from 'api/APIBase';
import { handleRTKQueryError } from 'services/Error';
import { getBaseQuery } from 'store/api/apiBaseQuery';

const TagType = { NOTIFICATION_SETTINGS: 'NOTIFICATION_SETTINGS' };

export const notificationApi = createApi({
    baseQuery: getBaseQuery(NOTIFICATION_API_URL),
    reducerPath: 'notificationApi',
    tagTypes: Object.values(TagType),
    endpoints: (build) => ({
        getNotificationPreferences: build.query({
            query: () => 'preferences/',
            providesTags: [TagType.NOTIFICATION_SETTINGS],
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving notification settings.',
            ),
        }),
        postUpdateNotificationPreferences: build.mutation({
            query: (params) => ({
                url: 'preferences/',
                method: 'POST',
                body: {
                    ...params,
                    sms: [], // SMS currently disabled
                },
            }),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred updating notification settings.',
            ),
            invalidatesTags: (_, error) => (!error ? [TagType.NOTIFICATION_SETTINGS] : []),
        }),
    }),
});

export const { useGetNotificationPreferencesQuery, usePostUpdateNotificationPreferencesMutation } =
    notificationApi;
