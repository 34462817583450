import { DateFormats, formatDate } from '@strata/helper-functions';
import { FormHeaderAndContent } from '@strata/react-form-header-and-content';
import { GridWithFilters, SecondaryData } from '@strata/react-grid';
import { FormTitleLabel } from '@strata/react-label-value-pairs';
import { ClinicalPolicyStatusIndicator } from '@strata/react-status-indicators';
import { useNavigate } from 'react-router-dom';

import { PayerPolicyStatusIndicator } from 'components/admin/PayerPolicyStatusIndicator';
import { AppRoutes } from 'services/routes';
import { Grids, useGridState } from 'store/grids/gridsSlice';
import { usePolicyListData } from 'store/policies/policiesSlice';

const policyStatusDisplayTextMap = {
    ...ClinicalPolicyStatusIndicator.StatusMessages,
    ...PayerPolicyStatusIndicator.StatusMessages,
};

const initialSortProperties = [{ id: 'productName', desc: false }];

const filterCategories = [
    {
        key: 'policyStatus',
        title: 'Status',
        valueDisplayTextMap: policyStatusDisplayTextMap,
    },
    {
        key: 'assignedTo',
        title: 'Assigned To',
        valueDisplayTextMap: policyStatusDisplayTextMap,
    },
];

const customSearchValueHandlers = {
    policyStatus: (value) => policyStatusDisplayTextMap[value],
    effectiveDate: (value) => formatDate(value),
};

const columns = [
    {
        accessorKey: 'productName',
        header: 'Product',
        size: 180,
    },
    {
        accessorKey: 'diagnoses',
        header: 'Diagnoses',
        size: 220,
    },
    {
        accessorKey: 'effectiveDate',
        header: 'Effective Date',
        size: 110,
        cell: ({ getValue }) => <SecondaryData data={formatDate(getValue())} />,
    },
    {
        accessorKey: 'assignedTo',
        header: 'Assigned To',
        size: 180,
    },
    {
        accessorKey: 'statusDate',
        header: 'Updated On',
        size: 120,
        cell: ({ getValue }) => (
            <SecondaryData data={formatDate(getValue(), DateFormats.DATE_TIME)} />
        ),
    },
    {
        accessorKey: 'username',
        header: 'Updated By',
        size: 180,
    },
    {
        accessorKey: 'policyStatus',
        header: 'Status',
        size: 150,
        enableSorting: false,
        cell: ({ getValue }) => <PayerPolicyStatusIndicator status={getValue()} />,
    },
];

export const ClinicalPoliciesListPage = () => {
    const navigate = useNavigate();

    const policyListData = usePolicyListData();

    const {
        sort,
        search,
        activeFilters,
        filterPaneWidth,
        updateGridSort,
        updateGridSearch,
        updateGridActiveFilters,
        updateFilterPaneWidth,
    } = useGridState(Grids.CLINICAL_POLICIES, initialSortProperties);

    return (
        <FormHeaderAndContent
            rowOneContentHeader={
                <div className="padding__top--medium padding__bottom--small">
                    <FormTitleLabel label="Clinical Policies" />
                </div>
            }
            className="tabbed-grid-content"
            mainContent={
                <GridWithFilters
                    data={policyListData}
                    isLoading={!policyListData}
                    sortProperties={sort}
                    onSort={updateGridSort}
                    enableSearch
                    customSearchValueHandlers={customSearchValueHandlers}
                    searchText={search}
                    onSearch={updateGridSearch}
                    filterCategories={filterCategories}
                    activeFilters={activeFilters}
                    onFilter={updateGridActiveFilters}
                    filterPaneRenderWidth={filterPaneWidth}
                    onFilterPaneResize={updateFilterPaneWidth}
                    onRowClick={(rowData) =>
                        navigate(AppRoutes.clinicalPolicyDetails(rowData.draftId))
                    }
                    columns={columns}
                />
            }
        />
    );
};
