import { DialogWithForm } from '@strata/react-dialog';
import { TextInputFormik } from '@strata/react-inputs';
import { validationStrings } from '@strata/strata-ui-strings';
import { Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { handleError } from 'services/Error';
import {
    checkRestrictedGroup,
    resetTreatmentSearchData,
} from 'store/treatments/treatmentListSlice';

export const AdditionalSearchInfoDialog = () => {
    const dispatch = useDispatch();
    const { restrictedAccess } = useSelector((state) => state.auth);
    const { lastSearchParams, searchResults } = useSelector((state) => state.treatmentList);

    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
        if (
            !restrictedAccess &&
            searchResults?.treatments?.length === 0 &&
            searchResults?.restrictedGroup == null
        ) {
            setIsOpen(true);
        }
    }, [restrictedAccess, searchResults]);

    return (
        <Formik
            initialValues={{ groupId: undefined }}
            validationSchema={Yup.object({
                groupId: Yup.string().required(validationStrings.requiredField.errorMessage),
            })}
            onSubmit={async ({ groupId }, { resetForm }) => {
                try {
                    await dispatch(checkRestrictedGroup({ ...lastSearchParams, groupId }));
                    resetForm();
                    setIsOpen(false);
                } catch (err) {
                    handleError('An error occurred checking group ID.', err);
                }
            }}
        >
            {(formik) => (
                <DialogWithForm
                    dialogTitle="Additional Information Needed"
                    isOpen={isOpen}
                    primaryButtonLabel="Search"
                    isPrimaryButtonDisabled={
                        !(formik.isValid && formik.dirty) || formik.isSubmitting
                    }
                    secondaryButtonLabel="Cancel"
                    onSecondaryButtonClick={(e) => {
                        dispatch(resetTreatmentSearchData());
                        setIsOpen(false);
                        formik.handleReset(e);
                    }}
                    dialogContent={
                        <TextInputFormik id="groupId" label="Member Group ID" required />
                    }
                />
            )}
        </Formik>
    );
};
