import { addCommas } from '@strata/helper-functions';
import { FormPageContainer } from '@strata/react-form-header-and-content';
import { GridWithFilters, PrimaryData, SecondaryData } from '@strata/react-grid';
import { KpiSmall } from '@strata/react-kpi';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ActiveStatusIndicator } from 'components/shared/ActiveStatusIndicator';
import { GridHeaderWithInfo } from 'components/shared/GridHeaderWithInfo';
import { TimeFrameBanner } from 'components/shared/TimeframeBanner';
import { ViewsControls } from 'components/shared/ViewsControls';
import { AppRoutes } from 'services/routes';
import { useGetPharmaciesQuery } from 'store/api/apertureApi';
import { useGetPayerDetailsQuery } from 'store/api/referenceApi';
import { Grids, useGridState } from 'store/grids/gridsSlice';

const initialSortProperties = [{ id: 'name', desc: false }];

const filterCategories = [ActiveStatusIndicator.GridFilter];

const kpiData = [
    {
        label: 'Active Pharmacies',
        value: (data) =>
            data?.filter((pharmacy) => pharmacy.status === ActiveStatusIndicator.Statuses.ACTIVE)
                .length,
    },
];

const columns = [
    {
        accessorKey: 'name',
        header: 'Pharmacy',
        size: 200,
        cell: ({ getValue }) => <PrimaryData data={getValue()} />,
    },
    {
        accessorKey: 'referrals',
        header: 'Active Referrals',
        size: 100,
        cell: ({ getValue }) => <SecondaryData data={addCommas(getValue())} />,
    },
    {
        accessorKey: 'winningPct',
        header: () => (
            <GridHeaderWithInfo
                header="Winning %"
                tooltipText="The average percentage a pharmacy wins referrals when participating in FMH Live Referrals bidding"
            />
        ),
        size: 100,
        cell: ({ getValue }) => (
            <KpiSmall value={getValue()} valueType={KpiSmall.ValueTypes.PERCENTAGE} />
        ),
    },
    {
        accessorKey: 'status',
        header: 'Status',
        size: 100,
        enableSorting: false,
        cell: ({ getValue }) => <ActiveStatusIndicator status={getValue()} />,
    },
];

export const PharmaciesPage = () => {
    const navigate = useNavigate();
    const { payerId } = useSelector((state) => state.auth);

    const { data: payerDetails } = useGetPayerDetailsQuery({ payerId });

    const { segmentId } = useSelector((state) => state.views);
    const { data, isFetching } = useGetPharmaciesQuery({ segmentId });

    const {
        sort,
        search,
        activeFilters,
        filterPaneWidth,
        updateGridSort,
        updateGridSearch,
        updateGridActiveFilters,
        updateFilterPaneWidth,
    } = useGridState(Grids.PHARMACIES, initialSortProperties);

    return (
        <FormPageContainer>
            <GridWithFilters
                title="Pharmacies"
                secondaryTitle={payerDetails?.payerName}
                headerContent={<TimeFrameBanner />}
                viewsPaneContent={<ViewsControls />}
                data={data}
                isLoading={isFetching}
                sortProperties={sort}
                onSort={updateGridSort}
                enableSearch
                searchText={search}
                onSearch={updateGridSearch}
                filterCategories={filterCategories}
                activeFilters={activeFilters}
                onFilter={updateGridActiveFilters}
                filterPaneRenderWidth={filterPaneWidth}
                onFilterPaneResize={updateFilterPaneWidth}
                kpiData={kpiData}
                onRowClick={(rowData) =>
                    navigate(AppRoutes.pharmacyDetails(rowData.npi), {
                        state: {
                            backToDestination: {
                                text: 'Pharmacies',
                                route: AppRoutes.pharmacies,
                            },
                        },
                    })
                }
                columns={columns}
            />
        </FormPageContainer>
    );
};
