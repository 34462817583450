import { DateFormats, formatDate } from '@strata/helper-functions';
import { PrimaryData, SecondaryData } from '@strata/react-grid';
import { ServicingRate } from '@strata/react-servicing-rate';
import { ReferralStatusIndicator } from '@strata/react-status-indicators';
import classNames from 'classnames';

import { ApertureReferralStatusIndicator } from 'components/shared/ApertureReferralStatusIndicator';
import { ReferralRowMenu } from 'components/shared/ReferralRowMenu';
import { Permissions } from 'services/constants';
import { AppRoutes } from 'services/routes';

export const referralGridColumns = (permissions, backToDestination) => [
    {
        accessorKey: 'memberId',
        header: 'Member ID',
        size: 150,
        cell: ({ getValue }) => <PrimaryData data={getValue()} />,
    },
    {
        accessorKey: 'productServiceDescription',
        header: 'Product',
        size: 120,
    },
    ...(permissions.includes(Permissions.READ_REFERRAL_RATES)
        ? [
              {
                  accessorKey: 'winningBidRate',
                  header: 'Referral Rate',
                  size: 100,
                  cell: ({ row, getValue }) => (
                      <SecondaryData
                          data={
                              <ServicingRate
                                  servicingRate={getValue()}
                                  referentPriceType={row.original.referentPriceType}
                              />
                          }
                      />
                  ),
              },
          ]
        : []),
    {
        accessorKey: 'receivedDate',
        header: 'Date Received',
        size: 100,
        cell: ({ getValue }) => (
            <SecondaryData data={formatDate(getValue(), DateFormats.DATE_TIME)} />
        ),
    },
    {
        accessorKey: 'referralStatus',
        header: 'Referral Status',
        enableSorting: false,
        size: 120,
        cell: ({ getValue }) => <ApertureReferralStatusIndicator status={getValue()} />,
    },
    {
        accessorKey: 'treatmentId',
        header: '',
        size: 50,
        enableSorting: false,
        cell: ({ getValue }) => (
            <ReferralRowMenu treatmentId={getValue()} backToDestination={backToDestination} />
        ),
    },
];

const isRowDisabled = (status) =>
    [
        ReferralStatusIndicator.Statuses.BEN_DENIAL,
        ReferralStatusIndicator.Statuses.CANCELLED,
        ReferralStatusIndicator.Statuses.DENIED,
        ReferralStatusIndicator.Statuses.OUT_OF_NET,
        ReferralStatusIndicator.Statuses.PENDAUTH,
        ReferralStatusIndicator.Statuses.RTA_ONLY,
    ].includes(status);

export const referralGridRowCssClass = (rowData) =>
    classNames({
        'griddle-row--disabled': isRowDisabled(rowData.referralStatus),
    });

export const referralGridOnRowClick = (navigate, backToDestination) => (rowData) => {
    if (!isRowDisabled(rowData.referralStatus)) {
        navigate(AppRoutes.referralDetails(rowData.referralId), {
            state: {
                backToDestination,
            },
        });
    }
};
