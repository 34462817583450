import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: undefined,
        payerId: undefined,
        segmentIds: [],
        permissions: [],
        restrictedAccess: true,
    },
    reducers: {
        setAccessToken(state, action) {
            const { accessToken, payerId, segmentIds, permissions, restrictedAccess } =
                action.payload;
            return { ...state, accessToken, payerId, segmentIds, permissions, restrictedAccess };
        },
    },
});

export const { setAccessToken } = authSlice.actions;
