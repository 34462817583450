import { useAuth0 } from '@auth0/auth0-react';
import { datadogRum } from '@datadog/browser-rum';
import { openInNewTab } from '@strata/helper-functions';
import { TermsOfUseDialog } from '@strata/react-dialog';
import { Header as StrataHeader } from '@strata/react-header';
import { BaseNotification } from '@strata/react-notifications';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHasAcceptedTermsOfUse, postAcceptTermsOfUse } from 'api/authAPI';
import { UserNotificationSettingsDialog } from 'components/header/dialogs/notificationSettings/UserNotificationSettingsDialog';
import { UserProfileDialog } from 'components/header/dialogs/UserProfileDialog';
import { FeatureCarousel } from 'components/header/FeatureCarousel';
import { NotificationComponentMap } from 'components/notifications/NotificationComponentMap';
import { PayerRequestReadPermissions, Permissions } from 'services/constants';
import { handleError } from 'services/Error';
import { PayerFeatures, usePayerFeatures } from 'services/FeatureFlags';
import { AppRoutes } from 'services/routes';
import { useGetHomeScreenMessagesQuery } from 'store/api/announcementApi';
import { useGetOpenRequestCountsQuery } from 'store/api/payerRequestApi';
import {
    clearIncomingNotification,
    updateNotificationStatus,
    updateNotificationStatuses,
} from 'store/notification/notificationSlice';

export const Header = () => {
    const { showAppVersion } = useFlags();
    const { isAuthenticated, logout } = useAuth0();
    const dispatch = useDispatch();

    const payerFeatures = usePayerFeatures();
    const { payerId, permissions } = useSelector((state) => state.auth);

    const canSeePayerRequests =
        payerFeatures?.[PayerFeatures.REQUESTS] &&
        Object.values(PayerRequestReadPermissions).some((permission) =>
            permissions.includes(permission),
        );

    const { data: requestCounts } = useGetOpenRequestCountsQuery(
        { payerId },
        {
            skip: !(payerId && canSeePayerRequests),
        },
    );

    const navTabs = useMemo(() => {
        if (!isAuthenticated) {
            return [];
        }
        return [
            ...(payerFeatures?.[PayerFeatures.APREFERRAL] &&
            permissions.includes(Permissions.READ_SUMMARY)
                ? [
                      { label: 'Products', to: AppRoutes.products },
                      { label: 'Pharmacies', to: AppRoutes.pharmacies },
                      { label: 'Prescribers', to: AppRoutes.prescribers },
                  ]
                : []),
            ...(payerFeatures?.[PayerFeatures.APREFERRAL] &&
            permissions.includes(Permissions.READ_DETAIL)
                ? [{ label: 'Member Search', to: AppRoutes.treatments }]
                : []),
            ...(canSeePayerRequests
                ? [
                      {
                          label: 'Requests',
                          to: AppRoutes.requests,
                          badgeCount: Object.values(requestCounts ?? {}).reduce(
                              (sum, num) => sum + num,
                              0,
                          ),
                      },
                  ]
                : []),
            ...(payerFeatures?.[PayerFeatures.POLICY_MAN] &&
            permissions.includes(Permissions.READ_CLINICAL_POLICY)
                ? [{ label: 'Admin', to: AppRoutes.clinicalPolicies }]
                : []),
        ];
    }, [isAuthenticated, payerFeatures, permissions, canSeePayerRequests, requestCounts]);

    const [showUserNotificationSettingsDialog, setShowUserNotificationSettingsDialog] =
        useState(false);
    const [showUserProfileDialog, setShowUserProfileDialog] = useState(false);

    // Load home screen messages & carousels on login
    useGetHomeScreenMessagesQuery(undefined, { skip: !isAuthenticated });

    // Check if user has accepted terms of use on login
    const [showTermsDialog, setShowTermsDialog] = useState(false);
    useEffect(() => {
        (async () => {
            try {
                if (isAuthenticated) {
                    const result = await getHasAcceptedTermsOfUse();
                    setShowTermsDialog(!result?.accepted);
                }
            } catch (err) {
                handleError('Error retrieving terms acceptance status', err);
            }
        })();
    }, [isAuthenticated, dispatch]);

    const onAcceptTermsClick = () => {
        setShowTermsDialog(false);
        postAcceptTermsOfUse();
    };

    const { notifications, incomingNotification } = useSelector((state) => state.notification);

    // Prevent incoming notifications from re-appearing
    useEffect(() => {
        dispatch(clearIncomingNotification());
    }, [incomingNotification, dispatch]);

    const onNotificationsDropdownClose = () => {
        const newlySeenIds = notifications
            .filter((notification) => notification.status === BaseNotification.Statuses.NEW)
            .map((notification) => notification.id);
        if (newlySeenIds.length > 0) {
            dispatch(updateNotificationStatuses(BaseNotification.Statuses.SEEN, newlySeenIds));
        }
    };
    const onNotificationClick = (id) => {
        dispatch(updateNotificationStatus(BaseNotification.Statuses.INTERACTED, id));
    };
    const onRemoveNotificationClick = (id) => {
        dispatch(updateNotificationStatus(BaseNotification.Statuses.DELETED, id));
    };
    const onClearAllNotificationsClick = () => {
        const idList = notifications.map((notification) => notification.id);
        dispatch(updateNotificationStatuses(BaseNotification.Statuses.DELETED, idList));
    };

    const onLogOutClick = () => {
        sessionStorage.clear();
        datadogRum.clearUser();
        logout({ logoutParams: { returnTo: `${window.location.origin}${AppRoutes.loggedOut}` } });
    };

    return (
        <>
            <StrataHeader
                navBar={navTabs}
                onProfileClick={() => setShowUserProfileDialog(true)}
                onNotificationSettingsClick={
                    canSeePayerRequests
                        ? () => setShowUserNotificationSettingsDialog(true)
                        : undefined
                }
                onLogOutClick={onLogOutClick}
                showNotifications={isAuthenticated}
                notifications={notifications}
                incomingNotification={incomingNotification}
                NotificationComponentMap={NotificationComponentMap}
                onNotificationClick={onNotificationClick}
                onRemoveNotificationClick={onRemoveNotificationClick}
                onClearAllNotificationsClick={onClearAllNotificationsClick}
                onNotificationsDropdownClose={onNotificationsDropdownClose}
                showProfileDropdown={isAuthenticated}
                onTermsOfUseClick={() => openInNewTab(AppRoutes.termsOfUse)}
                onPrivacyPolicyClick={() => openInNewTab(AppRoutes.privacyPolicy)}
                appVersion={showAppVersion && process.env.REACT_APP_VERSION}
            />
            {isAuthenticated && (
                <>
                    <TermsOfUseDialog
                        isOpen={showTermsDialog}
                        onAcceptClick={onAcceptTermsClick}
                        onSignOutClick={onLogOutClick}
                    />
                    <UserNotificationSettingsDialog
                        onSecondaryButtonClick={() => setShowUserNotificationSettingsDialog(false)}
                        isOpen={showUserNotificationSettingsDialog}
                    />
                    <UserProfileDialog
                        onSecondaryButtonClick={() => setShowUserProfileDialog(false)}
                        isOpen={showUserProfileDialog}
                    />
                    {!showTermsDialog && <FeatureCarousel />}
                </>
            )}
        </>
    );
};
