import { IconNames } from '@blueprintjs/icons';
import { DateFormats, formatDate } from '@strata/helper-functions';
import { RequestChatIcon, RequestType, UserCell } from '@strata/react-chat';
import { GridWithFilters, PrimaryData, SecondaryData } from '@strata/react-grid';
import { InlineMessage } from '@strata/react-inline-message';
import { Icon } from '@strata/react-status-icons';
import { PayerRequestStatusIndicator } from '@strata/react-status-indicators';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ViewsControls } from 'components/shared/ViewsControls';
import { AppRoutes } from 'services/routes';
import { useUserNameMap } from 'store/api/authApi';
import { useGetOpenRequestsByTypeQuery } from 'store/api/payerRequestApi';
import { useGridState } from 'store/grids/gridsSlice';

const initialSortProperties = [{ id: 'createDate', desc: true }];

const customSearchValueHandlers = {
    createDate: (value) => formatDate(value, DateFormats.DATE_TIME),
    requestStatus: (value) => PayerRequestStatusIndicator.StatusMessages[value],
};

const filterCategories = ({ requestType }) => [
    ...(requestType !== RequestType.OTHER
        ? [{ key: 'requestSubType', title: 'Request Type' }]
        : []),
    {
        key: 'requestStatus',
        title: 'Status',
        valueDisplayTextMap: PayerRequestStatusIndicator.StatusMessages,
    },
    {
        key: 'urgent',
        title: 'Request Urgency',
        customValues: [{ value: 'true', text: 'Urgent' }],
    },
];

const columns = ({ requestType }) => [
    {
        ...{
            [RequestType.ESCALATION]: { accessorKey: 'memberId', header: 'Member ID' },
            [RequestType.CHANGE]: { accessorKey: 'requestSubType', header: 'Request Type' },
            [RequestType.OTHER]: { accessorKey: 'summary', header: 'Summary' },
        }[requestType],
        size: 180,
        cell: ({ getValue }) => <PrimaryData data={getValue()} />,
    },
    {
        ...{
            [RequestType.ESCALATION]: { accessorKey: 'requestSubType', header: 'Request Type' },
            [RequestType.CHANGE]: { accessorKey: 'summary', header: 'Summary' },
            [RequestType.OTHER]: { accessorKey: 'additionalNotes', header: 'Description' },
        }[requestType],
        size: 180,
    },
    {
        accessorKey: 'createDate',
        header: 'Created On',
        size: 160,
        cell: ({ getValue }) => (
            <SecondaryData data={formatDate(getValue(), DateFormats.DATE_TIME)} />
        ),
    },
    {
        accessorKey: 'submitterUpn',
        header: 'Created By',
        size: 150,
        cell: ({ getValue, row }) => (
            <UserCell upn={getValue()} fullName={row.original.submitterFullName} small />
        ),
    },
    {
        accessorKey: 'isUnread',
        header: () => <Icon icon={IconNames.CHAT} />,
        enableSorting: false,
        size: 48,
        cell: ({ getValue }) => <RequestChatIcon isUnread={getValue()} />,
    },
    {
        accessorKey: 'urgent',
        header: () => <Icon icon={IconNames.WARNING_SIGN} intent={Icon.Intent.WARNING} />,
        enableSorting: false,
        size: 48,
        cell: ({ getValue }) =>
            getValue() ? (
                <Icon icon={IconNames.WARNING_SIGN} intent={Icon.Intent.WARNING} title="Urgent" />
            ) : null,
    },
    {
        accessorKey: 'requestNumber',
        header: 'Request #',
        size: 90,
    },
    {
        accessorKey: 'requestStatus',
        header: 'Status',
        size: 120,
        cell: ({ getValue }) => <PayerRequestStatusIndicator status={getValue()} />,
    },
];

export const RequestsGrid = ({ requestType }) => {
    const navigate = useNavigate();
    const { payerId, segmentIds } = useSelector((state) => state.auth);
    const { segmentId } = useSelector((state) => state.views);

    const { data, isFetching } = useGetOpenRequestsByTypeQuery({
        payerId,
        requestType,
        segmentIds: segmentId ? [segmentId] : segmentIds,
    });
    const userNameMap = useUserNameMap({ payerId });
    const requests = useMemo(
        () =>
            data?.requests?.map((request) => ({
                ...request,
                submitterFullName: userNameMap?.[request.submitterUpn],
            })),
        [data?.requests, userNameMap],
    );

    const {
        sort,
        search,
        activeFilters,
        filterPaneWidth,
        updateGridSort,
        updateGridSearch,
        updateGridActiveFilters,
        updateFilterPaneWidth,
    } = useGridState(requestType, initialSortProperties);

    return (
        <GridWithFilters
            headerContent={
                data?.filteredCount > 0 ? (
                    <div className="margin__bottom--medium">
                        <InlineMessage
                            intent={InlineMessage.Intent.INFO}
                            label={`The ${data.requests?.length} requests viewable below do not include ${data.filteredCount} restricted requests hidden from view.`}
                        />
                    </div>
                ) : undefined
            }
            viewsPaneContent={<ViewsControls />}
            data={requests}
            isLoading={isFetching}
            columns={columns({ requestType })}
            sortProperties={sort}
            onSort={updateGridSort}
            enableSearch
            searchText={search}
            onSearch={updateGridSearch}
            customSearchValueHandlers={customSearchValueHandlers}
            filterCategories={filterCategories({ requestType })}
            activeFilters={activeFilters}
            onFilter={updateGridActiveFilters}
            filterPaneRenderWidth={filterPaneWidth}
            onFilterPaneResize={updateFilterPaneWidth}
            onRowClick={(rowData) => navigate(AppRoutes.requestDetails(rowData.requestId))}
        />
    );
};

RequestsGrid.propTypes = {
    requestType: PropTypes.oneOf(Object.values(RequestType)).isRequired,
};
