import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

/**
 * Format json values into form data or query params for fastapi
 * @param {object} json json data to be formatted
 * @param {boolean} isFormData true for FormData, false for URLSearchParams
 * @returns FormData or URLSearchParams instance containing the values from json
 */
export const jsonToFormDataOrUrlSearchParams = (json, isFormData) => {
    const formattedData = new (isFormData ? FormData : URLSearchParams)();
    Object.entries(json).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach((item) => formattedData.append(key, item));
        } else if (value != null) {
            formattedData.append(key, value);
        }
    });
    return formattedData;
};

export const getBaseQuery = (url) =>
    fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BASE_URL}${url}`,
        paramsSerializer: (params) => jsonToFormDataOrUrlSearchParams(params, false),
        prepareHeaders: (headers, { getState }) => {
            headers.set('authorization', `Bearer ${getState().auth.accessToken}`);
            return headers;
        },
    });

export const addNpiHeader = (headers, payerId) => {
    headers.set('x-npi', payerId);
};
