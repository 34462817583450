import { DateFormats, formatDate } from '@strata/helper-functions';
import { InlineMessage } from '@strata/react-inline-message';
import dayjs from 'dayjs';

export const TimeFrameBanner = () => (
    <div className="margin__bottom--medium">
        <InlineMessage
            intent={InlineMessage.Intent.INFO}
            label={`Data is representative of all active, assigned referrals through ${formatDate(
                dayjs().subtract(1, 'day'),
                DateFormats.DATE_LONG,
            )}.`}
        />
    </div>
);
