import { ActionButtonPageTitle } from '@strata/react-buttons';
import { FormHeaderAndTabbedContent } from '@strata/react-form-header-and-content';
import { FormTitleLabel } from '@strata/react-label-value-pairs';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ProductDetailsOverviewTab } from 'components/products/ProductDetailsOverviewTab';
import { ServicingPharmaciesTab } from 'components/products/ServicingPharmaciesTab';
import { ProductLabel } from 'components/shared/ProductLabel';
import { AppRoutes } from 'services/routes';
import { useGetMedicationDetailsQuery } from 'store/api/apertureApi';

export const ProductDetailsPage = () => {
    const { oid, isBrand, referentPriceType } = useParams();
    const navigate = useNavigate();
    const location = useLocation();

    const { segmentId } = useSelector((state) => state.views);
    const { data: productDetails, isFetching } = useGetMedicationDetailsQuery({
        segmentId,
        productServiceOid: oid,
        isBrand,
        referentPriceType,
    });

    return (
        <FormHeaderAndTabbedContent
            rowOneContentHeader={
                <ActionButtonPageTitle
                    label="Products"
                    title="Product Details"
                    onClick={() => navigate(AppRoutes.products)}
                />
            }
            rowTwoContentHeader={
                <FormTitleLabel
                    label={
                        <ProductLabel
                            product={productDetails?.drugName}
                            isBrand={productDetails?.isBrand}
                        />
                    }
                />
            }
            tabs={[
                {
                    title: 'Overview',
                    mainContent: (
                        <ProductDetailsOverviewTab
                            productDetails={productDetails}
                            isLoading={isFetching}
                        />
                    ),
                    className: 'tabbed-grid-content',
                },
                {
                    title: 'Servicing Pharmacies',
                    mainContent: <ServicingPharmaciesTab product={productDetails?.drugName} />,
                    className: 'tabbed-grid-content',
                },
            ]}
            activeTab={location.hash.replace(/^#/, '').replace(/-/g, ' ')}
            onTabSelect={(tab) =>
                navigate({
                    ...location,
                    hash: tab.replace(/\s/g, '-').toLocaleLowerCase(),
                })
            }
        />
    );
};
