import { IconNames } from '@blueprintjs/icons';
import { DateFormats, formatDate, sortAsc } from '@strata/helper-functions';
import {
    ChangeRequestSubType,
    MemberEscalationSubType,
    RequestChatIcon,
    RequestType,
    RequestTypeDisplayTextMap,
    UserCell,
} from '@strata/react-chat';
import { GridWithFilters, PrimaryData, SecondaryData, TimeFrameOptions } from '@strata/react-grid';
import { InlineMessage } from '@strata/react-inline-message';
import { Icon } from '@strata/react-status-icons';
import { PayerRequestStatusIndicator } from '@strata/react-status-indicators';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ViewsControls } from 'components/shared/ViewsControls';
import { ReadPermissionRequestTypeMap } from 'services/payerRequests';
import { AppRoutes } from 'services/routes';
import { useUserNameMap } from 'store/api/authApi';
import { useGetArchivedRequestsQuery } from 'store/api/payerRequestApi';
import { useGridState } from 'store/grids/gridsSlice';

const initialSortProperties = [{ id: 'completedDate', desc: true }];

const timeFrameConfig = {
    key: 'completedDate',
    title: 'Request Time Frame',
    defaultValue: TimeFrameOptions.PAST_WEEK,
    optionOverrideMap: {
        [TimeFrameOptions.PAST_DAY]: { text: 'Today' },
        [TimeFrameOptions.PAST_WEEK]: { text: 'Last 7 Days' },
        [TimeFrameOptions.PAST_MONTH]: { text: 'Last 30 Days' },
        [TimeFrameOptions.PAST_QUARTER]: { text: 'Last 3 Months' },
        [TimeFrameOptions.PAST_YEAR]: { text: 'Last 12 Months' },
        ALL_TIME: { text: 'All', handler: () => true },
    },
};

const filterCategories = (permissions, filters) => [
    {
        key: 'requestType',
        title: 'Request Category',
        customValues: Object.entries(ReadPermissionRequestTypeMap)
            // Only show requestTypes user has permissions for
            .filter(([permission]) => permissions.includes(permission))
            .map(([_, value]) => ({
                value,
                text: RequestTypeDisplayTextMap[value],
            })),
    },
    {
        key: 'requestSubType',
        title: 'Request Type',
        customValues: [
            ...new Set([
                ...Object.values(ChangeRequestSubType),
                ...Object.values(MemberEscalationSubType),
            ]),
        ]
            .sort(sortAsc)
            .map((value) => ({ value, text: value })),
    },
    {
        key: 'reason',
        title: 'Reason',
        customValues: filters
            ?.find((filter) => filter.key === 'reason')
            ?.values?.map((value) => ({ value, text: value })),
    },
    {
        key: 'requestStatus',
        title: 'Status',
        customValues: [
            PayerRequestStatusIndicator.Statuses.COMPLETED,
            PayerRequestStatusIndicator.Statuses.CANCELED,
        ].map((value) => ({ value, text: PayerRequestStatusIndicator.StatusMessages[value] })),
    },
    {
        key: 'urgent',
        title: 'Request Urgency',
        customValues: [{ value: 'true', text: 'Urgent' }],
    },
];

const columns = [
    {
        accessorKey: 'requestType',
        header: 'Request Category',
        size: 200,
        cell: ({ getValue }) => <PrimaryData data={RequestTypeDisplayTextMap[getValue()]} />,
    },
    {
        accessorKey: 'memberId',
        header: 'Member ID',
        size: 120,
    },
    {
        accessorKey: 'requestSubType',
        header: 'Request Type',
        size: 150,
    },
    {
        accessorKey: 'summary',
        header: 'Summary',
        size: 150,
    },
    {
        accessorKey: 'submitterUpn',
        header: 'Created By',
        size: 150,
        cell: ({ getValue, row }) => (
            <UserCell upn={getValue()} fullName={row.original.submitterFullName} small />
        ),
    },
    {
        accessorKey: 'completedDate',
        header: 'Completed On',
        size: 160,
        cell: ({ getValue }) => (
            <SecondaryData data={formatDate(getValue(), DateFormats.DATE_TIME)} />
        ),
    },
    {
        accessorKey: 'isUnread',
        header: () => <Icon icon={IconNames.CHAT} />,
        enableSorting: false,
        size: 50,
        cell: ({ getValue }) => <RequestChatIcon isUnread={getValue()} />,
    },
    {
        accessorKey: 'urgent',
        header: () => <Icon icon={IconNames.WARNING_SIGN} intent={Icon.Intent.WARNING} />,
        enableSorting: false,
        size: 50,
        cell: ({ getValue }) =>
            getValue() ? (
                <Icon icon={IconNames.WARNING_SIGN} intent={Icon.Intent.WARNING} title="Urgent" />
            ) : null,
    },
    {
        accessorKey: 'requestNumber',
        header: 'Request #',
        size: 90,
    },
    {
        accessorKey: 'reason',
        header: 'Reason',
        size: 150,
    },
    {
        accessorKey: 'requestStatus',
        header: 'Status',
        size: 120,
        cell: ({ getValue }) => <PayerRequestStatusIndicator status={getValue()} />,
    },
];

export const RequestArchiveGrid = () => {
    const navigate = useNavigate();
    const { payerId, permissions, segmentIds } = useSelector((state) => state.auth);
    const { segmentId } = useSelector((state) => state.views);

    const {
        sort,
        search,
        activeFilters,
        filterPaneWidth,
        page,
        updateGridSort,
        updateGridSearch,
        updateGridActiveFilters,
        updateFilterPaneWidth,
        updateCurrentPage,
    } = useGridState(RequestType.ARCHIVE, initialSortProperties);

    const apiQueryParams = {
        page,
        payerId,
        segmentIds: segmentId ? [segmentId] : segmentIds,
        searchText: search,
        timeframe: activeFilters?.completedDate?.[0],
        requestType: activeFilters?.requestType,
        requestSubType: activeFilters?.requestSubType,
        reason: activeFilters?.reason,
        requestStatus: activeFilters?.requestStatus,
        urgent: activeFilters?.reason?.[0],
    };
    const apiQueryOptions = { skip: !activeFilters };
    const { data: firstPageData, isFetching: isFirstPageDataFetching } =
        useGetArchivedRequestsQuery({ ...apiQueryParams, page: 0 }, apiQueryOptions);
    const { data: currentPageData, isFetching: isCurrentPageDataFetching } =
        useGetArchivedRequestsQuery(apiQueryParams, apiQueryOptions);

    const userNameMap = useUserNameMap({ payerId });
    const requests = useMemo(
        () =>
            currentPageData?.requests?.map((request) => ({
                ...request,
                submitterFullName: userNameMap?.[request.submitterUpn],
            })),
        [currentPageData?.requests, userNameMap],
    );

    return (
        <GridWithFilters
            headerContent={
                firstPageData?.filteredCount > 0 ? (
                    <div className="margin__bottom--medium">
                        <InlineMessage
                            intent={InlineMessage.Intent.INFO}
                            label={`The ${firstPageData.count} requests viewable below do not include ${firstPageData.filteredCount} restricted requests hidden from view.`}
                        />
                    </div>
                ) : undefined
            }
            viewsPaneContent={<ViewsControls />}
            data={requests}
            isLoading={isFirstPageDataFetching || isCurrentPageDataFetching}
            columns={columns}
            sortProperties={sort}
            onSort={updateGridSort}
            enableSearch
            searchText={search}
            onSearch={updateGridSearch}
            timeFrameConfig={timeFrameConfig}
            filterCategories={filterCategories(permissions, firstPageData?.filters)}
            activeFilters={activeFilters}
            onFilter={updateGridActiveFilters}
            filterPaneRenderWidth={filterPaneWidth}
            onFilterPaneResize={updateFilterPaneWidth}
            currentPage={page}
            onPageChange={updateCurrentPage}
            totalCount={firstPageData?.count}
            onRowClick={(rowData) => navigate(AppRoutes.requestDetails(rowData.requestId))}
        />
    );
};
