import { formatPhoneNumber } from '@strata/helper-functions';
import { ActionButtonPageTitle } from '@strata/react-buttons';
import { FormHeaderAndContent } from '@strata/react-form-header-and-content';
import {
    AddressHeaderLabelValue,
    FormTitleLabel,
    HeaderLabelValuePair,
} from '@strata/react-label-value-pairs';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { ReferralGrid } from 'components/shared/ReferralGrid';
import { Permissions } from 'services/constants';
import { useGoBack } from 'services/navigationHelpers';
import { AppRoutes } from 'services/routes';
import { useGetPrescriberDetailsQuery } from 'store/api/referenceApi';

const backToDestinationDefault = {
    route: AppRoutes.prescribers,
    text: 'Prescribers',
};

export const PrescriberDetailsPage = () => {
    const { npi } = useParams();
    const { permissions } = useSelector((state) => state.auth);

    const { data: prescriberDetails, isLoading } = useGetPrescriberDetailsQuery({ npi });

    const { backToDestination, goBack } = useGoBack(backToDestinationDefault);

    // navigate back to product list if npi is invalid
    useEffect(() => {
        if (!isLoading && !prescriberDetails?.npi) {
            goBack(true);
        }
    }, [prescriberDetails, isLoading, goBack]);

    return (
        <FormHeaderAndContent
            rowOneContentHeader={
                <ActionButtonPageTitle
                    label={backToDestination.text}
                    title="Prescriber Details"
                    onClick={goBack}
                />
            }
            rowTwoContentHeader={
                <>
                    <FormTitleLabel label={prescriberDetails?.name} />
                    <AddressHeaderLabelValue
                        label="Practice Address"
                        address1={prescriberDetails?.address1}
                        address2={prescriberDetails?.address2}
                        city={prescriberDetails?.city}
                        state={prescriberDetails?.state}
                        zip={prescriberDetails?.zip}
                    />
                    <HeaderLabelValuePair
                        label="Specialty"
                        value={prescriberDetails?.specialization}
                    />
                    <HeaderLabelValuePair label="NPI" value={prescriberDetails?.npi} />
                    <HeaderLabelValuePair
                        label="Phone"
                        value={formatPhoneNumber(prescriberDetails?.phone)}
                    />
                    <HeaderLabelValuePair
                        label="Fax"
                        value={formatPhoneNumber(prescriberDetails?.fax)}
                    />
                    <HeaderLabelValuePair
                        label="Practice NPI"
                        value={prescriberDetails?.practiceNpi}
                    />
                </>
            }
            className="tabbed-grid-content"
            mainContent={
                permissions.includes(Permissions.READ_DETAIL) && (
                    <ReferralGrid
                        title="Prescriber Referrals"
                        prescriberNpi={npi}
                        parentLocationName="Prescriber Details"
                    />
                )
            }
        />
    );
};
