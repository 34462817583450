import { createSlice } from '@reduxjs/toolkit';

import { getReferrals } from 'api/apertureAPI';
import { handleError } from 'services/Error';

const initialState = {
    pages: {},
    currentPage: 0,
    npi: undefined,
    referralCount: undefined,
    filteredCount: undefined,
    filterCategories: undefined,
};

export const referralListSlice = createSlice({
    name: 'referralList',
    initialState,
    reducers: {
        setCurrentPage(state, action) {
            const { currentPage } = action.payload;
            return { ...state, currentPage };
        },
        setPageData(state, action) {
            const { referrals, page } = action.payload;
            return {
                ...state,
                pages: {
                    ...state.pages,
                    [page]: referrals,
                },
            };
        },
        setAdditionalReferralsData(state, action) {
            const { npi, referralCount, filteredCount, filterCategories } = action.payload;
            return {
                ...state,
                npi,
                referralCount,
                filteredCount,
                filterCategories,
            };
        },
        resetReferralData(state) {
            const { npi } = state;
            return { ...initialState, npi };
        },
    },
});

export const { setCurrentPage, setPageData, setAdditionalReferralsData, resetReferralData } =
    referralListSlice.actions;

const referralMapper = (referral) => ({
    referralId: referral.referral_id,
    treatmentId: referral.treatment_id,
    segmentId: referral.segment_id,
    memberId: referral.member_id,
    productServiceDescription: referral.product_service_description,
    isBrand: referral.is_brand,
    supplierNpi: referral.supplier_npi,
    prescriberNpi: referral.prescriber_npi,
    winningBidRate: referral.winning_bid_rate,
    referralStatus: referral.referral_status,
    receivedDate: referral.received_date,
    referentPriceType: referral.referent_price_type,
    therapeuticCategory: referral.therapeutic_category,
});

export const fetchReferrals =
    ({ segmentId, searchText, supplierNpi, prescriberNpi, activeFilters }) =>
    async (dispatch, getState) => {
        try {
            const page = getState().referralList.currentPage;
            const response = await getReferrals({
                segmentId,
                page,
                searchText,
                supplierNpi,
                prescriberNpi,
                activeFilters,
            });

            const referrals = response?.referrals?.map(referralMapper);

            // Additional data only returned w/ first page
            if (page === 0) {
                const filterCategoryTitleMap = {
                    therapeutic_category: 'Therapeutic Category',
                    product_service_description: 'Product',
                };
                const filterCategories = [
                    ...(response?.filters?.map(({ key, values }) => ({
                        key,
                        title: filterCategoryTitleMap[key],
                        customValues: values?.map((value) => ({ value })) ?? [],
                    })) ?? []),
                    {
                        key: 'is_brand',
                        title: 'Brand/Generic',
                        customValues: [
                            { value: true, text: 'Brand' },
                            { value: false, text: 'Generic' },
                        ],
                    },
                ];
                dispatch(
                    setAdditionalReferralsData({
                        npi: supplierNpi ?? prescriberNpi,
                        referralCount: response?.count,
                        filteredCount: response?.filtered_count,
                        filterCategories,
                    }),
                );
            }

            dispatch(setPageData({ referrals, page }));
        } catch (err) {
            handleError('An error occurred retrieving referrals.', err);
        }
    };

export const updatePage = (currentPage) => (dispatch) => {
    dispatch(setCurrentPage({ currentPage }));
};
