import { DateFormats, formatDate } from '@strata/helper-functions';
import { ActionButtonPageTitle } from '@strata/react-buttons';
import { ButtonWithDropdownMenu } from '@strata/react-dropdown-menu';
import { FormHeaderAndTabbedContent } from '@strata/react-form-header-and-content';
import { FormTitleLabelValuePair, HeaderLabelValuePair } from '@strata/react-label-value-pairs';
import { ServicingRate } from '@strata/react-servicing-rate';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { ClinicalPolicyDialog } from 'components/referrals/dialogs/ClinicalPolicyDialog';
import { PerformanceProgress } from 'components/referrals/PerformanceProgress';
import { ReferralDetailsOverview } from 'components/referrals/ReferralDetailsOverview';
import { ApertureReferralStatusIndicator } from 'components/shared/ApertureReferralStatusIndicator';
import { Permissions } from 'services/constants';
import { useGoBack } from 'services/navigationHelpers';
import { AppRoutes } from 'services/routes';
import { useGetMedicationQuery } from 'store/api/referenceApi';
import {
    fetchPerformanceEntries,
    fetchReferralDetails,
    fetchReferralMilestones,
} from 'store/referrals/referralDetailsSlice';

const currentLocation = {
    text: 'Referral Details',
};

const backToDestinationDefault = {
    route: AppRoutes.treatments,
    text: 'Member Search',
};

export const ReferralDetailsPage = () => {
    const { referralId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { payerId, permissions } = useSelector((state) => state.auth);
    const referralDetails = useSelector(
        (state) => state.referralDetails.referralDetailsData[referralId],
    );
    const performanceEntries = useSelector(
        (state) => state.referralDetails.performanceEntries[referralId],
    );
    const { data: medicationDetails } = useGetMedicationQuery(
        { oid: referralDetails?.productServiceOid },
        { skip: !referralDetails },
    );

    useEffect(() => {
        dispatch(fetchReferralDetails({ payerId, referralId }));
        dispatch(fetchReferralMilestones({ payerId, referralId }));
        dispatch(fetchPerformanceEntries({ payerId, referralId }));
    }, [dispatch, payerId, referralId]);

    const { backToDestination, goBack } = useGoBack(backToDestinationDefault);

    // navigate back to referral list if id is invalid
    useEffect(() => {
        if (referralDetails && !referralDetails.referralId) {
            goBack();
        }
    }, [referralDetails, goBack]);

    const [showClinicalPolicyDialog, setShowClinicalPolicyDialog] = useState(false);

    // Actions button menu items
    const menuItems =
        referralDetails?.clinicalInfo?.clinicalPolicyAnswers?.length > 0
            ? [
                  {
                      text: 'View Clinical Policy Responses',
                      onItemClick: () => setShowClinicalPolicyDialog(true),
                  },
              ]
            : [];

    if (referralDetails?.treatmentId) {
        menuItems.push({
            text: 'Go to Member Treatment',
            onItemClick: () =>
                navigate(AppRoutes.treatmentDetails(referralDetails.treatmentId), {
                    state: {
                        backToDestination: currentLocation,
                    },
                }),
        });
    }

    return (
        <>
            <ClinicalPolicyDialog
                isOpen={showClinicalPolicyDialog}
                onClose={() => setShowClinicalPolicyDialog(false)}
                referralDetails={referralDetails}
            />
            <FormHeaderAndTabbedContent
                rowOneContentHeader={
                    <>
                        <ActionButtonPageTitle
                            label={backToDestination.text}
                            title={currentLocation.text}
                            onClick={goBack}
                        />
                        {menuItems.length > 0 && (
                            <div style={{ marginLeft: 'auto' }}>
                                <ButtonWithDropdownMenu
                                    label="Actions"
                                    menuItems={menuItems}
                                    intent={ButtonWithDropdownMenu.Intent.PRIMARY}
                                />
                            </div>
                        )}
                    </>
                }
                rowTwoContentHeader={
                    referralDetails && (
                        <>
                            <FormTitleLabelValuePair
                                label={referralDetails?.memberName}
                                value={referralDetails?.memberId}
                            />
                            <HeaderLabelValuePair
                                label="Product"
                                value={medicationDetails?.description}
                            />
                            {permissions.includes(Permissions.READ_REFERRAL_RATES) && (
                                <HeaderLabelValuePair
                                    label="Referral Rate"
                                    value={
                                        <ServicingRate
                                            servicingRate={referralDetails?.winningBidRate}
                                            referentPriceType={referralDetails?.referentPriceType}
                                        />
                                    }
                                />
                            )}
                            <HeaderLabelValuePair
                                label="Date Received"
                                value={formatDate(
                                    referralDetails.receivedTime,
                                    DateFormats.DATE_TIME,
                                )}
                            />
                            <HeaderLabelValuePair
                                label="Referral Status"
                                value={
                                    <ApertureReferralStatusIndicator
                                        status={referralDetails?.referralStatus}
                                    />
                                }
                            />
                        </>
                    )
                }
                tabs={[
                    {
                        title: 'Overview',
                        mainContent: (
                            <ReferralDetailsOverview referralId={referralId} showMemberDetails />
                        ),
                    },
                    {
                        title: 'Performance Progress',
                        rowOneContent: performanceEntries?.length > 0 && (
                            <PerformanceProgress performanceEntries={performanceEntries} />
                        ),
                    },
                ]}
                activeTab={location.hash.replace(/^#/, '').replace(/-/g, ' ')}
                onTabSelect={(tab) =>
                    navigate({
                        ...location,
                        hash: tab.replace(/\s/g, '-').toLocaleLowerCase(),
                    })
                }
            />
        </>
    );
};
