import { ClinicalPolicyDialog as CPDialog } from '@strata/react-dialog';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getQuestionTree } from 'api/clinicalPolicyAPI';
import { handleError } from 'services/Error';

export const ClinicalPolicyDialog = ({ referralDetails = undefined, isOpen, onClose }) => {
    const { payerId } = useSelector((state) => state.auth);

    const [questions, setQuestions] = useState();
    useEffect(() => {
        if (isOpen) {
            (async () => {
                try {
                    if (referralDetails?.productServiceOid && referralDetails?.diagnosisOid) {
                        const questionPayload = await getQuestionTree({
                            payerId,
                            productServiceOid: referralDetails.productServiceOid,
                            diagnosisOid: referralDetails.diagnosisOid,
                        });
                        setQuestions(questionPayload.nodes);
                    }
                } catch (err) {
                    onClose();
                    handleError('An error occurred retrieving clinical policy.', err);
                }
            })();
        }
    }, [isOpen, onClose, payerId, referralDetails]);

    return (
        <CPDialog
            isOpen={isOpen}
            onClose={onClose}
            questions={questions}
            answers={referralDetails?.clinicalInfo?.clinicalPolicyAnswers}
        />
    );
};

ClinicalPolicyDialog.propTypes = {
    referralDetails: PropTypes.shape({
        productServiceOid: PropTypes.string,
        diagnosisOid: PropTypes.string,
        clinicalInfo: PropTypes.shape({
            clinicalPolicyAnswers: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};
