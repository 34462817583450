import { PrivacyPolicy, TermsOfUse } from '@strata/react-page-templates';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ClinicalPoliciesListPage } from 'components/admin/ClinicalPoliciesListPage';
import { ClinicalPolicyDetailsPage } from 'components/admin/ClinicalPolicyDetailsPage';
import { AuthCallback } from 'components/auth/AuthCallback';
import { LoggedOut } from 'components/auth/LoggedOut';
import { RequireAuth } from 'components/auth/RequireAuth';
import { DataLakeColdStartCheck } from 'components/DataLakeColdStartCheck';
import { Home } from 'components/home/Home';
import { UpdatesHistoryScreen } from 'components/home/UpdatesHistoryScreen';
import { PharmaciesPage } from 'components/pharmacies/PharmaciesPage';
import { PharmacyDetailsPage } from 'components/pharmacies/PharmacyDetailsPage';
import { PrescriberDetailsPage } from 'components/prescribers/PrescriberDetailsPage';
import { PrescribersPage } from 'components/prescribers/PrescribersPage';
import { ProductDetailsPage } from 'components/products/ProductDetailsPage';
import { ProductsPage } from 'components/products/ProductsPage';
import { ReferralDetailsPage } from 'components/referrals/ReferralDetailsPage';
import { RequestDetailsPage } from 'components/requests/RequestDetailsPage';
import { RequestsPage } from 'components/requests/RequestsPage';
import { MemberSearchPage } from 'components/treatments/MemberSearchPage';
import { TreatmentDetailPage } from 'components/treatments/TreatmentDetailPage';
import { PayerRequestReadPermissions, Permissions } from 'services/constants';
import { PayerFeatures } from 'services/FeatureFlags';
import { AppRoutes } from 'services/routes';

export const App = () => (
    <BrowserRouter basename="/">
        <Routes>
            <Route path={AppRoutes.callback} element={<AuthCallback />} />
            <Route path={AppRoutes.loggedOut} element={<LoggedOut />} />
            <Route element={<RequireAuth />}>
                <Route path={AppRoutes.home} element={<Home />} />
                <Route path={AppRoutes.termsOfUse} element={<TermsOfUse />} />
                <Route path={AppRoutes.privacyPolicy} element={<PrivacyPolicy />} />
                <Route path={AppRoutes.updatesHistory} element={<UpdatesHistoryScreen />} />
            </Route>
            <Route
                element={
                    <RequireAuth
                        requiredPayerFeature={PayerFeatures.APREFERRAL}
                        permissionsToCheck={[Permissions.READ_SUMMARY]}
                    />
                }
            >
                <Route element={<DataLakeColdStartCheck />}>
                    <Route path={AppRoutes.products} element={<ProductsPage />} />
                    <Route path={AppRoutes.productDetails()} element={<ProductDetailsPage />} />
                    <Route path={AppRoutes.prescribers} element={<PrescribersPage />} />
                    <Route
                        path={AppRoutes.prescriberDetails()}
                        element={<PrescriberDetailsPage />}
                    />
                    <Route path={AppRoutes.pharmacies} element={<PharmaciesPage />} />
                    <Route path={AppRoutes.pharmacyDetails()} element={<PharmacyDetailsPage />} />
                </Route>
            </Route>
            <Route
                element={
                    <RequireAuth
                        requiredPayerFeature={PayerFeatures.APREFERRAL}
                        permissionsToCheck={[Permissions.READ_DETAIL]}
                    />
                }
            >
                <Route path={AppRoutes.treatments} element={<MemberSearchPage />} />
                <Route path={AppRoutes.treatmentDetails()} element={<TreatmentDetailPage />} />
                <Route path={AppRoutes.referralDetails()} element={<ReferralDetailsPage />} />
            </Route>
            <Route
                element={
                    <RequireAuth
                        requiredPayerFeature={PayerFeatures.REQUESTS}
                        permissionsToCheck={Object.values(PayerRequestReadPermissions)}
                    />
                }
            >
                <Route path={AppRoutes.requests} element={<RequestsPage />} />
                <Route path={AppRoutes.requestDetails()} element={<RequestDetailsPage />} />
            </Route>
            <Route
                element={
                    <RequireAuth
                        requiredPayerFeature={PayerFeatures.POLICY_MAN}
                        permissionsToCheck={[Permissions.READ_CLINICAL_POLICY]}
                    />
                }
            >
                <Route path={AppRoutes.clinicalPolicies} element={<ClinicalPoliciesListPage />} />
                <Route
                    path={AppRoutes.clinicalPolicyDetails()}
                    element={<ClinicalPolicyDetailsPage />}
                />
            </Route>
            <Route path="*" element={<Navigate replace to={AppRoutes.home} />} />
        </Routes>
    </BrowserRouter>
);
