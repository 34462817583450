import { IconNames } from '@blueprintjs/icons';
import { BenefitTypes } from '@strata/constants';
import { DateFormats, formatDate, formatNameLastFirst } from '@strata/helper-functions';
import { ActionButtonPageTitle } from '@strata/react-buttons';
import { RequestType, RequestTypeDisplayTextMap } from '@strata/react-chat';
import { CollapsibleSection } from '@strata/react-collapsible-section';
import { ButtonWithDropdownMenu } from '@strata/react-dropdown-menu';
import { FormHeaderAndContent } from '@strata/react-form-header-and-content';
import { FormSection } from '@strata/react-form-section';
import { Grid, SecondaryData } from '@strata/react-grid';
import { FormTitleLabelValuePair, HeaderLabelValuePair } from '@strata/react-label-value-pairs';
import { MessagingBlock } from '@strata/react-messaging-block';
import { ReferralStatusIndicator, TreatmentStatusIndicator } from '@strata/react-status-indicators';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { ReferralDetailsOverview } from 'components/referrals/ReferralDetailsOverview';
import { SubmitRequestDialog } from 'components/requests/dialogs/SubmitRequestDialog';
import { AuthDetails } from 'components/treatments/AuthDetails';
import { TreatmentMemberDetails } from 'components/treatments/TreatmentMemberDetails';
import { Permissions } from 'services/constants';
import { PayerFeatures, usePayerFeatures } from 'services/FeatureFlags';
import { useGoBack } from 'services/navigationHelpers';
import { AppRoutes } from 'services/routes';
import { useGetNeedsByDateQuery } from 'store/api/performanceApi';
import { useGetAllProductServicesQuery, useGetSupplierMapQuery } from 'store/api/referenceApi';
import { useGetMemberDetailsQuery } from 'store/api/scopeAssessorApi';
import { useGetTreatmentDetailsQuery } from 'store/api/treatmentApi';

const initialSortProperties = [{ id: 'receivedTime', desc: true }];

const backToDestinationDefault = {
    route: AppRoutes.treatments,
    text: 'Member Search',
};

const currentLocation = {
    text: 'Member Treatment Details',
};

const columns = ({ supplierMap }) => [
    {
        accessorKey: 'friendlyId',
        header: 'Referral ID',
        size: 80,
    },
    {
        accessorKey: 'authId',
        header: 'Authorization ID',
        size: 80,
    },
    {
        accessorKey: 'servicingSupplierNpi',
        header: 'Servicing Pharmacy',
        size: 80,
        cell: ({ getValue }) => <SecondaryData data={supplierMap?.[getValue()]?.name} />,
    },
    {
        accessorKey: 'routeName',
        header: 'Route Rule',
        size: 80,
    },
    {
        accessorKey: 'benefitType',
        header: 'Benefit',
        size: 80,
    },
    {
        accessorKey: 'receivedTime',
        header: 'Received Date',
        size: 80,
        cell: ({ getValue }) => (
            <SecondaryData data={formatDate(getValue(), DateFormats.DATE_TIME)} />
        ),
    },
    {
        accessorKey: 'referralStatus',
        header: 'Referral Status',
        size: 80,
        cell: ({ getValue }) => <ReferralStatusIndicator status={getValue()} />,
    },
];

export const TreatmentDetailPage = () => {
    let { treatmentId } = useParams();
    treatmentId = decodeURIComponent(treatmentId);
    const navigate = useNavigate();

    const payerFeatures = usePayerFeatures();
    const { payerId, permissions } = useSelector((state) => state.auth);
    const canSubmitMemberEscalation =
        payerFeatures?.[PayerFeatures.REQUESTS] &&
        permissions.includes(Permissions.WRITE_PAYER_REQUEST_ESCALATION);

    const {
        data: treatmentDetails,
        isError: getTreatmentDetailsError,
        isFetching: treatmentFetching,
    } = useGetTreatmentDetailsQuery({
        payerId,
        treatmentId,
    });

    const { data: memberDetails } = useGetMemberDetailsQuery(
        {
            payerId,
            segmentId: treatmentDetails?.segmentId,
            memberId: treatmentDetails?.memberDetails.memberId,
            firstName: treatmentDetails?.memberDetails.firstName,
            lastName: treatmentDetails?.memberDetails.lastName,
            dateOfBirth: treatmentDetails?.memberDetails.dateOfBirth,
        },
        { skip: !treatmentDetails },
    );

    const { referralList, authList } = treatmentDetails ?? {
        referralList: [],
        authList: [],
    };

    const { data: products } = useGetAllProductServicesQuery();
    const { data: supplierMap } = useGetSupplierMapQuery();

    const { productServiceMap } = products ?? {};

    const { backToDestination, goBack } = useGoBack(backToDestinationDefault);

    useEffect(() => {
        if (getTreatmentDetailsError) {
            goBack();
        }
    }, [goBack, getTreatmentDetailsError]);

    const activeReferralIsPharmacyBenefit =
        treatmentDetails?.activeReferral?.benefitTypeRaw === BenefitTypes.PHARMACY;

    const { data: needsByDateData } = useGetNeedsByDateQuery(
        {
            treatmentId: treatmentDetails?.treatmentId,
        },
        {
            skip: !(treatmentDetails?.isActive && activeReferralIsPharmacyBenefit),
        },
    );

    const [isSubmitRequestDialogOpen, setIsSubmitRequestDialogOpen] = useState(false);

    const menuItems = canSubmitMemberEscalation
        ? [
              {
                  text: `Submit ${RequestTypeDisplayTextMap[RequestType.ESCALATION]?.replace(' Inquiry', '')} Request`,
                  onItemClick: () => setIsSubmitRequestDialogOpen(true),
              },
          ]
        : [];

    return (
        <FormHeaderAndContent
            rowOneContentHeader={
                <>
                    <ActionButtonPageTitle
                        label={backToDestination.text}
                        title={currentLocation.text}
                        onClick={goBack}
                    />
                    {menuItems.length > 0 && (
                        <div style={{ marginLeft: 'auto' }}>
                            <ButtonWithDropdownMenu
                                label="Actions"
                                menuItems={menuItems}
                                intent={ButtonWithDropdownMenu.Intent.PRIMARY}
                            />
                        </div>
                    )}
                    <SubmitRequestDialog
                        isOpen={isSubmitRequestDialogOpen}
                        onClose={() => setIsSubmitRequestDialogOpen(false)}
                        treatmentDetails={treatmentDetails}
                    />
                </>
            }
            rowThreeContentHeader={
                !treatmentFetching && (
                    <>
                        <FormTitleLabelValuePair
                            label={formatNameLastFirst(
                                treatmentDetails?.lastName,
                                treatmentDetails?.firstName,
                            )}
                            value={memberDetails?.memberId}
                        />
                        <HeaderLabelValuePair
                            label="Product"
                            value={productServiceMap?.[treatmentDetails?.productServiceOid]}
                        />
                        <HeaderLabelValuePair
                            label="Status"
                            value={
                                <TreatmentStatusIndicator
                                    status={treatmentDetails?.treatmentStatus}
                                />
                            }
                        />
                        <HeaderLabelValuePair
                            label="Next Fill Needs By Date"
                            value={formatDate(needsByDateData?.needsByDate)}
                        />
                    </>
                )
            }
            mainContent={
                <>
                    <CollapsibleSection
                        title="Treatment Overview"
                        className="sds_treatment__active-section-header"
                        content={
                            <div className="sds_details-page-overview">
                                <div
                                    className="sds_details-page-overview__container"
                                    style={{ flex: '2 0' }}
                                >
                                    <FormSection
                                        sectionTitle="Referrals"
                                        className="sds_treatment__grid"
                                        content={
                                            <Grid
                                                enableSorting
                                                data={referralList}
                                                isLoading={treatmentFetching}
                                                sortProperties={initialSortProperties}
                                                noResults={
                                                    <MessagingBlock
                                                        size={MessagingBlock.Sizes.SMALL}
                                                        icon={IconNames.CLIPBOARD}
                                                        primaryText="No referrals"
                                                    />
                                                }
                                                onRowClick={(row) => {
                                                    navigate(
                                                        AppRoutes.referralDetails(row.referralId),
                                                        {
                                                            state: {
                                                                backToDestination: currentLocation,
                                                            },
                                                        },
                                                    );
                                                }}
                                                rowCssClass={(row) =>
                                                    classNames('sds_treatment__grid__row', {
                                                        'sds_treatment__grid__row--active':
                                                            row.referralId ===
                                                            treatmentDetails?.activeReferral
                                                                ?.referralId,
                                                    })
                                                }
                                                columns={columns({ supplierMap })}
                                            />
                                        }
                                    />
                                    <FormSection
                                        sectionTitle="Authorizations"
                                        content={
                                            <AuthDetails auths={authList} isLoading={!authList} />
                                        }
                                    />
                                </div>
                                <div
                                    className="sds_details-page-overview__container"
                                    style={{ flex: '1 0' }}
                                >
                                    <TreatmentMemberDetails memberDetails={memberDetails} />
                                </div>
                            </div>
                        }
                    />
                    {treatmentDetails?.activeReferral && (
                        <CollapsibleSection
                            title="Active Referral Overview"
                            className={classNames(
                                'padding__top--x-large',
                                'sds_treatment__active-section-header',
                            )}
                            content={
                                <ReferralDetailsOverview
                                    referralId={treatmentDetails?.activeReferral?.referralId}
                                />
                            }
                        />
                    )}
                </>
            }
        />
    );
};
