import { fmhNpiAPI, REFERENCE_API_URL } from 'api/APIBase';

/** Gets supplier details based on supplier NPI */
export async function getSupplierDetails(npi) {
    const url = `${REFERENCE_API_URL}/suppliers/${npi}`;
    return fmhNpiAPI({ url })
        .get()
        .notFound(() => ({}))
        .json();
}

/** Gets the list of diagnoses */
export async function getDiagnoses() {
    const url = `${REFERENCE_API_URL}/diagnoses/`;
    return fmhNpiAPI({ url }).get().json();
}
