import { ClinicalPolicyStatusIndicator } from '@strata/react-status-indicators';
import PropTypes from 'prop-types';

const StatusMessages = {
    [ClinicalPolicyStatusIndicator.Statuses.EXTREVIEW]: 'Pend. Review',
    [ClinicalPolicyStatusIndicator.Statuses.EXTCHANGE]: 'Change Request',
    [ClinicalPolicyStatusIndicator.Statuses.EXTAPPROVE]: 'Approved',
};

export const PayerPolicyStatusIndicator = ({ status }) => (
    <ClinicalPolicyStatusIndicator status={status} overrideStatusMessages={StatusMessages} />
);

PayerPolicyStatusIndicator.StatusMessages = StatusMessages;

PayerPolicyStatusIndicator.propTypes = {
    status: PropTypes.oneOf(Object.values(ClinicalPolicyStatusIndicator.Statuses)).isRequired,
};
