import { fmhNpiAPI, PERFORMANCE_API_URL } from 'api/APIBase';

/** Gets performance entries associated with a referral */
export function getPerformanceByReferralId({ payerId, referralId }) {
    const url = `${PERFORMANCE_API_URL}/referral/${referralId}`;
    return fmhNpiAPI({ url, payerId })
        .get()
        .notFound(() => [])
        .json();
}
