import { Carousel } from '@strata/react-carousel';
import { groupAndSortHomeScreenMessages } from '@strata/react-page-templates';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getCarouselImage } from 'api/announcementAPI';
import {
    useGetHomeScreenMessagesQuery,
    usePostCarouselViewedMutation,
} from 'store/api/announcementApi';

export const FeatureCarousel = () => {
    const dispatch = useDispatch();
    const { data: messages } = useGetHomeScreenMessagesQuery();
    const [postCarouselViewed] = usePostCarouselViewedMutation();

    const { carousels } = groupAndSortHomeScreenMessages(messages);

    // Load images for active carousel
    const activeCarousel = carousels?.[0];
    const [carouselImages, setCarouselImages] = useState();
    useEffect(() => {
        (async () => {
            if (activeCarousel) {
                setCarouselImages(
                    await Promise.all(
                        activeCarousel.images.map((filename) =>
                            getCarouselImage({ messageId: activeCarousel.message_id, filename }),
                        ),
                    ),
                );
            }
        })();
    }, [activeCarousel, dispatch]);

    const onCarouselClose = () => {
        setCarouselImages();
        postCarouselViewed({ messageId: activeCarousel.message_id });
    };

    return (
        <Carousel
            isOpen={!!(activeCarousel && carouselImages)}
            onClose={onCarouselClose}
            slides={activeCarousel?.message?.map((slide) => ({
                ...slide,
                image: (() => {
                    const imgForSlide = carouselImages?.find(
                        (img) => img.name === slide.image?.[0]?.name,
                    );
                    return imgForSlide && URL.createObjectURL(imgForSlide);
                })(),
            }))}
        />
    );
};
