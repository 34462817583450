import { NOTIFICATION_API_URL } from 'api/APIBase';

// exported websocket helpers in separate file to avoid cyclic dependencies
export const SocketType = { NOTIFICATION: 'NOTIFICATION_SOCKET' };

// API constant
export const SocketURLMap = { [SocketType.NOTIFICATION]: NOTIFICATION_API_URL };

export const getSocketURL = (socketType) =>
    `${process.env.REACT_APP_BASE_URL.replace('http', 'ws')}${SocketURLMap[socketType]}`;

export const wsConnect = ({ socketType }) => ({ type: 'WS_CONNECT', socketType });
export const wsEnsureConnected = ({ socketType }) => ({ type: 'WS_ENSURE_CONNECTED', socketType });
export const wsDisconnect = (socketType, host) => ({ type: 'WS_DISCONNECT', socketType, host });
export const wsSend = ({ socketType, messageType, message }) => ({
    type: 'WS_SEND',
    socketType,
    messageType,
    message,
});
