import { DateFormats, formatDate } from '@strata/helper-functions';
import { DateInputFormik } from '@strata/react-date-time';
import { DialogWithForm } from '@strata/react-dialog';
import { TextAreaFormik } from '@strata/react-inputs';
import { ClinicalPolicyStatusIndicator } from '@strata/react-status-indicators';
import { validationStrings } from '@strata/strata-ui-strings';
import dayjs from 'dayjs';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { putApproveDraft, putUpdateDraftStatus } from 'api/clinicalPolicyAPI';
import { handleError } from 'services/Error';
import { fetchPolicies } from 'store/policies/policiesSlice';

export const ApprovePolicyDialog = ({ isOpen, onSecondaryButtonClick, policyData = undefined }) => {
    const dispatch = useDispatch();
    const { payerId } = useSelector((state) => state.auth);
    return (
        <Formik
            initialValues={{ effectiveDate: policyData?.effectiveDate, comment: '' }}
            enableReinitialize
            validationSchema={Yup.object({
                effectiveDate: DateInputFormik.validator()
                    .required(validationStrings.requiredField.errorMessage)
                    .min(dayjs().add(1, 'day').startOf('day').toDate(), 'Must be future date'),
                comment: Yup.string(),
            })}
            onSubmit={async (values, { resetForm }) => {
                try {
                    await putApproveDraft({
                        payerId,
                        draftId: policyData.draftId,
                        effectiveDate: formatDate(values.effectiveDate, DateFormats.HYPHEN_DATE),
                        comment: values.comment,
                    });
                    await putUpdateDraftStatus({
                        payerId,
                        draftId: policyData?.draftId,
                        policyStatus: ClinicalPolicyStatusIndicator.Statuses.PEND_PROD,
                        comment: values.comment,
                    });
                    await dispatch(fetchPolicies({ payerId }));
                    resetForm();
                    onSecondaryButtonClick();
                } catch (err) {
                    handleError('An error occurred approving the policy.', err);
                }
            }}
        >
            {(formik) => (
                <DialogWithForm
                    dialogTitle="Approve Policy"
                    isOpen={isOpen}
                    primaryButtonLabel="Approve"
                    isPrimaryButtonDisabled={!formik.isValid || formik.isSubmitting}
                    secondaryButtonLabel="Cancel"
                    onSecondaryButtonClick={(e) => {
                        formik.handleReset(e);
                        onSecondaryButtonClick();
                    }}
                    dialogContent={
                        <>
                            <p className="margin__bottom--large">
                                Approving this policy will make it live in production on the
                                specified effective date below.
                            </p>
                            <DateInputFormik
                                id="effectiveDate"
                                label="Policy Effective Date"
                                required
                                verticalSpacingSmall
                                minDate={dayjs().add(1, 'day').startOf('day').toDate()}
                            />
                            <TextAreaFormik id="comment" label="Comment" />
                        </>
                    }
                />
            )}
        </Formik>
    );
};

ApprovePolicyDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSecondaryButtonClick: PropTypes.func.isRequired,
    policyData: PropTypes.shape({
        draftId: PropTypes.string.isRequired,
        effectiveDate: PropTypes.string.isRequired,
    }),
};
