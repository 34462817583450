import { ReferralStatusIndicator } from '@strata/react-status-indicators';

const pendingAssignmentMessage = 'Currently Pending Assignment';

export const ReferralStatusMessageMap = {
    [ReferralStatusIndicator.Statuses.MARKET]: 'Currently a Live Referral',
    [ReferralStatusIndicator.Statuses.PENDINTAKE]: 'Currently Pending Intake',
    [ReferralStatusIndicator.Statuses.FMH]: pendingAssignmentMessage,
    [ReferralStatusIndicator.Statuses.RESEARCH]: pendingAssignmentMessage,
};
