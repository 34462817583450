import { InformationIcon } from '@strata/react-status-icons';
import PropTypes from 'prop-types';

export const GridHeaderWithInfo = ({ header, tooltipText }) => (
    <div className="sds_grid-header-with-info">
        <span className="sds_grid-header-with-info__text">{header}</span>
        <InformationIcon tooltipText={tooltipText} />
    </div>
);

GridHeaderWithInfo.propTypes = {
    header: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
};
