import { DateFormats, formatDate } from '@strata/helper-functions';
import { FormSectionWithMessage, FormSectionWithRows } from '@strata/react-form-section';
import { DetailLabelValuePair } from '@strata/react-label-value-pairs';
import { ServicingRate } from '@strata/react-servicing-rate';
import { FIcon } from '@strata/react-status-icons';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Permissions } from 'services/constants';
import { ReferralStatusMessageMap } from 'services/referralHelpers';

export const LiveReferralDetails = ({ referralDetails = undefined }) => {
    const referralStatusMessage = ReferralStatusMessageMap[referralDetails?.referralStatus];

    const { permissions } = useSelector((state) => state.auth);

    return referralStatusMessage ? (
        <FormSectionWithMessage
            sectionTitle="Live Referral Details"
            icon={<FIcon />}
            primaryText={referralStatusMessage}
            secondaryText="No details to show."
        />
    ) : (
        <FormSectionWithRows
            sectionTitle="Live Referral Details"
            isLoading={!referralDetails}
            rowOneContent={
                <>
                    <DetailLabelValuePair
                        style={{ width: '33%' }}
                        label="Ended At"
                        value={formatDate(referralDetails?.auctionEnd, DateFormats.DATE)}
                    />
                    {permissions.includes(Permissions.READ_REFERRAL_RATES) && (
                        <DetailLabelValuePair
                            label="Referral Rate"
                            value={
                                <ServicingRate
                                    servicingRate={referralDetails?.winningBidRate}
                                    referentPriceType={referralDetails?.referentPriceType}
                                />
                            }
                        />
                    )}
                </>
            }
        />
    );
};

LiveReferralDetails.propTypes = {
    referralDetails: PropTypes.shape({
        auctionEnd: PropTypes.string,
        referralStatus: PropTypes.string,
        winningBidRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        referentPriceType: PropTypes.string,
    }),
};
