import { formatDate } from '@strata/helper-functions';
import { InfoDialog } from '@strata/react-dialog';
import { FileList } from '@strata/react-file-upload';
import { DetailLabel, DetailLabelValuePair } from '@strata/react-label-value-pairs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { downloadPolicyFile, usePolicyFileList } from 'services/Files';

export const ViewRequestedChangesDialog = ({ isOpen, onClose, policyDetails = undefined }) => {
    const { payerId } = useSelector((state) => state.auth);
    const files = usePolicyFileList({ payerId, draftId: policyDetails?.draftId, isOpen });
    return (
        <InfoDialog
            dialogTitle="View Requested Changes"
            isOpen={isOpen}
            onClose={onClose}
            width={800}
            dialogContent={policyDetails?.changeRequests.map((request) => (
                <div
                    key={request.request_date}
                    className="sds_policy-changes-dialog__section margin__bottom--large"
                >
                    <div className="flex__gap--xx-large padding__bottom--large">
                        <DetailLabelValuePair
                            label="Change Request Date"
                            value={formatDate(request.request_date)}
                        />
                        <DetailLabelValuePair label="Requested By" value={request.username} />
                    </div>
                    <DetailLabelValuePair
                        className="padding__bottom--medium"
                        label="Change Description"
                        value={request.change?.split('\n').map((text, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <div key={index} className="padding__bottom--small">
                                {text}
                            </div>
                        ))}
                    />
                    {request.attachments?.length > 0 && (
                        <div className="padding__bottom--small" style={{ width: '50%' }}>
                            <DetailLabel label="Clinical Documents" />
                            <FileList
                                files={request.attachments.map((name) => ({
                                    name,
                                    size: files?.find((file) => file.name === name)?.size,
                                }))}
                                readOnly
                                onFileClick={(filename) =>
                                    downloadPolicyFile({
                                        payerId,
                                        draftId: policyDetails?.draftId,
                                        filename,
                                    })
                                }
                            />
                        </div>
                    )}
                </div>
            ))}
        />
    );
};

ViewRequestedChangesDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    policyDetails: PropTypes.shape({
        draftId: PropTypes.string.isRequired,
        changeRequests: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }),
};
