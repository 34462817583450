import { fmhAPI, TREATMENT_API_URL } from 'api/APIBase';

export async function getReferralDetails({ payerId, referralId }) {
    const url = `${TREATMENT_API_URL}/payers/${payerId}/referrals/${referralId}`;
    return fmhAPI.base
        .url(url)
        .get()
        .notFound(() => ({}))
        .json();
}
