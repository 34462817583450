import { createSlice } from '@reduxjs/toolkit';

export const viewsSlice = createSlice({
    name: 'views',
    initialState: {
        segmentId: undefined,
    },
    reducers: {
        setSegmentId(state, action) {
            const { segmentId } = action.payload;
            return { ...state, segmentId };
        },
    },
});

export const { setSegmentId } = viewsSlice.actions;
