import { capitalizeString, sortDateDesc } from '@strata/helper-functions';
import { TreatmentStatusIndicator } from '@strata/react-status-indicators';

import { FMH_NPI, FMH_UNMANAGED_NPI } from 'services/constants';

/**
 * Object containing the summary of a treatment referral.
 * @typedef {Object} TreatmentReferralSummary
 * @property {string} referralId - The referral ID.
 * @property {string} friendlyId - The friendly ID.
 * @property {string} authId - The auth ID.
 * @property {string} servicingSupplierNpi - The servicing supplier NPI.
 * @property {string} routeName - The route name.
 * @property {string} benefitType - The benefit type.
 * @property {string} benefitTypeRaw - The unformatted benefit type.
 * @property {string} receivedTime - The received time.
 * @property {string} referralStatus - The referral status.
 */

/**
 * Maps treatment referral summary from API.
 * @param {*} referral - The referral object from the API.
 * @returns {TreatmentReferralSummary} The treatment referral summary object.
 */
const mapTreatmentReferralSummary = (referral) => ({
    referralId: referral.referral_id,
    friendlyId: referral.friendly_id,
    authId: referral.auth_id,
    servicingSupplierNpi: [FMH_NPI, FMH_UNMANAGED_NPI].includes(
        referral.servicing_parent_pharmacy_npi,
    )
        ? undefined
        : referral.servicing_parent_pharmacy_npi,
    routeName: referral.route_name,
    benefitType: capitalizeString(referral.benefit_type),
    benefitTypeRaw: referral.benefit_type,
    receivedTime: referral.created_at,
    referralStatus: referral.referral_status,
});

/**
 * Object containing the summary of a treatment authorization.
 * @typedef {Object} TreatmentAuthSummary
 * @property {string} caseId - The case ID.
 * @property {string} authStart - The authorization start date.
 * @property {string} authExpiration - The authorization expiration date.
 * @property {string} billingProvider - The billing provider.
 * @property {string} servicingProvider - The servicing provider.
 * @property {string} requestingProvider - The requesting provider.
 * @property {string} servicingFacility - The servicing facility.
 */

/**
 * Maps treatment auth summary from API.
 * @param {*} auth - The auth object from the API.
 * @returns {TreatmentAuthSummary} The treatment auth summary object.
 */
const mapTreatmentAuthSummary = (auth) => ({
    caseId: auth.auth_id,
    authStart: auth.auth_start,
    authExpiration: auth.auth_end,
    billingProvider: auth.billing_provider,
    servicingProvider: auth.servicing_provider,
    requestingProvider: auth.requesting_provider,
    servicingFacility: auth.servicing_facility,
});

/**
 * Object containing the summary of a treatment member.
 * @typedef {Object} TreatmentMemberSummary
 * @property {string} memberId - The member ID.
 * @property {string} firstName - The first name of the member.
 * @property {string} lastName - The last name of the member.
 * @property {string} dateOfBirth - The date of birth of the member.
 */

/**
 * Object containing the summary of a treatment.
 * @typedef {Object} TreatmentSummary
 * @property {string} payerId - The payer ID.
 * @property {string} treatmentId - The treatment ID.
 * @property {string} segmentId - The segment ID.
 * @property {boolean} isBrand - Whether the product is brand or generic.
 * @property {string} benefitType - The benefit type.
 * @property {string} placeOfService - The place of service.
 * @property {string} productServiceOid - The product service OID.
 * @property {string} treatmentStatus - The treatment status.
 * @property {boolean} isActive - Whether the treatment is active.
 * @property {string} treatmentStart - The treatment start date.
 * @property {string} lastReferralStart - The last referral start date.
 * @property {string} firstName - The first name of the patient.
 * @property {string} lastName - The last name of the patient.
 * @property {TreatmentReferralSummary} activeReferral - The active referral summary.
 * @property {Array<TreatmentReferralSummary>} referralList - The list of referral summaries.
 * @property {Array<TreatmentAuthSummary>} authList - The list of auth summaries.
 * @property {TreatmentMemberSummary} memberDetails - The member details summary.
 */

/**
 * Maps treatment details from API.
 *
 * @returns {TreatmentSummary} The simplified treatment details object.
 */
export const mapTreatmentDetails = (details) => {
    const referralList = details.referrals.map(mapTreatmentReferralSummary);
    const activeReferral = details.active_referral
        ? {
              referralId: details.active_referral.referral_id,
              authId: details.active_referral.auths?.sort((a, b) =>
                  sortDateDesc(a.auth_expiration, b.auth_expiration),
              )[0]?.auth_id,
              benefitType: capitalizeString(details.active_referral.benefit_type),
              benefitTypeRaw: details.active_referral.benefit_type,
          }
        : undefined;

    return {
        payerId: details.payer_id,
        treatmentId: details.treatment_id,
        segmentId: details.segment_id,
        isBrand: !!details.is_brand,
        benefitType: details.benefit_type,
        placeOfService: details.place_of_service,
        productServiceOid: details.product_service_oid,
        treatmentStatus: details.treatment_status,
        isActive: details.treatment_status === TreatmentStatusIndicator.Statuses.ACTIVE,
        treatmentStart: details.treatment_start,
        lastReferralStart: details.last_referral_start,
        firstName: details.member.first_name,
        lastName: details.member.last_name,
        activeReferral,
        referralList,
        authList: details.auths.map(mapTreatmentAuthSummary),
        memberDetails: {
            memberId: details.member.member_id,
            firstName: details.member.first_name,
            lastName: details.member.last_name,
            dateOfBirth: details.member.dob,
        },
    };
};
