import { formatDate, formatGender, formatPhoneNumber, getAge } from '@strata/helper-functions';
import { FormSectionWithRows } from '@strata/react-form-section';
import { MailToHyperlink } from '@strata/react-hyperlink';
import { AddressDetailLabelValue, DetailLabelValuePair } from '@strata/react-label-value-pairs';
import PropTypes from 'prop-types';

export const TreatmentMemberDetails = ({ memberDetails = undefined }) => (
    <FormSectionWithRows
        sectionTitle="Member Details"
        isLoading={!memberDetails}
        hoverOverlayText="Member details are hidden. Hover to view information."
        rowOneContent={
            <>
                <DetailLabelValuePair
                    style={{ width: '50%' }}
                    label="Gender"
                    value={formatGender(memberDetails?.gender)}
                />
                <DetailLabelValuePair
                    style={{ width: '50%' }}
                    label="DOB"
                    value={formatDate(memberDetails?.dateOfBirth)}
                />
            </>
        }
        rowTwoContent={
            <>
                <DetailLabelValuePair
                    style={{ width: '50%' }}
                    label="Age"
                    value={getAge(memberDetails?.dateOfBirth)}
                />
                <AddressDetailLabelValue
                    style={{ width: '50%' }}
                    address1={memberDetails?.addressLine1}
                    address2={memberDetails?.addressLine2}
                    city={memberDetails?.city}
                    state={memberDetails?.state}
                    zip={memberDetails?.zip}
                />
            </>
        }
        rowThreeContent={
            <>
                <DetailLabelValuePair
                    style={{ width: '50%' }}
                    label="Phone"
                    value={formatPhoneNumber(memberDetails?.phone)}
                />
                <DetailLabelValuePair
                    style={{ width: '50%' }}
                    label="Email"
                    value={
                        memberDetails?.email ? (
                            <MailToHyperlink emailAddress={memberDetails?.email} />
                        ) : undefined
                    }
                />
            </>
        }
        rowFourContent={
            <DetailLabelValuePair
                style={{ width: '50%' }}
                label="Group ID"
                value={memberDetails?.benefitGroup}
            />
        }
    />
);

TreatmentMemberDetails.propTypes = {
    memberDetails: PropTypes.shape({
        memberId: PropTypes.string,
        gender: PropTypes.string,
        dateOfBirth: PropTypes.string,
        addressLine1: PropTypes.string,
        addressLine2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        benefitGroup: PropTypes.string,
        phone: PropTypes.string,
        email: PropTypes.string,
    }),
};
