/* eslint-disable max-lines */
import { IconNames } from '@blueprintjs/icons';
import { DateFormats, formatDate } from '@strata/helper-functions';
import { ChangeRequestSubType, RequestType, RequestTypeDisplayTextMap } from '@strata/react-chat';
import { CheckboxListFormik } from '@strata/react-checkbox-list';
import { DialogWithForm } from '@strata/react-dialog';
import { FileUploadFormik } from '@strata/react-file-upload';
import { Hyperlink } from '@strata/react-hyperlink';
import { TextAreaFormik } from '@strata/react-inputs';
import { SuggestSingleSelectFormik } from '@strata/react-selects';
import { Icon } from '@strata/react-status-icons';
import { SuccessToast } from '@strata/react-toast-message';
import { validationStrings } from '@strata/strata-ui-strings';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { MemberEscalation } from 'components/requests/dialogs/MemberEscalation';
import { ReadPermissionRequestTypeMap } from 'services/payerRequests';
import { AppRoutes } from 'services/routes';
import { usePostNewRequestMutation } from 'store/api/payerRequestApi';
import { useGetSegmentsQuery } from 'store/api/referenceApi';

const hideAdditionalInfoSection = ({ requestType, requestSubType }) =>
    !requestType || (requestType === RequestType.CHANGE && !requestSubType);

const isProductChangeRequest = ({ requestType, requestSubType }) =>
    requestType === RequestType.CHANGE && requestSubType === ChangeRequestSubType.PRODUCT;

const ChangeRequestSubTypeDisplayTextMap = {
    [ChangeRequestSubType.PHARMACY]: 'pharmacy',
    [ChangeRequestSubType.PRODUCT]: 'product',
};

const useDefaultSegmentOption = (segmentOptions) => {
    const { segmentId } = useSelector((state) => state.views);

    // If segment is selected in views, use that segment
    if (segmentId) {
        return { segmentId };
    }

    // If there is only one segment option, select it
    if (segmentOptions?.length === 1) {
        return { segmentId: segmentOptions[0].value };
    }

    // Otherwise, force user to select segment
    return undefined;
};

export const SubmitRequestDialog = ({ isOpen, onClose, treatmentDetails = undefined }) => {
    const navigate = useNavigate();
    const [postNewRequest] = usePostNewRequestMutation();

    const { payerId, permissions, segmentIds, restrictedAccess } = useSelector(
        (state) => state.auth,
    );
    const { data: segments, isFetching: isFetchingSegments } = useGetSegmentsQuery({ payerId });

    const segmentOptions = (segments ?? [])
        .filter(({ id }) => segmentIds.includes(id))
        .map((segment) => ({ name: segment.name, value: segment.id }));

    const defaultSegmentOption = useDefaultSegmentOption(segmentOptions);

    const requestTypeOptions = Object.entries(ReadPermissionRequestTypeMap)
        .filter(([permission]) => permissions.includes(permission))
        .map(([_, value]) => ({
            value,
            name: RequestTypeDisplayTextMap[value],
        }));

    /** True if opened from the treatment details screen */
    const openedFromTreatment = !!treatmentDetails;

    return (
        <Formik
            initialValues={
                openedFromTreatment
                    ? {
                          segmentId: treatmentDetails.segmentId,
                          requestType: RequestType.ESCALATION,
                          ...MemberEscalation.initialValues(treatmentDetails),
                      }
                    : { ...defaultSegmentOption }
            }
            enableReinitialize
            validateOnMount
            validationSchema={Yup.object({
                segmentId: Yup.string().required(validationStrings.requiredField.errorMessage),
                requestType: Yup.string().required(validationStrings.requiredField.errorMessage),
                ...MemberEscalation.validationSchema({ restrictedAccess }),
                requestSubType: Yup.string().when('requestType', {
                    is: (requestType) =>
                        [RequestType.ESCALATION, RequestType.CHANGE].includes(requestType),
                    then: (schema) => schema.required(validationStrings.requiredField.errorMessage),
                }),
                additionalNotes: Yup.string().when(['requestType', 'requestSubType'], {
                    is: (requestType, requestSubType) =>
                        requestType === RequestType.OTHER ||
                        (requestType === RequestType.CHANGE &&
                            requestSubType !== ChangeRequestSubType.PRODUCT),
                    then: (schema) => schema.required(validationStrings.requiredField.errorMessage),
                }),
                requiredFile: Yup.array().when(['requestType', 'requestSubType'], {
                    is: (requestType, requestSubType) =>
                        isProductChangeRequest({ requestType, requestSubType }),
                    then: (schema) =>
                        schema
                            .required(validationStrings.requiredField.errorMessage)
                            .length(1, validationStrings.requiredField.errorMessage),
                }),
                additionalFiles: Yup.array(),
                urgent: Yup.bool(),
            })}
            onSubmit={async (values, { resetForm }) => {
                const newRequestId = await postNewRequest({
                    payerId,
                    segmentId: values.segmentId,
                    requestType: values.requestType,
                    requestSubType: values.requestSubType,
                    memberId: values.memberId,
                    firstName: values.firstName,
                    lastName: values.lastName,
                    dateOfBirth: values.dob
                        ? formatDate(values.dob, DateFormats.HYPHEN_DATE)
                        : undefined,
                    treatmentIds: values.selectedTreatments ?? [],
                    additionalNotes: values.additionalNotes,
                    files: [
                        ...(values.requiredFileUpload ?? []),
                        ...(values.additionalFilesUpload ?? []),
                    ],
                    urgent: values.urgent ?? false,
                }).unwrap();
                resetForm();
                onClose();
                SuccessToast({ message: 'Request created successfully.' });
                navigate(AppRoutes.requestDetails(newRequestId));
            }}
        >
            {(formik) => (
                <DialogWithForm
                    dialogTitle="Submit Request"
                    isOpen={isOpen}
                    secondaryButtonLabel="Cancel"
                    onSecondaryButtonClick={() => {
                        onClose();
                        formik.resetForm();
                    }}
                    primaryButtonLabel="Submit Request"
                    isPrimaryButtonDisabled={!formik.isValid || formik.isSubmitting}
                    dialogContent={
                        <>
                            {!openedFromTreatment && (
                                <>
                                    {segmentIds.length !== 1 && (
                                        <SuggestSingleSelectFormik
                                            id="segmentId"
                                            label="Business Segment"
                                            verticalSpacingSmall
                                            required
                                            disableAutoPopulate
                                            isLoading={isFetchingSegments}
                                            values={segmentOptions}
                                            selectedItem={segmentOptions.find(
                                                ({ value }) => value === formik.values.segmentId,
                                            )}
                                            onChange={(newVal) =>
                                                formik.setValues({ segmentId: newVal?.value })
                                            }
                                        />
                                    )}
                                    <SuggestSingleSelectFormik
                                        id="requestType"
                                        label="What Can We Help You With?"
                                        verticalSpacingSmall
                                        required
                                        values={requestTypeOptions}
                                        selectedItem={requestTypeOptions.find(
                                            ({ value }) => value === formik.values.requestType,
                                        )}
                                        onChange={(newVal) =>
                                            formik.setValues({
                                                segmentId: formik.values.segmentId,
                                                requestType: newVal?.value,
                                            })
                                        }
                                    />
                                </>
                            )}
                            <MemberEscalation openedFromTreatment={openedFromTreatment} />
                            {formik.values.requestType === RequestType.CHANGE && (
                                <>
                                    <SuggestSingleSelectFormik
                                        id="requestSubType"
                                        label="Please Select One Of The Following"
                                        values={Object.values(ChangeRequestSubType)}
                                        required
                                        verticalSpacingSmall
                                        onChange={(newVal) =>
                                            formik.setValues({
                                                segmentId: formik.values.segmentId,
                                                requestType: formik.values.requestType,
                                                requestSubType: newVal,
                                            })
                                        }
                                    />
                                    {(() => {
                                        if (!formik.values.requestSubType) {
                                            return null;
                                        }
                                        return isProductChangeRequest(formik.values) ? (
                                            <>
                                                <div className="sds_label font-semi-bold--uppercase margin__bottom--medium">
                                                    {`${
                                                        ChangeRequestSubTypeDisplayTextMap[
                                                            formik.values.requestSubType
                                                        ]
                                                    } Information`}
                                                </div>
                                                <FileUploadFormik
                                                    id="requiredFile"
                                                    label="Upload Product File"
                                                    required
                                                    verticalSpacingSmall
                                                    accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/csv"
                                                    maxAllowedFiles={1}
                                                    onAddFiles={(files) =>
                                                        formik.setFieldValue(
                                                            'requiredFileUpload',
                                                            files,
                                                        )
                                                    }
                                                    onRemoveFile={() =>
                                                        formik.setFieldValue(
                                                            'requiredFileUpload',
                                                            undefined,
                                                        )
                                                    }
                                                    helperText={
                                                        <div
                                                            className="flex__gap--small"
                                                            style={{ flexDirection: 'column' }}
                                                        >
                                                            <div>
                                                                Please use the template file below
                                                                to upload product information.
                                                            </div>
                                                            <Hyperlink
                                                                navUrl="https://sseasacdn.blob.core.windows.net/platform-assets/Product-Management-Template.xlsx"
                                                                linkText={
                                                                    <div className="flex__gap--small">
                                                                        <span>
                                                                            Product-Management-Template.xlsx
                                                                        </span>
                                                                        <Icon
                                                                            icon={
                                                                                IconNames.DOWNLOAD
                                                                            }
                                                                        />
                                                                    </div>
                                                                }
                                                            />
                                                        </div>
                                                    }
                                                />
                                            </>
                                        ) : (
                                            <TextAreaFormik
                                                id="additionalNotes"
                                                label="Please Describe The Change Required"
                                                required
                                                verticalSpacingSmall
                                            />
                                        );
                                    })()}
                                </>
                            )}
                            {formik.values.requestType === RequestType.OTHER && (
                                <TextAreaFormik
                                    id="additionalNotes"
                                    label="Please Describe The Request"
                                    required
                                    verticalSpacingSmall
                                />
                            )}
                            {!hideAdditionalInfoSection(formik.values) && (
                                <>
                                    <div className="sds_label font-semi-bold--uppercase margin__bottom--medium">
                                        Additional Information
                                    </div>
                                    {(formik.values.requestType === RequestType.ESCALATION ||
                                        isProductChangeRequest(formik.values)) && (
                                        <TextAreaFormik
                                            id="additionalNotes"
                                            label="Anything Else You Would Like Us To Know?"
                                            verticalSpacingSmall
                                        />
                                    )}
                                    <FileUploadFormik
                                        id="additionalFiles"
                                        label="Attach File(s)"
                                        verticalSpacingSmall
                                        accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/csv,application/pdf"
                                        helperText="Accepted file types: xlsx, docx, csv, and pdf"
                                        onAddFiles={(files) =>
                                            formik.setFieldValue('additionalFilesUpload', [
                                                ...(formik.values.additionalFilesUpload ?? []),
                                                ...files,
                                            ])
                                        }
                                        onRemoveFile={(filename) =>
                                            formik.setFieldValue(
                                                'additionalFilesUpload',
                                                formik.values.additionalFilesUpload?.filter(
                                                    (file) => file.name !== filename,
                                                ),
                                            )
                                        }
                                    />
                                    <CheckboxListFormik
                                        name="urgent"
                                        label="Request Urgency"
                                        options={['Urgent']}
                                        values={formik.values.urgent ? ['Urgent'] : []}
                                        onChange={(newValue) =>
                                            formik.setFieldValue(
                                                'urgent',
                                                newValue.includes('Urgent'),
                                            )
                                        }
                                    />
                                </>
                            )}
                        </>
                    }
                />
            )}
        </Formik>
    );
};

SubmitRequestDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    treatmentDetails: PropTypes.shape({
        treatmentId: PropTypes.string.isRequired,
        segmentId: PropTypes.string.isRequired,
        productServiceOid: PropTypes.string.isRequired,
    }),
};
