import { createSlice } from '@reduxjs/toolkit';
import { formatNameLastFirst } from '@strata/helper-functions';
import { ReferralTimeline } from '@strata/react-timeline';

import { getReferralMilestones } from 'api/clientAPI';
import { getPerformanceByReferralId } from 'api/performanceAPI';
import { getReferralDetails } from 'api/treatmentAPI';
import { handleError } from 'services/Error';
import { fetchSupplierDetails } from 'store/reference/referenceSlice';

export const referralDetailsSlice = createSlice({
    name: 'referralDetails',
    initialState: {
        referralDetailsData: {},
        referralMilestones: {},
        performanceEntries: {},
    },
    reducers: {
        setReferralDetails(state, action) {
            const { referralId, referral } = action.payload;
            return {
                ...state,
                referralDetailsData: {
                    ...state.referralDetailsData,
                    [referralId]: referral,
                },
            };
        },
        setReferralMilestonesForReferral(state, action) {
            const { referralId, referralMilestones } = action.payload;
            return {
                ...state,
                referralMilestones: {
                    ...state.referralMilestones,
                    [referralId]: referralMilestones,
                },
            };
        },
        setPerformanceEntriesForReferral(state, action) {
            const { referralId, performanceEntries } = action.payload;
            return {
                ...state,
                performanceEntries: {
                    ...state.performanceEntries,
                    [referralId]: performanceEntries,
                },
            };
        },
    },
});

export const {
    setReferralDetails,
    setReferralMilestonesForReferral,
    setPerformanceEntriesForReferral,
} = referralDetailsSlice.actions;

export const fetchReferralDetails =
    ({ payerId, referralId }) =>
    async (dispatch) => {
        try {
            // Fetch all pieces of data needed for referrals
            const response = await getReferralDetails({ payerId, referralId });
            const referral = {
                referralId: response.referral_id,
                treatmentId: response.treatment_id,
                segmentId: response.segment_id,
                productServiceOid: response.product_service_oid,
                diagnosisOid: response.diagnosis_oid,
                memberId: response.member?.member_id,
                memberDOB: response.member?.dob,
                memberFirstName: response.member?.first_name,
                memberLastName: response.member?.last_name,
                memberName: formatNameLastFirst(
                    response.member?.last_name,
                    response.member?.first_name,
                ),
                auctionEnd: response.referral_period?.start,
                winningBidRate: response.servicing_info?.servicing_rate?.rate,
                referentPriceType: response.servicing_info?.servicing_rate?.referent_price_type,
                receivedTime: response.created_at,
                referralExpiration: response.referral_period?.expiration,
                referralStatus: response.referral_status?.trim(),
                payerId: response.payer_id,
                placeOfService: response.place_of_service?.trim(),
                prescriberNpi: response.prescription_info?.prescriber_npi,
                practiceNpi: response.prescription_info?.practice_npi,
                supplierNpi: response.servicing_info?.servicing_parent_pharmacy_npi,
                clinicalInfo: JSON.parse(response.clinical_info ?? '{}'), // TODO update feature
                friendlyId: response.friendly_id,
                benefit: response.benefit_type?.trim(),
            };

            dispatch(setReferralDetails({ referralId, referral }));
        } catch (err) {
            handleError('An error occurred retrieving referral details.', err);
            dispatch(setReferralDetails({ referralId }));
        }
    };

export const fetchReferralMilestones =
    ({ payerId, referralId }) =>
    async (dispatch, getState) => {
        try {
            const response = await getReferralMilestones({ payerId, referralId });
            const referralMilestones = await Promise.all(
                response.map(async (milestone) => {
                    if (
                        milestone.milestone_type === ReferralTimeline.MilestoneTypes.AT_PHARMACY_WON
                    ) {
                        return {
                            ...milestone,
                            additional_data: {
                                ...milestone.additional_data,
                                // get supplier name for AT PHARMACY milestone
                                supplier_name: await (async () => {
                                    const supplierNpi = milestone.additional_data?.supplier_npi;
                                    let supplierName =
                                        getState().reference.supplierDetails[supplierNpi]?.name;
                                    if (!supplierName && supplierNpi) {
                                        await dispatch(fetchSupplierDetails(supplierNpi));
                                        supplierName =
                                            getState().reference.supplierDetails[supplierNpi]?.name;
                                    }
                                    return supplierName;
                                })(),
                                winning_bid_amt: undefined,
                            },
                        };
                    }
                    if (
                        milestone.milestone_type ===
                        ReferralTimeline.MilestoneTypes.REFERRAL_STATUS_CHANGE
                    ) {
                        return {
                            ...milestone,
                            additional_data: undefined,
                        };
                    }
                    return milestone;
                }),
            );
            dispatch(setReferralMilestonesForReferral({ referralId, referralMilestones }));
        } catch (err) {
            handleError('An error occurred retrieving referral milestones information.', err);
        }
    };

export const fetchPerformanceEntries =
    ({ payerId, referralId }) =>
    async (dispatch) => {
        try {
            const performanceEntries = await getPerformanceByReferralId({ payerId, referralId });
            dispatch(setPerformanceEntriesForReferral({ referralId, performanceEntries }));
        } catch (err) {
            handleError('An error occurred retrieving performance information.', err);
        }
    };
