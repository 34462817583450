import { HomeScreen } from '@strata/react-page-templates';
import { useSelector } from 'react-redux';

import { Permissions } from 'services/constants';
import { PayerFeatures, usePayerFeatures } from 'services/FeatureFlags';
import { AppRoutes } from 'services/routes';
import { useGetHomeScreenMessagesQuery } from 'store/api/announcementApi';

export const Home = () => {
    const { data: messages } = useGetHomeScreenMessagesQuery();

    const payerFeatures = usePayerFeatures();
    const { permissions } = useSelector((state) => state.auth);

    const getHomeScreenButton = () => {
        if (
            payerFeatures?.[PayerFeatures.APREFERRAL] &&
            permissions.includes(Permissions.READ_SUMMARY)
        ) {
            return {
                label: 'View Products',
                path: AppRoutes.products,
            };
        }

        if (
            payerFeatures?.[PayerFeatures.APREFERRAL] &&
            permissions.includes(Permissions.READ_DETAIL)
        ) {
            return {
                label: 'Go to Member Search',
                path: AppRoutes.treatments,
            };
        }

        if (
            payerFeatures?.[PayerFeatures.POLICY_MAN] &&
            permissions.includes(Permissions.READ_CLINICAL_POLICY)
        ) {
            return {
                label: 'View Clinical Policies',
                path: AppRoutes.clinicalPolicies,
            };
        }
        return undefined;
    };

    return (
        <HomeScreen
            illustration={HomeScreen.Illustration.PAYERS}
            button={getHomeScreenButton()}
            welcomeMessagePartOne="FMH for Payers provides our partners with unprecedented visibility into their specialty drug referrals and specialty program performance."
            messages={messages}
            viewAllUpdatesUrl={AppRoutes.updatesHistory}
        />
    );
};
