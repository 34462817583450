import { createSlice } from '@reduxjs/toolkit';
import { ClinicalPolicyStatusIndicator } from '@strata/react-status-indicators';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getAllPolicies, getPolicyDraftDetails } from 'api/clinicalPolicyAPI';
import { handleError } from 'services/Error';
import { fetchDiagnoses } from 'store/reference/referenceSlice';

export const policiesSlice = createSlice({
    name: 'policies',
    initialState: {
        policyListData: undefined,
        policyDetails: {},
    },
    reducers: {
        setPolicyListData(state, action) {
            const { policyListData } = action.payload;
            return {
                ...state,
                policyListData,
            };
        },
        setPolicyDetails(state, action) {
            const { draftId, draft } = action.payload;
            return {
                ...state,
                policyDetails: {
                    ...state.policyDetails,
                    [draftId]: draft,
                },
            };
        },
    },
});

export const { setPolicyListData, setPolicyDetails } = policiesSlice.actions;

export const fetchPolicies =
    ({ payerId }) =>
    async (dispatch, getState) => {
        try {
            const response = await getAllPolicies({ payerId });

            if (!getState().reference.diagnosisList) {
                await dispatch(fetchDiagnoses());
            }

            const policyListData = response
                .filter((policy) =>
                    [
                        ClinicalPolicyStatusIndicator.Statuses.IN_PROD,
                        ClinicalPolicyStatusIndicator.Statuses.PEND_PROD,
                        ClinicalPolicyStatusIndicator.Statuses.EXTAPPROVE,
                        ClinicalPolicyStatusIndicator.Statuses.EXTCHANGE,
                        ClinicalPolicyStatusIndicator.Statuses.EXTREVIEW,
                    ].includes(policy.policy_status),
                )
                .map((policy) => ({
                    draftId: policy.draft_id,
                    policyId: policy.policy_id,
                    productOid: policy.product_oid,
                    productName: policy.product_name,
                    diagnoses: policy.diagnosis_oids
                        ?.map((oid) => getState().reference.diagnosisList?.[oid]?.description)
                        .join(', '),
                    effectiveDate: policy.effective_date,
                    statusDate: policy.status_date,
                    policyStatus: policy.policy_status,
                    username: policy.username?.toLowerCase(),
                    assignedTo: policy.assigned_to,
                }));

            dispatch(setPolicyListData({ policyListData }));
        } catch (err) {
            handleError('An error occurred retrieving clinical policies.', err);
        }
    };

export const fetchPolicyDetails =
    ({ payerId, draftId }) =>
    async (dispatch) => {
        try {
            const response = await getPolicyDraftDetails({ payerId, draftId });

            const draft = response && {
                draftId: response.draft_id,
                policyId: response.policy_id,
                statusHistory: response.status_history,
                changeRequests:
                    // Filter out internal change requests by checking username for FMH domain
                    response.change_requests?.filter(
                        (request) => !(request.username ?? '').match(/@freemarkethealth.com$/),
                    ) ?? [],
                questions: JSON.parse(response.graph ?? '{}').nodes ?? [],
            };

            dispatch(setPolicyDetails({ draftId, draft }));
        } catch (err) {
            handleError('An error occurred retrieving clinical policy details.', err);
            dispatch(setPolicyDetails({ draftId }));
        }
    };

export const usePolicyListData = () => {
    const dispatch = useDispatch();
    const { payerId } = useSelector((state) => state.auth);

    const policyListData = useSelector((state) => state.policies.policyListData);
    useEffect(() => {
        if (payerId && !policyListData) {
            dispatch(fetchPolicies({ payerId }));
        }
    }, [dispatch, payerId, policyListData]);
    return policyListData;
};
