import { Loading } from '@strata/react-loading-animation';
import { MessagingBlock } from '@strata/react-messaging-block';
import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useTestLakeConnectionQuery } from 'store/api/apertureApi';

/** Checks data lake connection. If call take longer than 3s, show cold start loading message */
export const DataLakeColdStartCheck = () => {
    const { isLoading } = useTestLakeConnectionQuery();
    const [showLoading, setShowLoading] = useState(false);
    useEffect(() => {
        if (!isLoading) {
            setShowLoading(false);
        }

        const handler = setTimeout(() => {
            if (isLoading) {
                setShowLoading(true);
            }
        }, 3000);
        return () => clearTimeout(handler);
    }, [isLoading]);

    return showLoading ? (
        <div className="sds_data-lake-cold-start-check">
            <MessagingBlock
                size={MessagingBlock.Sizes.LARGE}
                icon={<Loading inline />}
                primaryText="Gathering payer data..."
                secondaryText="This could take a few minutes."
            />
        </div>
    ) : (
        <Outlet />
    );
};
