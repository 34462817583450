import { createSlice } from '@reduxjs/toolkit';

import { getDiagnoses, getSupplierDetails } from 'api/referenceAPI';
import { handleError } from 'services/Error';

export const referenceSlice = createSlice({
    name: 'reference',
    initialState: {
        supplierDetails: {},
        diagnosisList: undefined,
    },
    reducers: {
        setSupplierDetails(state, action) {
            const { supplierNpi, supplier } = action.payload;
            return {
                ...state,
                supplierDetails: {
                    ...state.supplierDetails,
                    [supplierNpi]: supplier,
                },
            };
        },
        setDiagnosisList(state, action) {
            return { ...state, diagnosisList: action.payload };
        },
    },
});

export const { setSupplierDetails, setDiagnosisList } = referenceSlice.actions;

export const fetchSupplierDetails = (supplierNpi) => async (dispatch) => {
    try {
        const response = await getSupplierDetails(supplierNpi);

        const supplier = {
            name: response.organization_name,
            phone: response.telephone_number,
            fax: response.fax_number,
            email: response.email,
            address1: response.address_1,
            address2: response.address_2,
            city: response.city,
            state: response.state,
            zip: response.postal_code,
            npi: response.supplier_npi,
            networkStatus: response.network_status,
        };

        dispatch(setSupplierDetails({ supplierNpi, supplier }));
    } catch (err) {
        handleError('An error occurred retrieving pharmacy details.', err);
    }
};

export const fetchDiagnoses = () => async (dispatch) => {
    try {
        const response = await getDiagnoses();

        const diagnosisList = Object.fromEntries(
            response.map((diagnosis) => [
                diagnosis.oid,
                { oid: diagnosis.oid, description: diagnosis.description },
            ]),
        );

        dispatch(setDiagnosisList(diagnosisList));
    } catch (err) {
        handleError('An error occurred retrieving diagnoses.', err);
    }
};
