import { saveAs } from 'file-saver';
import { useEffect, useState } from 'react';

import {
    deletePolicyFile,
    getPolicyFile,
    getPolicyFileList,
    postPolicyFile,
} from 'api/clinicalPolicyAPI';
import { handleError } from 'services/Error';

export const usePolicyFileList = ({ payerId, draftId, isOpen }) => {
    const [fileList, setFileList] = useState();
    useEffect(() => {
        (async () => {
            try {
                if (payerId && draftId && isOpen) {
                    setFileList(await getPolicyFileList({ payerId, draftId }));
                }
            } catch (err) {
                handleError('An error occurred retrieving uploaded files.', err);
            }
        })();
    }, [payerId, draftId, isOpen]);
    return fileList;
};

export const downloadPolicyFile = async ({ payerId, draftId, filename }) => {
    try {
        saveAs(await getPolicyFile({ payerId, draftId, filename }), filename);
    } catch (err) {
        handleError(`An error occurred downloading ${filename}.`, err);
    }
};

export const uploadPolicyFiles = async ({ payerId, draftId, files }) => {
    try {
        await Promise.all(files.map(async (file) => postPolicyFile({ payerId, draftId, file })));
    } catch (err) {
        handleError('An error occurred uploading files.', err);
        throw err; // Propagate error to FileUpload component
    }
};

export const removePolicyFile = async ({ payerId, draftId, filename }) => {
    try {
        await deletePolicyFile({ payerId, draftId, filename });
    } catch (err) {
        handleError(`An error occurred deleting ${filename}.`, err);
        throw err; // Propagate error to FileUpload component
    }
};
