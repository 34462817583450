import { DialogWithForm } from '@strata/react-dialog';
import { FileUploadFormik } from '@strata/react-file-upload';
import { TextAreaFormik } from '@strata/react-inputs';
import { validationStrings } from '@strata/strata-ui-strings';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { putRequestChanges } from 'api/clinicalPolicyAPI';
import { handleError } from 'services/Error';
import { downloadPolicyFile, removePolicyFile, uploadPolicyFiles } from 'services/Files';
import { fetchPolicies } from 'store/policies/policiesSlice';

export const RequestChangesDialog = ({
    isOpen,
    onSecondaryButtonClick,
    policyData = undefined,
}) => {
    const dispatch = useDispatch();
    const { payerId } = useSelector((state) => state.auth);
    return (
        <Formik
            initialValues={{ attachments: [] }}
            enableReinitialize
            validationSchema={Yup.object({
                changeDescription: Yup.string().required(
                    validationStrings.requiredField.errorMessage,
                ),
                attachments: Yup.array(),
            })}
            onSubmit={async (values, { resetForm }) => {
                try {
                    await putRequestChanges({
                        payerId,
                        draftId: policyData.draftId,
                        change: values.changeDescription,
                        attachments: values.attachments.map((file) => file.name),
                    });
                    await dispatch(fetchPolicies({ payerId }));
                    resetForm();
                    onSecondaryButtonClick();
                } catch (err) {
                    handleError('An error occurred requesting policy changes.', err);
                }
            }}
        >
            {(formik) => (
                <DialogWithForm
                    dialogTitle="Request Changes"
                    isOpen={isOpen}
                    primaryButtonLabel="Send Request"
                    isPrimaryButtonDisabled={!formik.isValid || formik.isSubmitting}
                    secondaryButtonLabel="Cancel"
                    onSecondaryButtonClick={(e) => {
                        formik.handleReset(e);
                        onSecondaryButtonClick();
                    }}
                    dialogContent={
                        <>
                            <p className="margin__bottom--large">
                                You can request changes from the Free Market Health team. Once the
                                changes are completed, this policy will be sent by Free Market
                                Health for another review.
                            </p>
                            <TextAreaFormik
                                id="changeDescription"
                                label="Change Description"
                                required
                                verticalSpacingSmall
                            />
                            <FileUploadFormik
                                id="attachments"
                                label="Additional Clinical Documents"
                                onAddFiles={(files) =>
                                    uploadPolicyFiles({
                                        payerId,
                                        draftId: policyData?.draftId,
                                        files,
                                    })
                                }
                                onRemoveFile={(filename) =>
                                    removePolicyFile({
                                        payerId,
                                        draftId: policyData?.draftId,
                                        filename,
                                    })
                                }
                                onFileClick={(filename) =>
                                    downloadPolicyFile({
                                        payerId,
                                        draftId: policyData?.draftId,
                                        filename,
                                    })
                                }
                            />
                        </>
                    }
                />
            )}
        </Formik>
    );
};

RequestChangesDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSecondaryButtonClick: PropTypes.func.isRequired,
    policyData: PropTypes.shape({
        draftId: PropTypes.string.isRequired,
    }),
};
