import { useSelector } from 'react-redux';

import { useGetPayerDetailsQuery } from 'store/api/referenceApi';

/* Payer specific feature flags */
export const PayerFeatures = {
    APREFERRAL: 'APREFERRAL',
    MEDBILLING: 'MEDBILLING',
    POLICY_MAN: 'POLICY_MAN',
    REQUESTS: 'REQUESTS',
};

export const usePayerFeatures = () => {
    const { payerId } = useSelector((state) => state.auth);
    const { data: payerDetails } = useGetPayerDetailsQuery({ payerId }, { skip: !payerId });
    return payerDetails?.payerFeatures
        ? Object.fromEntries(payerDetails.payerFeatures.map((feature) => [feature, true]))
        : undefined;
};
