import { addCommas } from '@strata/helper-functions';
import { SecondaryData } from '@strata/react-grid';
import { ServicingRate } from '@strata/react-servicing-rate';

import { GridHeaderWithInfo } from 'components/shared/GridHeaderWithInfo';

export const ProductStatsColumns = [
    {
        accessorKey: 'numberOfReferrals',
        header: 'Active Referrals',
        size: 100,
        cell: ({ getValue }) => <SecondaryData data={addCommas(getValue())} />,
    },
    {
        accessorKey: 'baselineRate',
        header: () => (
            <GridHeaderWithInfo
                header="Baseline Rate"
                tooltipText="The rate for the given product that was in place prior to Free Market Health's market-driven rates"
            />
        ),
        size: 100,
        cell: ({ row, getValue }) => (
            <SecondaryData
                data={
                    <ServicingRate
                        servicingRate={getValue()}
                        referentPriceType={row.original.referentPriceType}
                    />
                }
            />
        ),
    },
    {
        accessorKey: 'avgWinningBidRate',
        header: 'Avg Servicing Rate',
        size: 100,
        cell: ({ row, getValue }) => (
            <SecondaryData
                data={
                    <ServicingRate
                        servicingRate={getValue()}
                        referentPriceType={row.original.referentPriceType}
                    />
                }
            />
        ),
    },
];
