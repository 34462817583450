import { createApi } from '@reduxjs/toolkit/query/react';

import { TREATMENT_API_URL } from 'api/APIBase';
import { handleError } from 'services/Error';
import { mapTreatmentDetails } from 'services/treatmentHelpers';
import { getBaseQuery } from 'store/api/apiBaseQuery';

export const treatmentApi = createApi({
    baseQuery: getBaseQuery(TREATMENT_API_URL),
    reducerPath: 'treatmentApi',
    endpoints: (build) => ({
        getTreatmentDetails: build.query({
            query: ({ payerId, treatmentId }) => `${payerId}/treatments/${treatmentId}`,
            transformResponse: mapTreatmentDetails,
            transformErrorResponse: (err) => {
                if (err.status === 404) {
                    handleError('Treatment not found.', err);
                } else {
                    handleError('An error occurred retrieving treatment details.', err);
                }
            },
        }),
    }),
});

export const { useGetTreatmentDetailsQuery } = treatmentApi;
