import { Tag } from '@strata/react-tags';
import PropTypes from 'prop-types';

export const ProductLabel = ({ product = undefined, isBrand = false }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <span>{product}</span>
        {isBrand && (
            <Tag label="Brand" className="margin__left--medium" style={{ flexShrink: 0 }} />
        )}
    </div>
);

ProductLabel.propTypes = {
    product: PropTypes.string,
    isBrand: PropTypes.bool,
};
