import { createApi } from '@reduxjs/toolkit/query/react';
import { useMemo } from 'react';

import { AUTH_API_URL } from 'api/APIBase';
import { handleRTKQueryError } from 'services/Error';
import { getBaseQuery } from 'store/api/apiBaseQuery';

const TagType = { USER_PROFILE: 'USER_PROFILE', PAYER_USER_NAME_MAP: 'PAYER_USER_NAME_MAP' };

export const authApi = createApi({
    baseQuery: getBaseQuery(AUTH_API_URL),
    reducerPath: 'authApi',
    tagTypes: Object.values(TagType),
    endpoints: (build) => ({
        getUserProfile: build.query({
            query: () => 'profile/payer',
            providesTags: [TagType.USER_PROFILE],
            transformResponse: (response) => ({
                fullName: response?.full_name,
                email: response?.upn,
                role: response?.display_name,
                segmentIds: response?.segment_ids,
            }),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving user profile.',
            ),
        }),
        putUserProfile: build.mutation({
            query: ({ fullName }) => ({
                url: 'profile/payer',
                method: 'PUT',
                body: { full_name: fullName },
            }),
            transformErrorResponse: handleRTKQueryError('An error occurred updating user profile.'),
            invalidatesTags: (_, error) =>
                !error ? [TagType.USER_PROFILE, TagType.PAYER_USER_NAME_MAP] : undefined,
        }),
        getCompassUserNameMap: build.query({
            query: () => 'profile/compass/name_map',
            transformResponse: (response) => response,
            transformErrorResponse: handleRTKQueryError('An error occurred retrieving user names.'),
        }),
        getPayerUserNameMap: build.query({
            query: ({ payerId }) => `profile/payer/name_map/${payerId}`,
            providesTags: [TagType.PAYER_USER_NAME_MAP],
            transformResponse: (response) => response,
            transformErrorResponse: handleRTKQueryError('An error occurred retrieving user names.'),
        }),
    }),
});

export const {
    useGetUserProfileQuery,
    usePutUserProfileMutation,
    useGetCompassUserNameMapQuery,
    useGetPayerUserNameMapQuery,
} = authApi;

export const useUserNameMap = ({ payerId }) => {
    const { data: compassUserNameMap } = useGetCompassUserNameMapQuery();
    const { data: payerUserNameMap } = useGetPayerUserNameMapQuery({ payerId });
    return useMemo(
        () => ({ ...compassUserNameMap, ...payerUserNameMap }),
        [compassUserNameMap, payerUserNameMap],
    );
};
