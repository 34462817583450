import { StatusIndicator } from '@strata/react-status-indicators';
import PropTypes from 'prop-types';

const Statuses = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};

const getStatus = (status) =>
    status === Statuses.ACTIVE
        ? StatusIndicator.Status.IN_PROGRESS
        : StatusIndicator.Status.DEFAULT;

const StatusMessages = {
    [Statuses.ACTIVE]: 'Active',
    [Statuses.INACTIVE]: 'Inactive',
};

const ActiveStatusGridFilter = {
    key: 'status',
    title: 'Status',
    valueDisplayTextMap: StatusMessages,
};

export const ActiveStatusIndicator = ({ status }) => (
    <StatusIndicator
        className="sds_indicator--active-status"
        status={getStatus(status)}
        message={StatusMessages[status]}
    />
);

ActiveStatusIndicator.Statuses = Statuses;
ActiveStatusIndicator.GridFilter = ActiveStatusGridFilter;

ActiveStatusIndicator.propTypes = {
    status: PropTypes.oneOf(Object.values(Statuses)).isRequired,
};
