import { formatPhoneNumber } from '@strata/helper-functions';
import { FormSectionWithRows } from '@strata/react-form-section';
import { AddressDetailLabelValue, DetailLabelValuePair } from '@strata/react-label-value-pairs';
import PropTypes from 'prop-types';

export const PrescriberDetails = ({ prescriberDetails = undefined, isLoading = true }) => (
    <FormSectionWithRows
        sectionTitle="Prescriber Details"
        isLoading={isLoading}
        rowOneContent={
            <>
                <DetailLabelValuePair
                    style={{ width: '50%' }}
                    label="Prescriber"
                    value={prescriberDetails?.name}
                />
                <DetailLabelValuePair label="Prescriber NPI" value={prescriberDetails?.npi} />
            </>
        }
        rowTwoContent={
            <>
                <DetailLabelValuePair
                    style={{ width: '50%' }}
                    label="Phone"
                    value={formatPhoneNumber(prescriberDetails?.phone)}
                />
                <DetailLabelValuePair
                    label="Fax"
                    value={formatPhoneNumber(prescriberDetails?.fax)}
                />
            </>
        }
        rowThreeContent={
            <>
                <DetailLabelValuePair
                    style={{ width: '50%' }}
                    label="Practice"
                    value={prescriberDetails?.organizationName}
                />
                <DetailLabelValuePair label="Practice NPI" value={prescriberDetails?.practiceNpi} />
            </>
        }
        rowFourContent={
            <AddressDetailLabelValue
                style={{ width: '50%' }}
                label="Practice Address"
                address1={prescriberDetails?.address1}
                address2={prescriberDetails?.address2}
                city={prescriberDetails?.city}
                state={prescriberDetails?.state}
                zip={prescriberDetails?.zip}
            />
        }
    />
);

PrescriberDetails.propTypes = {
    prescriberDetails: PropTypes.shape({
        name: PropTypes.string,
        phone: PropTypes.string,
        fax: PropTypes.string,
        address1: PropTypes.string,
        address2: PropTypes.string,
        city: PropTypes.string,
        state: PropTypes.string,
        zip: PropTypes.string,
        npi: PropTypes.string,
        practiceNpi: PropTypes.string,
        organizationName: PropTypes.string,
    }),
    isLoading: PropTypes.bool,
};
