import { createApi } from '@reduxjs/toolkit/query/react';

import { PAYER_REQUEST_API_URL } from 'api/APIBase';
import { handleRTKQueryError } from 'services/Error';
import { getBaseQuery, jsonToFormDataOrUrlSearchParams } from 'store/api/apiBaseQuery';

const TagType = {
    REQUESTS_COUNTS: 'REQUESTS_COUNTS',
    REQUEST_LIST: 'REQUEST_LIST',
    REQUESTS: 'REQUESTS',
    TREATMENT_REQUESTS: 'TREATMENT_REQUESTS',
};

export const payerRequestApi = createApi({
    baseQuery: getBaseQuery(PAYER_REQUEST_API_URL),
    refetchOnMountOrArgChange: true,
    reducerPath: 'payerRequestApi',
    tagTypes: Object.values(TagType),
    endpoints: (build) => ({
        getOpenRequestCounts: build.query({
            query: ({ payerId }) => `${payerId}/requests/counts`,
            transformResponse: (response) => response?.open_requests,
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving request counts.',
            ),
            providesTags: [{ type: TagType.REQUESTS_COUNTS }],
        }),
        getOpenRequestsByType: build.query({
            query: ({ payerId, requestType, segmentIds }) => ({
                url: `${payerId}/requests`,
                params: { request_type: requestType, segment_ids: segmentIds },
            }),
            transformResponse: (response) => ({
                filteredCount: response?.filtered_count,
                requests: response?.requests?.map((request) => ({
                    requestId: request.request_id,
                    requestSubType: request.request_sub_type,
                    summary: request.summary,
                    additionalNotes: request.additional_notes,
                    memberId: request.member_id,
                    createDate: request.create_date,
                    submitterUpn: request.submitter_upn,
                    isUnread: request.is_unread,
                    requestNumber: request.request_number?.toString().padStart(6, '0'),
                    urgent: request.urgent,
                    requestStatus: request.request_status,
                })),
            }),
            transformErrorResponse: handleRTKQueryError('An error occurred retrieving requests.'),
            invalidatesTags: [{ type: TagType.REQUESTS_COUNTS }],
            providesTags: [{ type: TagType.REQUEST_LIST }],
        }),
        getArchivedRequests: build.query({
            query: ({
                page,
                payerId,
                segmentIds,
                searchText,
                timeframe,
                requestType,
                requestSubType,
                reason,
                requestStatus,
                urgent,
            }) => ({
                url: `${payerId}/requests/archive`,
                params: {
                    page,
                    segment_ids: segmentIds,
                    search_text: searchText,
                    timeframe,
                    request_type: requestType,
                    request_sub_type: requestSubType,
                    reason,
                    request_status: requestStatus,
                    urgent,
                },
            }),
            transformResponse: (response) => ({
                count: response?.count,
                filteredCount: response?.filtered_count,
                filters: response?.filters,
                requests: response?.requests?.map((request) => ({
                    requestId: request.request_id,
                    requestType: request.request_type,
                    requestSubType: request.request_sub_type,
                    summary: request.summary,
                    memberId: request.member_id,
                    completedDate: request.completed_date,
                    submitterUpn: request.submitter_upn,
                    isUnread: request.is_unread,
                    requestNumber: request.request_number?.toString().padStart(6, '0'),
                    urgent: request.urgent,
                    reason: request.reason,
                    requestStatus: request.request_status,
                })),
            }),
            transformErrorResponse: handleRTKQueryError('An error occurred retrieving requests.'),
            invalidatesTags: [{ type: TagType.REQUESTS_COUNTS }],
            providesTags: [{ type: TagType.REQUEST_LIST }],
        }),
        getRequestDetails: build.query({
            query: ({ payerId, requestId }) => `${payerId}/requests/${requestId}`,
            transformResponse: (response) => ({
                requestId: response?.request_id,
                segmentId: response?.segment_id,
                requestType: response?.request_type,
                requestSubType: response?.request_sub_type,
                summary: response?.summary,
                memberId: response?.member_id,
                firstName: response?.first_name,
                lastName: response?.last_name,
                dateOfBirth: response?.date_of_birth,
                createDate: response?.create_date,
                submitterUpn: response?.submitter_upn,
                requestNumber: response?.request_number?.toString().padStart(6, '0'),
                reopenedFromRequestId: response?.reopened_from_request_id,
                reopenedFromRequestNumber: response?.reopened_from_request_number
                    ?.toString()
                    .padStart(6, '0'),
                reason: response?.reason,
                requestStatus: response?.request_status,
                urgent: response?.urgent,
                additionalNotes: response?.additional_notes,
                treatmentIds: response?.treatment_ids,
                attachments: response?.attachments?.map((attachment) => ({
                    requestAttachmentId: attachment.request_attachment_id,
                    filename: attachment.filename,
                    dateAdded: attachment.date_added,
                })),
                activity: response?.activity?.map((action) => ({
                    requestActivityId: action.request_activity_id,
                    actionDate: action.action_date,
                    actionType: action.action_type,
                    actorUpn: action.actor_upn,
                    fmhAction: action.fmh_action,
                    actionDetail: action.action_detail,
                })),
            }),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving request details.',
            ),
            providesTags: (_result, _error, { requestId }) => [
                { type: TagType.REQUESTS, id: requestId },
            ],
        }),
        getRequestsForTreatment: build.query({
            query: ({ payerId, treatmentId }) => `${payerId}/treatment/${treatmentId}`,
            transformResponse: (response) =>
                response?.map((request) => ({
                    requestId: request.request_id,
                    requestNumber: request.request_number?.toString().padStart(6, '0'),
                    requestSubType: request.request_sub_type,
                    requestStatus: request.request_status,
                    createDate: request.create_date,
                    completedDate: request.completed_date,
                })),
            transformErrorResponse: handleRTKQueryError('An error occurred retrieving requests.'),
            providesTags: (_result, _error, { treatmentId }) => [
                { type: TagType.TREATMENT_REQUESTS, id: treatmentId },
            ],
        }),
        postNewRequest: build.mutation({
            query: ({
                payerId,
                segmentId,
                requestType,
                requestSubType,
                memberId,
                firstName,
                lastName,
                dateOfBirth,
                urgent,
                additionalNotes,
                treatmentIds,
                files,
            }) => ({
                url: `${payerId}/requests`,
                method: 'POST',
                body: jsonToFormDataOrUrlSearchParams(
                    {
                        segment_id: segmentId,
                        request_type: requestType,
                        request_sub_type: requestSubType,
                        member_id: memberId,
                        first_name: firstName,
                        last_name: lastName,
                        date_of_birth: dateOfBirth,
                        urgent,
                        additional_notes: additionalNotes,
                        treatment_ids: treatmentIds,
                        files,
                    },
                    true,
                ),
            }),
            transformResponse: (response) => response?.request_id?.toLocaleLowerCase(),
            transformErrorResponse: handleRTKQueryError('An error occurred creating request.'),
            invalidatesTags: (_, error, { treatmentIds }) =>
                !error
                    ? [
                          { type: TagType.REQUESTS_COUNTS },
                          { type: TagType.REQUEST_LIST },
                          ...(treatmentIds ?? []).map((id) => ({
                              type: TagType.TREATMENT_REQUESTS,
                              id,
                          })),
                      ]
                    : undefined,
        }),
        postMarkRequestRead: build.mutation({
            query: ({ payerId, requestId }) => ({
                url: `${payerId}/requests/${requestId}/read`,
                method: 'POST',
            }),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred marking request as read.',
            ),
            invalidatesTags: (_, error) =>
                !error
                    ? [{ type: TagType.REQUESTS_COUNTS }, { type: TagType.REQUEST_LIST }]
                    : undefined,
        }),
        postRequestActivity: build.mutation({
            query: ({ payerId, requestId, actionType, actionDetail }) => ({
                url: `${payerId}/requests/${requestId}/activity`,
                method: 'POST',
                body: { action_type: actionType, action_detail: actionDetail },
            }),
            transformErrorResponse: handleRTKQueryError('An error occurred updating request.'),
            invalidatesTags: (_, error, { requestId }) =>
                !error
                    ? [
                          { type: TagType.REQUESTS, id: requestId },
                          { type: TagType.REQUEST_LIST },
                          { type: TagType.REQUESTS_COUNTS },
                      ]
                    : undefined,
        }),
        postAttachFile: build.mutation({
            query: ({ payerId, requestId, attachment }) => ({
                url: `${payerId}/requests/${requestId}/attachment`,
                method: 'POST',
                body: jsonToFormDataOrUrlSearchParams({ attachment }, true),
            }),
            transformErrorResponse: handleRTKQueryError('An error occurred uploading file.'),
            invalidatesTags: (_, error, { requestId }) =>
                !error ? [{ type: TagType.REQUESTS, id: requestId }] : undefined,
        }),
    }),
});

export const {
    useGetOpenRequestCountsQuery,
    useGetOpenRequestsByTypeQuery,
    useGetArchivedRequestsQuery,
    useGetRequestDetailsQuery,
    useGetRequestsForTreatmentQuery,
    usePostMarkRequestReadMutation,
    usePostNewRequestMutation,
    usePostRequestActivityMutation,
    usePostAttachFileMutation,
} = payerRequestApi;
