import { PrimaryData } from '@strata/react-grid';
import { Tag } from '@strata/react-tags';
import PropTypes from 'prop-types';

export const ProductCell = ({ product = undefined, isBrand = false }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ flexGrow: 1 }}>
            <PrimaryData data={product} />
        </span>
        {isBrand && <Tag label="Brand" style={{ flexShrink: 0 }} />}
    </div>
);

ProductCell.propTypes = {
    product: PropTypes.string,
    isBrand: PropTypes.bool,
};
