import { createSlice } from '@reduxjs/toolkit';
import { RequestType } from '@strata/react-chat';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const Grids = {
    CLINICAL_POLICIES: 'clinicalPolicies',
    PRODUCTS: 'products',
    SERVICING_PHARMACIES: 'servicingPharmacies',
    PRESCRIBERS: 'prescribers',
    REFERRALS: 'referrals',
    PHARMACIES: 'pharmacies',
    ...RequestType,
};

const initialGridState = {
    sort: undefined,
    search: undefined,
    activeFilters: undefined,
    filterPaneWidth: undefined,
    page: 0,
};

const initGridStates = () =>
    Object.fromEntries(Object.values(Grids).map((grid) => [grid, initialGridState]));

export const gridsSlice = createSlice({
    name: 'grids',
    initialState: initGridStates(),
    reducers: {
        setGridSort(state, action) {
            const { grid, sort } = action.payload;
            return { ...state, [grid]: { ...state[grid], sort } };
        },
        setGridSearch(state, action) {
            const { grid, search } = action.payload;
            return { ...state, [grid]: { ...state[grid], search } };
        },
        setGridActiveFilters(state, action) {
            const { grid, activeFilters } = action.payload;
            return { ...state, [grid]: { ...state[grid], activeFilters } };
        },
        setFilterPaneWidth(state, action) {
            const { grid, filterPaneWidth } = action.payload;
            return { ...state, [grid]: { ...state[grid], filterPaneWidth } };
        },
        setCurrentPage(state, action) {
            const { grid, page } = action.payload;
            return { ...state, [grid]: { ...state[grid], page } };
        },
        resetGrid(state, action) {
            const grid = action.payload;
            return { ...state, [grid]: initialGridState };
        },
    },
});

export const {
    setGridSort,
    setGridSearch,
    setGridActiveFilters,
    setFilterPaneWidth,
    setCurrentPage,
    resetGrid,
} = gridsSlice.actions;

export const useGridState = (grid, initialSortProperties) => {
    const dispatch = useDispatch();
    const { sort, search, activeFilters, filterPaneWidth, page } = useSelector(
        (state) => state.grids[grid],
    );
    useEffect(() => {
        if (!sort) {
            dispatch(setGridSort({ grid, sort: initialSortProperties }));
        }
    }, [grid, sort, initialSortProperties, dispatch]);
    return {
        sort,
        search,
        activeFilters,
        filterPaneWidth,
        page,
        updateGridSort: (sortProperties) => dispatch(setGridSort({ grid, sort: sortProperties })),
        updateGridSearch: (searchText) => dispatch(setGridSearch({ grid, search: searchText })),
        updateGridActiveFilters: (filters) =>
            dispatch(setGridActiveFilters({ grid, activeFilters: filters })),
        updateFilterPaneWidth: (width) =>
            dispatch(setFilterPaneWidth({ grid, filterPaneWidth: width })),
        updateCurrentPage: (currentPage) => dispatch(setCurrentPage({ grid, page: currentPage })),
    };
};
