import { createApi } from '@reduxjs/toolkit/query/react';

import { SCOPE_ASSESSOR_API_URL } from 'api/APIBase';
import { handleError } from 'services/Error';
import { mapMemberSummary } from 'services/scopeAssessorHelpers';
import { getBaseQuery } from 'store/api/apiBaseQuery';

export const scopeAssessorApi = createApi({
    baseQuery: getBaseQuery(SCOPE_ASSESSOR_API_URL),
    reducerPath: 'scopeAssessorApi',
    endpoints: (build) => ({
        getMemberDetails: build.query({
            query: ({ payerId, segmentId, memberId, firstName, lastName, dateOfBirth }) => ({
                url: `${payerId}/members/${memberId}`,
                params: {
                    segment_id: segmentId,
                    firstname: firstName,
                    lastname: lastName,
                    dateofbirth: dateOfBirth,
                    ignore_dates: true,
                },
            }),
            transformResponse: mapMemberSummary,
            transformErrorResponse: (err) => {
                if (err.status === 404) {
                    handleError('Member not found.', err);
                } else {
                    handleError('An error occurred retrieving member details.', err);
                }
            },
        }),
    }),
});

export const { useGetMemberDetailsQuery } = scopeAssessorApi;
