import { NotificationSettingsDialog } from '@strata/react-dialog';
import { SuccessToast } from '@strata/react-toast-message';
import PropTypes from 'prop-types';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { PayerRequestSummarySettings } from 'components/header/dialogs/notificationSettings/PayerRequestSummarySettings';
import { NotificationTypes } from 'components/notifications/NotificationComponentMap';
import {
    useGetNotificationPreferencesQuery,
    usePostUpdateNotificationPreferencesMutation,
} from 'store/api/notificationApi';

const summaryEmailTypes = (permissions) => [
    {
        value: NotificationTypes.PAYREQSUM_APERTURE,
        text: 'Daily Request Summary',
        description: 'Summary of open and closed requests delivered in the morning.',
        AdditionalSettings: <PayerRequestSummarySettings />,
        additionalSettingsCallback: (formik, values) =>
            formik.setFieldValue(
                'payerRequestSummaryTypes',
                values.includes(NotificationTypes.PAYREQSUM_APERTURE)
                    ? PayerRequestSummarySettings.Options(permissions).map(({ value }) => value)
                    : [],
            ),
    },
];

const notificationTypes = [
    {
        value: NotificationTypes.PAYREQ_APERTURE,
        text: 'A new request is submitted',
    },
];

export const UserNotificationSettingsDialog = ({ isOpen, onSecondaryButtonClick }) => {
    const { permissions } = useSelector((state) => state.auth);

    const [postUpdateNotificationPreferences] = usePostUpdateNotificationPreferencesMutation();
    const { data, isFetching, isError } = useGetNotificationPreferencesQuery(undefined, {
        skip: !isOpen,
    });
    useEffect(() => {
        if (isError) {
            onSecondaryButtonClick();
        }
    }, [isError, onSecondaryButtonClick]);

    const preferences = useMemo(
        () =>
            !isFetching && data
                ? {
                      email: data.email,
                      inApp: data.in_app,
                      ...PayerRequestSummarySettings.initialValues(data),
                  }
                : undefined,
        [data, isFetching],
    );

    return (
        <NotificationSettingsDialog
            isOpen={isOpen}
            notificationTypes={notificationTypes}
            summaryEmailTypes={summaryEmailTypes(permissions)}
            values={preferences}
            onSecondaryButtonClick={onSecondaryButtonClick}
            onSubmit={async (values) => {
                await postUpdateNotificationPreferences({
                    email: values.email,
                    in_app: values.inApp,
                    ...PayerRequestSummarySettings.submitValues(values),
                }).unwrap();
                SuccessToast({ message: 'Your notification settings were updated successfully.' });
                onSecondaryButtonClick();
            }}
            width={770}
        />
    );
};

UserNotificationSettingsDialog.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSecondaryButtonClick: PropTypes.func.isRequired,
};
