import wretch from 'wretch';
// eslint-disable-next-line import/no-unresolved
import formData from 'wretch/addons/formData';
// eslint-disable-next-line import/no-unresolved
import queryString from 'wretch/addons/queryString';

// API URL constants
export const ANNOUNCEMENT_API_URL = '/announcement';
export const APERTURE_API_URL = '/aperture';
export const AUTH_API_URL = '/authorization';
export const CLIENT_API_URL = '/clients';
export const CLINICAL_POLICY_API_URL = '/clinicalpolicy';
export const NOTIFICATION_API_URL = '/notifications';
export const REFERENCE_API_URL = '/reference';
export const PAYER_REQUEST_API_URL = '/payer_request';
export const PERFORMANCE_API_URL = '/performance';
export const SCOPE_ASSESSOR_API_URL = '/scope-assessor';
export const TREATMENT_API_URL = '/treatment';

/** Singleton for base wretcher. Bearer token is added on login. */
export const fmhAPI = {
    base: wretch(process.env.REACT_APP_BASE_URL).addon(queryString).addon(formData),
};

/**
 * Returns wretcher w/ Bearer token and optional x-npi header
 * @param {string} url url to append to the BASE_URL
 * @param {string} payerId optional payerId to be sent as x-npi header
 */
export const fmhNpiAPI = ({ url, payerId }) =>
    fmhAPI.base.headers(payerId ? { 'x-npi': payerId } : undefined).url(url);
