import { useAuth0 } from '@auth0/auth0-react';
import { IconNames } from '@blueprintjs/icons';
import { DateFormats, formatDate, formatNameLastFirst } from '@strata/helper-functions';
import { ActionButtonPageTitle } from '@strata/react-buttons';
import {
    ChatWithRequestPane,
    isRequestArchived,
    RequestActionType,
    RequestType,
    RequestTypeDisplayTextMap,
    UserCell,
} from '@strata/react-chat';
import { FormHeaderAndContent } from '@strata/react-form-header-and-content';
import {
    FormTitleLabelValuePair,
    HeaderLabel,
    HeaderLabelValuePair,
} from '@strata/react-label-value-pairs';
import { Icon } from '@strata/react-status-icons';
import { PayerRequestStatusIndicator } from '@strata/react-status-indicators';
import { SuccessToast } from '@strata/react-toast-message';
import { saveAs } from 'file-saver';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getTreatmentsForPatient } from 'api/apertureAPI';
import { getRequestAttachment } from 'api/payerRequestAPI';
import { Permissions } from 'services/constants';
import { handleError } from 'services/Error';
import { AppRoutes } from 'services/routes';
import { useUserNameMap } from 'store/api/authApi';
import {
    useGetRequestDetailsQuery,
    usePostAttachFileMutation,
    usePostMarkRequestReadMutation,
    usePostRequestActivityMutation,
} from 'store/api/payerRequestApi';
import { useGetAllProductServicesQuery, useGetSegmentsQuery } from 'store/api/referenceApi';

const RequestTypeWritePermissionMap = {
    [RequestType.ESCALATION]: Permissions.WRITE_PAYER_REQUEST_ESCALATION,
    [RequestType.CHANGE]: Permissions.WRITE_PAYER_REQUEST_CHANGE,
    [RequestType.OTHER]: Permissions.WRITE_PAYER_REQUEST_OTHER,
};

const getActionButtonPageTitleLabel = (requestDetails) =>
    isRequestArchived(requestDetails)
        ? 'Archive'
        : RequestTypeDisplayTextMap[requestDetails.requestType];

export const RequestDetailsPage = () => {
    const { requestId } = useParams();
    const { user } = useAuth0();
    const navigate = useNavigate();
    const { payerId, permissions } = useSelector((state) => state.auth);

    const { data, isFetching } = useGetRequestDetailsQuery({ payerId, requestId });
    const [postRequestActivity] = usePostRequestActivityMutation();
    const [postAttachFile] = usePostAttachFileMutation();
    const userNameMap = useUserNameMap({ payerId });

    const { data: products } = useGetAllProductServicesQuery();
    const [treatmentProductOidMap, setTreatmentProductOidMap] = useState({});
    useEffect(() => {
        (async () => {
            try {
                if (!isFetching && data?.memberId) {
                    const result = await getTreatmentsForPatient({
                        memberId: data?.memberId,
                        memberFirstName: data?.firstName,
                        memberLastName: data?.lastName,
                        memberDob: data?.dateOfBirth,
                    });
                    setTreatmentProductOidMap(
                        Object.fromEntries(
                            (result?.treatments ?? []).map((treatment) => [
                                treatment.treatment_id,
                                treatment.product_service_oid,
                            ]),
                        ),
                    );
                }
            } catch (err) {
                handleError('An error occurred retrieving treatment details.', err);
            }
        })();
    }, [data, isFetching]);

    const requestDetails = useMemo(
        () =>
            data
                ? {
                      ...data,
                      submitterFullName: userNameMap[data.submitterUpn],
                      activity: data.activity?.map((action) => ({
                          ...action,
                          actorFullName: userNameMap[action.actorUpn],
                      })),
                      treatments: data.treatmentIds?.map((treatmentId) => ({
                          treatmentId,
                          productServiceDescription:
                              products?.productServiceMap[treatmentProductOidMap[treatmentId]],
                      })),
                  }
                : undefined,
        [data, userNameMap, treatmentProductOidMap, products?.productServiceMap],
    );

    // Once data loads, mark request as read
    const [postMarkRequestRead] = usePostMarkRequestReadMutation();
    useEffect(() => {
        if (!isFetching && requestId === data?.requestId) {
            postMarkRequestRead({ payerId, requestId });
        }
    }, [isFetching, data?.requestId, payerId, requestId, postMarkRequestRead]);

    const { data: segments } = useGetSegmentsQuery({ payerId });

    return (
        <FormHeaderAndContent
            rowOneContentHeader={
                !isFetching ? (
                    <ActionButtonPageTitle
                        label={getActionButtonPageTitleLabel(requestDetails)}
                        title={`${RequestTypeDisplayTextMap[requestDetails.requestType]} Details`}
                        onClick={() =>
                            navigate({
                                pathname: AppRoutes.requests,
                                hash: isRequestArchived(requestDetails)
                                    ? RequestType.ARCHIVE.toLocaleLowerCase()
                                    : requestDetails.requestType?.toLocaleLowerCase(),
                            })
                        }
                    />
                ) : undefined
            }
            rowTwoContentHeader={
                !isFetching ? (
                    <>
                        {requestDetails.requestType === RequestType.ESCALATION && (
                            <FormTitleLabelValuePair
                                label={formatNameLastFirst(
                                    requestDetails.lastName,
                                    requestDetails.firstName,
                                )}
                                value={requestDetails.memberId}
                            />
                        )}
                        {requestDetails.requestType !== RequestType.OTHER && (
                            <HeaderLabelValuePair
                                label="Request Type"
                                value={requestDetails.requestSubType}
                            />
                        )}
                        {requestDetails.requestType !== RequestType.ESCALATION && (
                            <HeaderLabelValuePair label="Summary" value={requestDetails.summary} />
                        )}
                        <HeaderLabelValuePair
                            label="Created On"
                            value={formatDate(requestDetails.createDate, DateFormats.DATE_TIME)}
                        />
                        <div>
                            <HeaderLabel label="Created By" />
                            <UserCell
                                fullName={requestDetails.submitterFullName}
                                upn={requestDetails.submitterUpn}
                            />
                        </div>
                        <HeaderLabelValuePair
                            label="Request Urgency"
                            value={
                                requestDetails.urgent ? (
                                    <div
                                        className="flex__gap--x-small"
                                        style={{ alignItems: 'center' }}
                                    >
                                        <Icon
                                            icon={IconNames.WARNING_SIGN}
                                            intent={Icon.Intent.WARNING}
                                        />
                                        <span>Urgent</span>
                                    </div>
                                ) : undefined
                            }
                        />
                        <HeaderLabelValuePair
                            label="Request #"
                            value={requestDetails.requestNumber}
                        />
                        <HeaderLabelValuePair
                            label="Business Segment"
                            value={
                                segments?.find((segment) => segment.id === requestDetails.segmentId)
                                    ?.name
                            }
                        />
                        <div>
                            <HeaderLabel label="Status" />
                            <PayerRequestStatusIndicator status={requestDetails.requestStatus} />
                        </div>
                    </>
                ) : undefined
            }
            className="tabbed-grid-content"
            mainContent={
                <ChatWithRequestPane
                    request={requestDetails}
                    treatmentUrlBase={AppRoutes.treatmentDetails('')}
                    requestUrlBase={AppRoutes.requestDetails('')}
                    onFileDownloadClick={async ({ requestAttachmentId, filename }) => {
                        try {
                            const response = await getRequestAttachment({
                                payerId,
                                requestId,
                                requestAttachmentId,
                            });
                            saveAs(response, filename);
                        } catch (err) {
                            handleError('An error occurred downloading attachment.', err);
                        }
                    }}
                    conversationType={ChatWithRequestPane.ConversationType.CHAT}
                    disabled={
                        !permissions.includes(
                            RequestTypeWritePermissionMap[requestDetails?.requestType],
                        )
                    }
                    isLoading={isFetching}
                    userUPN={user?.email}
                    isSupportUser={false}
                    onMessageSend={({ details }) =>
                        postRequestActivity({
                            payerId,
                            requestId,
                            actionType: RequestActionType.MESSAGE,
                            actionDetail: details.message,
                        }).unwrap()
                    }
                    onAddFile={async ({ file }) => {
                        await postAttachFile({ payerId, requestId, attachment: file }).unwrap();
                        SuccessToast({ message: 'File added successfully.' });
                    }}
                    onApprovalComplete={({ actionTaken, actionDetail }) =>
                        postRequestActivity({
                            payerId,
                            requestId,
                            actionType: actionTaken,
                            actionDetail,
                        }).unwrap()
                    }
                />
            }
        />
    );
};
