import { APERTURE_API_URL, fmhNpiAPI } from 'api/APIBase';

/** Gets filtered referrals for payer */
export async function getReferrals({
    segmentId,
    page,
    supplierNpi,
    prescriberNpi,
    searchText,
    activeFilters,
}) {
    const url = `${APERTURE_API_URL}/referrals`;
    return fmhNpiAPI({ url })
        .query({
            ...(segmentId ? { segment_id: segmentId } : {}),
            page,
            ...(supplierNpi ? { pharmacy_npi: supplierNpi } : {}),
            ...(prescriberNpi ? { prescriber_npi: prescriberNpi } : {}),
            ...(searchText ? { search_text: searchText } : {}),
            ...activeFilters,
        })
        .get()
        .json();
}

/** Gets referrals for a patient */
export async function getTreatmentsForPatient({
    memberId,
    memberFirstName,
    memberLastName,
    memberDob,
}) {
    const url = `${APERTURE_API_URL}/treatments`;
    return fmhNpiAPI({ url })
        .query({
            ...(memberId ? { member_id: memberId } : {}),
            ...(memberFirstName ? { member_first_name: memberFirstName } : {}),
            ...(memberLastName ? { member_last_name: memberLastName } : {}),
            ...(memberDob ? { member_dob: memberDob } : {}),
        })
        .get()
        .json();
}

/** Checks if a group ID is restricted */
export async function getCheckRestrictedGroup({
    groupId,
    memberId,
    memberFirstName,
    memberLastName,
    memberDob,
}) {
    const url = `${APERTURE_API_URL}/restricted_group_check/${groupId}`;
    return fmhNpiAPI({ url })
        .query({
            ...(memberId ? { member_id: memberId } : {}),
            ...(memberFirstName ? { member_first_name: memberFirstName } : {}),
            ...(memberLastName ? { member_last_name: memberLastName } : {}),
            ...(memberDob ? { member_dob: memberDob } : {}),
        })
        .get()
        .json();
}
