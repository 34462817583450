import { AUTH_API_URL, fmhNpiAPI } from 'api/APIBase';

/** Gets FMH token using Auth0 access token */
export async function getFmhToken({ accessToken }) {
    const url = `${AUTH_API_URL}/access_tokens/`;
    return fmhNpiAPI({ url }).auth(`Bearer ${accessToken}`).get().json();
}

/** Gets if current user has accepted the terms of use */
export async function getHasAcceptedTermsOfUse() {
    const url = `${AUTH_API_URL}/terms_of_use`;
    return fmhNpiAPI({ url }).get().json();
}

/** Accept current terms of use for the current user */
export async function postAcceptTermsOfUse() {
    const url = `${AUTH_API_URL}/terms_of_use`;
    return fmhNpiAPI({ url }).post().json();
}
