import { CLINICAL_POLICY_API_URL, fmhNpiAPI } from 'api/APIBase';

export async function getQuestionTree({ payerId, productServiceOid, diagnosisOid }) {
    const url = `${CLINICAL_POLICY_API_URL}/${payerId}/policies/${productServiceOid}/${diagnosisOid}`;
    const data = await fmhNpiAPI({ url, payerId }).get().json();
    return JSON.parse(data.graph);
}

export async function getAllPolicies({ payerId }) {
    const url = `${CLINICAL_POLICY_API_URL}/${payerId}/drafts`;
    return fmhNpiAPI({ url, payerId }).get().json();
}

export async function getPolicyDraftDetails({ payerId, draftId }) {
    const url = `${CLINICAL_POLICY_API_URL}/${payerId}/drafts/${draftId}`;
    return fmhNpiAPI({ url, payerId })
        .get()
        .notFound(() => ({}))
        .json();
}

export async function putApproveDraft({ payerId, draftId, effectiveDate, comment }) {
    const url = `${CLINICAL_POLICY_API_URL}/${payerId}/drafts/${draftId}/approve`;
    const body = {
        internal: false,
        effective_date: effectiveDate,
        comment,
    };
    return fmhNpiAPI({ url, payerId }).put(body).json();
}

export async function putRequestChanges({ payerId, draftId, change, attachments }) {
    const url = `${CLINICAL_POLICY_API_URL}/${payerId}/drafts/${draftId}/changes`;
    const body = {
        internal: false,
        change,
        attachments,
    };
    return fmhNpiAPI({ url, payerId }).put(body).json();
}

export async function putUpdateDraftStatus({ payerId, draftId, policyStatus, comment }) {
    const url = `${CLINICAL_POLICY_API_URL}/${payerId}/drafts/${draftId}/next`;
    const body = {
        policy_status: policyStatus,
        comment,
    };
    return fmhNpiAPI({ url, payerId }).put(body).json();
}

/* Change Request Files */

export async function getPolicyFileList({ payerId, draftId }) {
    const url = `${CLINICAL_POLICY_API_URL}/${payerId}/attachments/${draftId}`;
    return fmhNpiAPI({ url, payerId }).get().json();
}

export async function getPolicyFile({ payerId, draftId, filename }) {
    const url = `${CLINICAL_POLICY_API_URL}/${payerId}/attachments/${draftId}/${filename}`;
    return fmhNpiAPI({ url, payerId }).get().blob();
}

export async function postPolicyFile({ payerId, draftId, file }) {
    const url = `${CLINICAL_POLICY_API_URL}/${payerId}/attachments/${draftId}`;
    return fmhNpiAPI({ url, payerId })
        .formData({
            file,
        })
        .post()
        .json();
}

export async function deletePolicyFile({ payerId, draftId, filename }) {
    const url = `${CLINICAL_POLICY_API_URL}/${payerId}/attachments/${draftId}/${filename}`;
    return fmhNpiAPI({ url, payerId }).delete().json();
}
