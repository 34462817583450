import { useAuth0 } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { BlankScreen } from '@strata/react-page-templates';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { fmhAPI } from 'api/APIBase';
import { getFmhToken } from 'api/authAPI';
import { LOGIN_REDIRECT_KEY } from 'services/constants';
import { handleError } from 'services/Error';
import { AppRoutes } from 'services/routes';
import { SocketType, wsConnect } from 'services/socketHelpers';
import { setAccessToken } from 'store/auth/authSlice';

/**
 * Component to be rendered on callback from Auth0.
 * Handles authentication of the user and redirects to /home.
 */
export const AuthCallback = () => {
    const { isLoading, user, getAccessTokenSilently, logout } = useAuth0();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        (async () => {
            if (!isLoading) {
                try {
                    const fmhTokenResult = await getFmhToken({
                        accessToken: await getAccessTokenSilently(),
                    });

                    const accessToken = fmhTokenResult.access_token;
                    fmhAPI.base = fmhAPI.base.auth(`Bearer ${accessToken}`);

                    dispatch(
                        setAccessToken({
                            accessToken,
                            payerId: fmhTokenResult.npi,
                            segmentIds: fmhTokenResult.segment_ids,
                            permissions: fmhTokenResult.permissions,
                            restrictedAccess: fmhTokenResult.restricted_access,
                        }),
                    );

                    dispatch(wsConnect({ socketType: SocketType.NOTIFICATION }));

                    datadogRum.setUser({ id: user.email, email: user.email, name: user.name });
                    datadogRum.startSessionReplayRecording();
                    window.addEventListener('beforeunload', () => {
                        datadogRum.stopSessionReplayRecording();
                    });
                    datadogLogs.logger.info('User Authenticated', { user });
                    const redirectPath = sessionStorage.getItem(LOGIN_REDIRECT_KEY);
                    navigate(redirectPath ?? AppRoutes.home);
                } catch (err) {
                    handleError('Error authenticating.', err);
                    sessionStorage.clear();
                    await logout({
                        logoutParams: {
                            returnTo: `${window.location.origin}${AppRoutes.loggedOut}`,
                        },
                    });
                }
            }
        })();
    }, [isLoading, user, dispatch, getAccessTokenSilently, navigate, logout]);

    return <BlankScreen />;
};
