import { createApi } from '@reduxjs/toolkit/query/react';
import { formatNameLastFirst, sortAsc } from '@strata/helper-functions';

import { REFERENCE_API_URL } from 'api/APIBase';
import { FMH_NPI, FMH_UNMANAGED_NPI } from 'services/constants';
import { handleError, handleRTKQueryError } from 'services/Error';
import { getBaseQuery } from 'store/api/apiBaseQuery';

export const referenceApi = createApi({
    baseQuery: getBaseQuery(REFERENCE_API_URL),
    reducerPath: 'referenceApi',
    endpoints: (build) => ({
        getPayerDetails: build.query({
            query: ({ payerId }) => `payers/${payerId}`,
            transformResponse: (response) => ({
                payerId: response.payer_id,
                payerName: response.payer_name,
                payerEmail: response.payer_email,
                payerFeatures: response.features,
            }),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving payer details.',
            ),
        }),
        getPlaceOfServiceMap: build.query({
            query: () => 'product_benefit_configs/places',
            transformResponse: (response) =>
                Object.fromEntries(
                    (response ?? []).map((option) => [option.code, option.description]),
                ),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving place of service options.',
            ),
        }),
        getPrescriberDetails: build.query({
            query: ({ npi }) => `prescribers/${npi}`,
            transformResponse: (response) => ({
                npi: response.prescriber_npi,
                name: formatNameLastFirst(response.last_name, response.first_name),
                phone: response.telephone_number,
                fax: response.fax_number,
                address1: response.address_1,
                address2: response.address_2,
                city: response.city,
                state: response.state,
                zip: response.postal_code,
                specialization: response.specialization,
                practiceNpi: response.practice_npi,
                organizationName: response.organization_name,
            }),
            transformErrorResponse: (err) => {
                if (err.status !== 404) {
                    handleError('An error occurred retrieving prescriber details.', err);
                }
            },
        }),
        getSegments: build.query({
            query: ({ payerId }) => `payer_segments/payers/${payerId}`,
            transformResponse: (response) =>
                response
                    ?.map((segment) => ({
                        id: segment.segment_id,
                        name: segment.segment_name,
                    }))
                    .sort((a, b) => sortAsc(a.name, b.name)),
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving segment info.',
            ),
        }),
        getSupplierDetails: build.query({
            query: ({ npi }) => `suppliers/${npi}`,
            transformResponse: (response) => ({
                name: response?.organization_name,
                phone: response?.telephone_number,
                fax: response?.fax_number,
                email: response?.email,
                address1: response?.address_1,
                address2: response?.address_2,
                city: response?.city,
                state: response?.state,
                zip: response?.postal_code,
                npi: response?.supplier_npi,
                networkStatus: response?.network_status,
            }),
            transformErrorResponse: (err) => {
                if (err.status !== 404) {
                    handleError('An error occurred retrieving pharmacy details.', err);
                }
            },
        }),
        getSupplierMap: build.query({
            query: () => 'suppliers/',
            transformResponse: (response) => {
                const supplierMap = Object.fromEntries(
                    response.map((supplier) => [
                        supplier.supplier_npi,
                        {
                            name: supplier.organization_name,
                            networkStatus: supplier.network_status,
                        },
                    ]),
                );
                supplierMap[FMH_NPI] = {
                    name: 'Free Market Health',
                    networkStatus: 'ON',
                };
                supplierMap[FMH_UNMANAGED_NPI] = {
                    name: 'Unmanaged',
                    networkStatus: 'ON',
                };
                return supplierMap;
            },
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving supplier information.',
            ),
        }),
        // Gets list of all Product/Services
        getAllProductServices: build.query({
            query: () => '/productservices/summaryonly',
            transformResponse: (response) => {
                const productServiceList = (response ?? [])
                    .map((productService) => ({
                        oid: productService.oid,
                        name: productService.description,
                        secondaryData: productService.oid,
                    }))
                    .sort((a, b) => sortAsc(a.name, b.name));
                const productServiceMap = Object.fromEntries(
                    productServiceList.map(({ oid, name }) => [oid, name]),
                );
                return { productServiceList, productServiceMap };
            },
            transformErrorResponse: handleRTKQueryError(
                'An error occurred retrieving list of products.',
            ),
        }),
        getMedication: build.query({
            query: ({ oid }) => `productservices/${oid}`,
            transformResponse: (response) => ({
                description: response.description,
            }),
            transformErrorResponse: (err) => {
                if (err.status !== 404) {
                    handleError('An error occurred retrieving medication details.', err);
                }
            },
        }),
    }),
});

export const {
    useGetAllProductServicesQuery,
    useGetPayerDetailsQuery,
    useGetPlaceOfServiceMapQuery,
    useGetPrescriberDetailsQuery,
    useGetSegmentsQuery,
    useGetSupplierDetailsQuery,
    useGetSupplierMapQuery,
    useGetMedicationQuery,
} = referenceApi;
