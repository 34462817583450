import { createSlice } from '@reduxjs/toolkit';
import { sortDateDesc } from '@strata/helper-functions';
import { BaseNotification } from '@strata/react-notifications';

import { SocketType, wsSend } from 'services/socketHelpers';

const mapNotification = (notification) => ({
    id: notification.notification_id,
    type: notification.notification_type,
    linkingId: notification.linking_id,
    payerId: notification.payer_id,
    segmentId: notification.segment_id,
    timestamp: notification.notification_time,
    status: notification.interaction_status,
    additionalData: notification.additional_info,
});

export const notificationSlice = createSlice({
    name: 'notification',
    initialState: {
        notifications: [],
        incomingNotification: null,
        notificationsLoaded: false,
    },
    reducers: {
        addNotifications(state, action) {
            const { newNotifications } = action.payload;
            return {
                ...state,
                notifications: [...newNotifications, ...state.notifications],
                notificationsLoaded: true,
            };
        },
        setIncomingNotification(state, action) {
            const { incomingNotification } = action.payload;
            return { ...state, incomingNotification };
        },
        setNotificationStatuses(state, action) {
            const { notificationIds, status } = action.payload;
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    notificationIds.includes(notification.id)
                        ? {
                              ...notification,
                              status,
                          }
                        : notification,
                ),
            };
        },
        removeFromList(state, action) {
            const { notificationIds } = action.payload;
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => !notificationIds.includes(notification.id),
                ),
            };
        },
    },
});

export const {
    addNotifications,
    setIncomingNotification,
    setNotificationStatuses,
    removeFromList,
} = notificationSlice.actions;

export const receiveNewNotifications = (notifications) => async (dispatch, getState) => {
    const { notification } = getState();
    const existingNotificationIds = notification.notifications.map((obj) => obj.id);
    const newNotifications = Array.from(notifications)
        .filter(
            (newNotification) => !existingNotificationIds.includes(newNotification.notification_id),
        )
        .map(mapNotification)
        .sort((a, b) => sortDateDesc(a.timestamp, b.timestamp));

    if (notification.notificationsLoaded && newNotifications.length > 0) {
        newNotifications.forEach((incomingNotification) =>
            dispatch(setIncomingNotification({ incomingNotification })),
        );
    }

    dispatch(addNotifications({ newNotifications }));
};

export const updateNotificationStatuses = (status, notificationIds) => async (dispatch) => {
    dispatch(
        wsSend({
            socketType: SocketType.NOTIFICATION,
            messageType: status,
            message: notificationIds,
        }),
    );

    if (status === BaseNotification.Statuses.DELETED) {
        dispatch(removeFromList({ notificationIds }));
    } else {
        dispatch(setNotificationStatuses({ notificationIds, status }));
    }
};

export const updateNotificationStatus = (status, notificationId) => async (dispatch) => {
    dispatch(updateNotificationStatuses(status, [notificationId]));
};

export const clearIncomingNotification = () => (dispatch) => {
    dispatch(setIncomingNotification({ incomingNotification: null }));
};
