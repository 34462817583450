import { NewPayerRequestNotification } from 'components/notifications/NewPayerRequestNotification';

export const NotificationTypes = {
    PAYREQ_APERTURE: 'PAYREQ_AP',
    // Summary emails
    PAYREQSUM_APERTURE: 'PAYREQSUMA',
};

export const NotificationComponentMap = {
    [NotificationTypes.PAYREQ_APERTURE]: NewPayerRequestNotification,
};
