import { addCommas } from '@strata/helper-functions';
import { GridWithFilters, PrimaryData, SecondaryData } from '@strata/react-grid';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { TimeFrameBanner } from 'components/shared/TimeframeBanner';
import { ViewsControls } from 'components/shared/ViewsControls';
import { AppRoutes } from 'services/routes';
import { useGetServicingPharmaciesForProductQuery } from 'store/api/apertureApi';
import { Grids, resetGrid, useGridState } from 'store/grids/gridsSlice';
import { resetReferralData } from 'store/referrals/referralListSlice';

const initialSortProperties = [{ id: 'name', desc: false }];

const columns = [
    {
        accessorKey: 'name',
        header: 'Pharmacy Name',
        cell: ({ getValue }) => <PrimaryData data={getValue()} />,
    },
    {
        accessorKey: 'referrals',
        header: 'Active Referrals',
        cell: ({ getValue }) => <SecondaryData data={addCommas(getValue())} />,
    },
];

export const ServicingPharmaciesTab = ({ product = undefined }) => {
    const { oid, isBrand, referentPriceType } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const { segmentId } = useSelector((state) => state.views);
    const { data, isFetching } = useGetServicingPharmaciesForProductQuery({
        segmentId,
        productServiceOid: oid,
        isBrand: isBrand === 'true',
        referentPriceType,
    });

    const {
        sort,
        search,
        activeFilters,
        filterPaneWidth,
        updateGridSort,
        updateGridSearch,
        updateGridActiveFilters,
        updateFilterPaneWidth,
    } = useGridState(Grids.SERVICING_PHARMACIES, initialSortProperties);

    const referralsGridState = useGridState(Grids.REFERRALS);

    return (
        <GridWithFilters
            headerContent={<TimeFrameBanner />}
            viewsPaneContent={<ViewsControls />}
            data={data}
            isLoading={isFetching}
            sortProperties={sort}
            onSort={updateGridSort}
            enableSearch
            searchText={search}
            onSearch={updateGridSearch}
            activeFilters={activeFilters}
            onFilter={updateGridActiveFilters}
            filterPaneRenderWidth={filterPaneWidth}
            onFilterPaneResize={updateFilterPaneWidth}
            onRowClick={(rowData) => {
                dispatch(resetGrid(Grids.REFERRALS));
                dispatch(resetReferralData());
                referralsGridState.updateGridActiveFilters({
                    is_brand: [isBrand === 'true'],
                    product_service_description: [product],
                });
                navigate(AppRoutes.pharmacyDetails(rowData.npi), {
                    state: {
                        backToDestination: {
                            text: 'Product Details',
                            route: location,
                        },
                    },
                });
            }}
            columns={columns}
        />
    );
};

ServicingPharmaciesTab.propTypes = {
    product: PropTypes.string,
};
