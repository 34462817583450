import { formatDate, formatGender, formatPhoneNumber, getAge } from '@strata/helper-functions';
import { FormSectionWithRows } from '@strata/react-form-section';
import { MailToHyperlink } from '@strata/react-hyperlink';
import { AddressDetailLabelValue, DetailLabelValuePair } from '@strata/react-label-value-pairs';
import PropTypes from 'prop-types';

import { useGetMemberDetailsQuery } from 'store/api/scopeAssessorApi';

export const MemberDetails = ({ referralDetails = undefined }) => {
    const { data: memberDetails, isFetching: isMemberDetailsFetching } = useGetMemberDetailsQuery(
        {
            segmentId: referralDetails?.segmentId,
            memberId: referralDetails?.memberId,
            firstName: referralDetails?.memberFirstName,
            lastName: referralDetails?.memberLastName,
            dateOfBirth: referralDetails?.memberDOB,
            payerId: referralDetails?.payerId,
        },
        { skip: !(referralDetails?.memberId && referralDetails?.payerId) },
    );
    return (
        <FormSectionWithRows
            sectionTitle="Member Details"
            isLoading={isMemberDetailsFetching}
            hoverOverlayText="Member details are hidden. Hover to view information."
            rowOneContent={
                <>
                    <DetailLabelValuePair
                        style={{ width: '33%' }}
                        label="Gender"
                        value={formatGender(memberDetails?.gender)}
                    />
                    <DetailLabelValuePair
                        style={{ width: '33%' }}
                        label="Age"
                        value={getAge(memberDetails?.dateOfBirth)}
                    />
                    <DetailLabelValuePair
                        label="DOB"
                        value={formatDate(memberDetails?.dateOfBirth)}
                    />
                </>
            }
            rowTwoContent={
                <>
                    <DetailLabelValuePair
                        style={{ width: '33%' }}
                        label="Phone"
                        value={formatPhoneNumber(memberDetails?.phone)}
                    />
                    <DetailLabelValuePair
                        style={{ width: '33%' }}
                        label="Email"
                        value={<MailToHyperlink emailAddress={memberDetails?.email} />}
                    />
                    <AddressDetailLabelValue
                        label="Address"
                        address1={memberDetails?.addressLine1}
                        address2={memberDetails?.addressLine2}
                        city={memberDetails?.city}
                        state={memberDetails?.state}
                        zip={memberDetails?.zip}
                    />
                </>
            }
            rowThreeContent={
                <DetailLabelValuePair
                    style={{ width: '33%' }}
                    label="Group ID"
                    value={memberDetails?.benefitGroup}
                />
            }
        />
    );
};

MemberDetails.propTypes = {
    referralDetails: PropTypes.shape({
        segmentId: PropTypes.string,
        memberId: PropTypes.string,
        memberFirstName: PropTypes.string,
        memberLastName: PropTypes.string,
        memberDOB: PropTypes.string,
        payerId: PropTypes.string,
    }),
};
