import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { ErrorBoundary } from '@strata/react-error-pages';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { StrictMode } from 'react';
import { render } from 'react-dom';
import ReactGA from 'react-ga4';
import { Provider } from 'react-redux';

import { App } from 'components/App';
import { AuthProvider } from 'components/auth/AuthProvider';
import { handleError } from 'services/Error';
import { store } from 'store';

import 'main.scss';

datadogLogs.init({
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION,
    forwardErrorsToLogs: true,
});

datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_RUM_APP_ID,
    clientToken: process.env.REACT_APP_DATADOG_RUM_CLIENT_TOKEN,
    service: process.env.REACT_APP_DATADOG_SERVICE,
    env: process.env.REACT_APP_ENV,
    version: process.env.REACT_APP_VERSION,
    allowedTracingUrls: [process.env.REACT_APP_BASE_URL],
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    sessionReplaySampleRate: process.env.REACT_APP_ENV === 'prd' ? 100 : 0, // Only enable session replay in prd
    startSessionReplayRecordingManually: true,
});

ReactGA.initialize('G-W6ZN0N19G3');

(async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: process.env.REACT_APP_LD_CLIENT_ID,
    });

    render(
        <StrictMode>
            <ErrorBoundary onError={handleError}>
                <LDProvider>
                    <Provider store={store}>
                        <AuthProvider>
                            <App />
                        </AuthProvider>
                    </Provider>
                </LDProvider>
            </ErrorBoundary>
        </StrictMode>,
        document.getElementById('root'),
    );
})();
