import { datadogLogs } from '@datadog/browser-logs';
import { conditionalString } from '@strata/helper-functions';
import { ErrorToast } from '@strata/react-toast-message';

export const handleError = (message, error) => {
    // Parse and format the error string
    let parsedError;
    try {
        parsedError = JSON.parse(error?.message)?.detail;
    } catch {
        parsedError = { message: error?.message };
    } finally {
        const errorMessage = parsedError?.message || message;
        let errorMessageDetails = error?.data?.detail || parsedError?.details;
        errorMessageDetails = conditionalString(!!errorMessageDetails, ` ${errorMessageDetails}`);
        const displayMessage = `${errorMessage}${errorMessageDetails}`;

        ErrorToast({ message: displayMessage });
        datadogLogs.logger.error(displayMessage, { error });
        // eslint-disable-next-line no-console
        console.error(displayMessage, error);
    }
};

export const handleRTKQueryError = (message) => (error) => {
    handleError(message, error);
    return error;
};
