import { createSlice } from '@reduxjs/toolkit';

import { getCheckRestrictedGroup, getTreatmentsForPatient } from 'api/apertureAPI';
import { handleError } from 'services/Error';

const initialState = {
    lastSearchParams: {},
    searchResults: { treatments: undefined, restrictedAccess: undefined },
};

export const treatmentListSlice = createSlice({
    name: 'treatmentList',
    initialState,
    reducers: {
        setTreatmentSearchData(state, action) {
            const { searchParams, searchResults } = action.payload;
            return { ...state, lastSearchParams: searchParams, searchResults };
        },
        setRestrictedGroup(state, action) {
            const { restrictedGroup } = action.payload;
            return { ...state, searchResults: { ...state.searchResults, restrictedGroup } };
        },
        resetTreatmentSearchData(state) {
            const { lastSearchParams, searchResults } = initialState;
            return { ...state, lastSearchParams, searchResults };
        },
    },
});

export const {
    resetTreatmentData,
    setTreatmentSearchData,
    setRestrictedGroup,
    resetTreatmentSearchData,
} = treatmentListSlice.actions;

export const searchTreatments = (searchParams) => async (dispatch) => {
    try {
        const response = await getTreatmentsForPatient({
            memberId: searchParams.memberId,
            memberFirstName: searchParams.memberFirstName,
            memberLastName: searchParams.memberLastName,
            memberDob: searchParams.memberDob,
        });

        dispatch(
            setTreatmentSearchData({
                searchParams,
                searchResults: {
                    treatments: response?.treatments,
                },
            }),
        );
    } catch (err) {
        handleError('An error occurred retrieving treatments.', err);
    }
};

export const checkRestrictedGroup = (searchParams) => async (dispatch) => {
    const restrictedGroup = await getCheckRestrictedGroup({
        groupId: searchParams.groupId,
        memberId: searchParams.memberId,
        memberFirstName: searchParams.memberFirstName,
        memberLastName: searchParams.memberLastName,
        memberDob: searchParams.memberDob,
    });

    dispatch(setRestrictedGroup({ restrictedGroup }));
};
