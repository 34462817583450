import { RequestType } from '@strata/react-chat';

// Cookie key value
export const ENVIRONMENT_COOKIE_KEY = 'ENVIRONMENT';

// Session storage key value
export const LOGIN_REDIRECT_KEY = 'LOGIN_REDIRECT';

/** Payer request read access permissions */
export const PayerRequestReadPermissions = {
    READ_PAYER_REQUEST_ESCALATION: 'read:payer_request_escalation',
    READ_PAYER_REQUEST_CHANGE: 'read:payer_request_change',
    READ_PAYER_REQUEST_OTHER: 'read:payer_request_other',
};

/** Payer request write access permissions */
export const PayerRequestWritePermissions = {
    WRITE_PAYER_REQUEST_ESCALATION: 'write:payer_request_escalation',
    WRITE_PAYER_REQUEST_CHANGE: 'write:payer_request_change',
    WRITE_PAYER_REQUEST_OTHER: 'write:payer_request_other',
};

/** Aperture user permissions */
export const Permissions = {
    READ_SUMMARY: 'read:summary',
    READ_DETAIL: 'read:detail',
    READ_REFERRAL_RATES: 'read:referral_rates',
    READ_CLINICAL_POLICY: 'read:clinical_policy',
    ...PayerRequestReadPermissions,
    ...PayerRequestWritePermissions,
};

export const ReadPermissionRequestTypeMap = {
    [Permissions.READ_PAYER_REQUEST_ESCALATION]: RequestType.ESCALATION,
    [Permissions.READ_PAYER_REQUEST_CHANGE]: RequestType.CHANGE,
    [Permissions.READ_PAYER_REQUEST_OTHER]: RequestType.OTHER,
};

export const FMH_NPI = '9999999999';
export const FMH_UNMANAGED_NPI = '9888888888';
