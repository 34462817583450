import { capitalizeString } from '@strata/helper-functions';
import { FilterPane } from '@strata/react-filter-pane';
import { FormSection, FormSectionWithMessage } from '@strata/react-form-section';
import { Grid, KpiSection, PrimaryData } from '@strata/react-grid';
import { formatRateString } from '@strata/react-servicing-rate';
import PropTypes from 'prop-types';

import { ProductStatsColumns } from 'components/products/ProductStatsColumns';
import { TimeFrameBanner } from 'components/shared/TimeframeBanner';
import { ViewsControls } from 'components/shared/ViewsControls';
import { useGetPlaceOfServiceMapQuery } from 'store/api/referenceApi';

const Sections = {
    PLACE_OF_SERVICE: 'place_of_service',
    LINE_OF_BUSINESS: 'line_of_business',
    BENEFIT: 'benefit',
};

const initialSortProperties = [{ id: 'value', desc: false }];

const createDefaultValues = (values) => values?.map((value) => ({ value }));

const columns = (sectionTitle, sectionMap, key) => [
    {
        accessorKey: 'value',
        header: sectionTitle,
        size: 120,
        cell: ({ getValue }) => <PrimaryData data={sectionMap[key]?.formatValue(getValue())} />,
    },
    ...ProductStatsColumns,
];

export const ProductDetailsOverviewTab = ({ productDetails = undefined, isLoading = true }) => {
    const { data: placeOfServiceMap } = useGetPlaceOfServiceMapQuery();
    const kpiData = [
        {
            label: 'Active Referrals',
            value: () => productDetails?.numberOfReferrals,
        },
        {
            label: 'Baseline Rate',
            value: () =>
                formatRateString({
                    bidDecimal: productDetails?.baselineRate,
                    referentPriceType: productDetails?.referentPriceType,
                }),
        },
        {
            label: 'Avg Servicing Rate',
            value: () =>
                formatRateString({
                    bidDecimal: productDetails?.avgWinningBidRate,
                    referentPriceType: productDetails?.referentPriceType,
                }),
        },
    ];

    const sectionMap = {
        [Sections.PLACE_OF_SERVICE]: {
            title: 'Place of Service',
            formatValue: (value) => placeOfServiceMap?.[value],
        },
        [Sections.LINE_OF_BUSINESS]: {
            title: 'Line of Business',
            formatValue: (value) => capitalizeString(value ?? 'Not Specified'),
        },
        [Sections.BENEFIT]: {
            title: 'Benefit',
            defaultValues: createDefaultValues(['MEDICAL', 'PHARMACY']),
            formatValue: capitalizeString,
        },
    };

    return (
        <FilterPane
            viewsPaneContent={<ViewsControls />}
            mainContent={
                <>
                    <TimeFrameBanner />
                    <KpiSection className="margin__bottom--medium" kpiData={kpiData} />
                    {Object.values(Sections).map((key) => {
                        const sectionTitle = sectionMap[key]?.title;
                        const values =
                            productDetails?.stats?.find((stat) => stat.key === key)?.values ?? [];
                        return !isLoading && values.length === 0 ? (
                            <FormSectionWithMessage
                                key={key}
                                className="margin__bottom--medium"
                                sectionTitle={sectionTitle}
                                secondaryText={`No data to display for ${sectionTitle}`}
                            />
                        ) : (
                            <FormSection
                                key={key}
                                className="margin__bottom--medium"
                                isLoading={isLoading}
                                sectionTitle={sectionTitle}
                                content={
                                    <Grid
                                        sortProperties={initialSortProperties}
                                        data={[
                                            ...values,
                                            ...(sectionMap[key]?.defaultValues?.filter(
                                                ({ value }) =>
                                                    !values.find((item) => value === item.value),
                                            ) ?? []),
                                        ]}
                                        columns={columns(sectionTitle, sectionMap, key)}
                                    />
                                }
                            />
                        );
                    })}
                </>
            }
        />
    );
};

ProductDetailsOverviewTab.propTypes = {
    productDetails: PropTypes.shape({
        numberOfReferrals: PropTypes.number,
        baselineRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        avgWinningBidRate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        referentPriceType: PropTypes.string,
        stats: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    isLoading: PropTypes.bool,
};
