import { BenefitTypes } from '@strata/constants';
import { formatDate } from '@strata/helper-functions';
import { TreatmentRequestsIcon } from '@strata/react-chat';
import { FormSectionWithRows } from '@strata/react-form-section';
import { DetailLabelValuePair } from '@strata/react-label-value-pairs';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { Permissions } from 'services/constants';
import { useGetRequestsForTreatmentQuery } from 'store/api/payerRequestApi';
import { useGetNeedsByDateQuery } from 'store/api/performanceApi';
import { useGetPlaceOfServiceMapQuery } from 'store/api/referenceApi';

export const ReferralDetails = ({ referralDetails = undefined }) => {
    const { payerId, permissions } = useSelector((state) => state.auth);
    const canSeeMemberEscalations = permissions.includes(Permissions.READ_PAYER_REQUEST_ESCALATION);

    const { data: placeOfServiceMap } = useGetPlaceOfServiceMapQuery();
    const { data: treatmentRequests } = useGetRequestsForTreatmentQuery(
        { payerId, treatmentId: referralDetails?.treatmentId },
        { skip: !(canSeeMemberEscalations && referralDetails?.treatmentId) },
    );
    const { data: needsByDate } = useGetNeedsByDateQuery(
        {
            treatmentId: referralDetails?.treatmentId,
        },
        {
            skip: !(
                referralDetails?.treatmentId && referralDetails?.benefit === BenefitTypes.PHARMACY
            ),
        },
    );
    return (
        <FormSectionWithRows
            sectionTitle="Referral Details"
            isLoading={!referralDetails}
            rowOneContent={
                <>
                    <DetailLabelValuePair
                        style={{ width: '33%' }}
                        label="Referral ID"
                        value={referralDetails?.friendlyId}
                    />
                    <DetailLabelValuePair
                        style={{ width: '33%' }}
                        label="Place Of Service"
                        value={placeOfServiceMap?.[referralDetails?.placeOfService]}
                    />
                    <DetailLabelValuePair
                        style={{ width: '34%' }}
                        label="Next Fill Needs By Date"
                        value={formatDate(needsByDate?.needsByDate)}
                    />
                </>
            }
            rowTwoContent={
                <>
                    <DetailLabelValuePair
                        style={{ width: '33%' }}
                        label="Referral Expiration"
                        value={formatDate(referralDetails?.referralExpiration)}
                    />
                    {canSeeMemberEscalations && treatmentRequests?.length > 0 && (
                        <DetailLabelValuePair
                            style={{ width: '33%' }}
                            label="Payer Requests"
                            value={<TreatmentRequestsIcon requests={treatmentRequests} />}
                        />
                    )}
                </>
            }
        />
    );
};

ReferralDetails.propTypes = {
    referralDetails: PropTypes.shape({
        treatmentId: PropTypes.string,
        benefit: PropTypes.string,
        friendlyId: PropTypes.string,
        placeOfService: PropTypes.string,
        clinicalInfo: PropTypes.shape({
            pointOfContact: PropTypes.string,
            pointOfContactPhone: PropTypes.string,
        }),
        referralExpiration: PropTypes.string,
    }),
};
