import { useAuth0 } from '@auth0/auth0-react';
import { LoggedOutScreen } from '@strata/react-page-templates';

import { Header } from 'components/header/Header';

export const LoggedOut = () => {
    const { loginWithRedirect } = useAuth0();
    return (
        <>
            <Header />
            <LoggedOutScreen
                appName={LoggedOutScreen.Logos.APERTURE}
                logInOnClick={() => loginWithRedirect()}
            />
        </>
    );
};
