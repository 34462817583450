import { IconNames } from '@blueprintjs/icons';
import { RequestType, RequestTypeDisplayTextMap } from '@strata/react-chat';
import { DetailLabelValuePair } from '@strata/react-label-value-pairs';
import { BaseNotification } from '@strata/react-notifications';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'services/routes';
import { useUserNameMap } from 'store/api/authApi';

export const NewPayerRequestNotification = ({
    linkingId,
    timestamp,
    additionalData,
    onNotificationClick,
    onRemoveNotificationClick,
    status,
    small,
}) => {
    const navigate = useNavigate();
    const { payerId } = useSelector((state) => state.auth);

    const userNameMap = useUserNameMap({ payerId });

    const getBodyText = () => {
        let secondaryText = '';
        if ([RequestType.ESCALATION, RequestType.CHANGE].includes(additionalData.requestType)) {
            secondaryText = additionalData.requestSubType;
        }
        if (additionalData.requestType === RequestType.OTHER) {
            secondaryText = additionalData.additionalNotes;
        }
        return `${RequestTypeDisplayTextMap[additionalData.requestType]} | ${secondaryText}`;
    };

    return (
        <BaseNotification
            notificationTitle="New Request"
            icon={IconNames.CHAT}
            bodyText={getBodyText()}
            timestamp={timestamp}
            onNotificationClick={() => {
                onNotificationClick();
                navigate(AppRoutes.requestDetails(linkingId));
            }}
            onRemoveNotificationClick={onRemoveNotificationClick}
            type={BaseNotification.Types.INFO}
            status={status}
            additionalData={
                <div className="flex__gap--small" style={{ flexDirection: 'column' }}>
                    <div className="sds_payer-request-notification__created-by">{`Created by ${userNameMap?.[additionalData.submitterUpn] || additionalData.submitterUpn}`}</div>
                    {!small && (
                        <DetailLabelValuePair
                            label="Request #"
                            value={additionalData.requestNumber?.toString().padStart(6, '0')}
                        />
                    )}
                </div>
            }
        />
    );
};

NewPayerRequestNotification.propTypes = {
    linkingId: PropTypes.string.isRequired,
    timestamp: PropTypes.string.isRequired,
    onNotificationClick: PropTypes.func.isRequired,
    onRemoveNotificationClick: PropTypes.func.isRequired,
    status: PropTypes.oneOf(Object.values(BaseNotification.Statuses)).isRequired,
    small: PropTypes.bool.isRequired,
    additionalData: PropTypes.shape({
        requestNumber: PropTypes.number.isRequired,
        requestType: PropTypes.string.isRequired,
        requestSubType: PropTypes.string,
        additionalNotes: PropTypes.string,
        submitterUpn: PropTypes.string.isRequired,
    }).isRequired,
};
