/**
 * Maps treatment member summary from API.
 * @param {*} member - The member object from the API.
 * @returns {MemberSummary} The treatment member summary object.
 */
export const mapMemberSummary = (member) => ({
    memberId: member.member_id,
    firstName: member.firstname,
    lastName: member.lastname,
    gender: member.gender,
    dateOfBirth: member.dateofbirth,
    benefitGroup: member.benefit_group,
    addressLine1: member.member_address1,
    addressLine2: member.member_address2,
    city: member.member_city,
    state: member.member_state,
    zip: member.member_zip,
    phone: member.phone,
    email: member.email,
    hasSecondaryCoverage: member.secondary_coverage_flag ? 'Yes' : 'No',
    lineOfBusiness: member.line_of_business,
    lobSegment: member.lob_segment,
});
