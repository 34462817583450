import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Given a default return destination, provide a function to navigate to the previous page.
 * If the current page is the first in the history stack, use the default destination from
 * `backToDestinationDefault`.
 * @param {Object} backToDestinationDefault - The default return destination.
 * @param {string} backToDestinationDefault.route - The route for the default return destination.
 * @param {string} backToDestinationDefault.text - The display name for the default return destination.
 * default behavior for `goBack` function.
 * @returns {{ backToDestination: { text: string, route: string = undefined }, goBack: (replace: bool = false) => void }} - The return destination and function for navigating to it
 */
export const useGoBack = (backToDestinationDefault) => {
    const location = useLocation();
    const navigate = useNavigate();

    // If current location was navigated to internally:
    // 1. If the location state has a 'backToDestination.route', use it.
    // 2. Else, use delta of -1 to go to prevous location record.
    // Else, use default route.
    let backToDestination;
    if (location.state?.backToDestination) {
        backToDestination = {
            route: -1,
            ...location.state?.backToDestination,
        };
    } else {
        backToDestination = backToDestinationDefault;
    }
    const goBack = useCallback(
        (replace = false) =>
            navigate(backToDestination.route, {
                replace,
            }),
        [backToDestination, navigate],
    );

    return { backToDestination, goBack };
};
