import { ActionButtonText } from '@strata/react-buttons';
import { MemberEscalationSubType, RequestType } from '@strata/react-chat';
import { CheckboxListFormik } from '@strata/react-checkbox-list';
import { DateInputFormik } from '@strata/react-date-time';
import { Hyperlink } from '@strata/react-hyperlink';
import { InlineMessage } from '@strata/react-inline-message';
import { TextInputFormik } from '@strata/react-inputs';
import { SuggestSingleSelectFormik } from '@strata/react-selects';
import { validationStrings } from '@strata/strata-ui-strings';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import { getCheckRestrictedGroup, getTreatmentsForPatient } from 'api/apertureAPI';
import { handleError } from 'services/Error';
import { AppRoutes } from 'services/routes';
import { useGetAllProductServicesQuery } from 'store/api/referenceApi';

// eslint-disable-next-line complexity
export const MemberEscalation = ({ openedFromTreatment }) => {
    const formik = useFormikContext();
    const { restrictedAccess } = useSelector((state) => state.auth);
    const { data: products } = useGetAllProductServicesQuery();
    return formik.values.requestType === RequestType.ESCALATION ? (
        <div className="margin__bottom--medium">
            <SuggestSingleSelectFormik
                id="requestSubType"
                label="Please Select One Of The Following"
                values={Object.values(MemberEscalationSubType).filter(
                    (val) =>
                        ![
                            // Compass only escalation sub types
                            MemberEscalationSubType.RESOLVE_AUTH_DATA_ISSUE,
                            MemberEscalationSubType.RESOLVE_NO_BID_APPROVAL,
                        ].includes(val),
                )}
                required
                verticalSpacingSmall
            />
            <div className="sds_label font-semi-bold--uppercase margin__bottom--medium">
                Member Lookup
            </div>
            <TextInputFormik
                id="memberId"
                label="Member ID"
                required
                verticalSpacingSmall
                disabled={!!formik.values.treatments}
            />
            <div className="flex__gap--medium">
                <TextInputFormik
                    id="firstName"
                    label="Member First Name"
                    required
                    verticalSpacingSmall
                    disabled={!!formik.values.treatments}
                />
                <TextInputFormik
                    id="lastName"
                    label="Member Last Name"
                    required
                    verticalSpacingSmall
                    disabled={!!formik.values.treatments}
                />
                <DateInputFormik
                    id="dob"
                    label="Member DOB"
                    required
                    verticalSpacingSmall
                    hideCalendarPopover
                    disabled={!!formik.values.treatments}
                />
            </div>
            {!restrictedAccess && formik.values.treatments?.length === 0 && (
                <>
                    <TextInputFormik
                        id="groupId"
                        label="Member Group ID"
                        required
                        verticalSpacingSmall
                        disabled={formik.values.restrictedGroup != null}
                    />
                    {formik.values.restrictedGroup == null && (
                        <ActionButtonText
                            label="Submit Group ID"
                            className="margin__bottom--xx-small"
                            disabled={!!formik.errors.groupId}
                            onClick={async () => {
                                try {
                                    const restrictedGroup = await getCheckRestrictedGroup({
                                        groupId: formik.values.groupId,
                                        memberId: formik.values.memberId,
                                        memberFirstName: formik.values.firstName,
                                        memberLastName: formik.values.lastName,
                                        memberDob: formik.values.dob,
                                    });
                                    await formik.setFieldValue('restrictedGroup', restrictedGroup);
                                } catch (err) {
                                    handleError('An error occurred during member lookup.', err);
                                }
                            }}
                        />
                    )}
                    {formik.values.restrictedGroup === true && (
                        <div className="margin__bottom--medium">
                            <InlineMessage
                                wrapText
                                intent={InlineMessage.Intent.WARNING}
                                label="Restricted Access: You cannot create requests for restricted members."
                            />
                        </div>
                    )}
                </>
            )}
            {formik.values.treatments?.length === 0 &&
                (restrictedAccess || formik.values.restrictedGroup === false) && (
                    <div className="margin__bottom--medium">
                        <InlineMessage
                            wrapText
                            intent={InlineMessage.Intent.WARNING}
                            label="No treatments found for member."
                        />
                    </div>
                )}
            {!formik.values.treatments && (
                <ActionButtonText
                    label="Lookup Member"
                    className="margin__bottom--medium"
                    disabled={
                        !!(
                            formik.errors.memberId ||
                            formik.errors.firstName ||
                            formik.errors.lastName ||
                            formik.errors.dob
                        )
                    }
                    onClick={async () => {
                        try {
                            const result = await getTreatmentsForPatient({
                                memberId: formik.values.memberId,
                                memberFirstName: formik.values.firstName,
                                memberLastName: formik.values.lastName,
                                memberDob: formik.values.dob,
                            });
                            await formik.setFieldValue('treatments', result.treatments);
                        } catch (err) {
                            handleError('An error occurred during member lookup.', err);
                        }
                    }}
                />
            )}
            {!!formik.values.treatments && !openedFromTreatment && (
                <ActionButtonText
                    label="Reset Member Lookup"
                    className="margin__bottom--medium"
                    onClick={() =>
                        formik.setValues({
                            ...formik.values,
                            memberId: undefined,
                            firstName: undefined,
                            lastName: undefined,
                            dob: undefined,
                            treatments: undefined,
                            groupId: undefined,
                            restrictedGroup: undefined,
                        })
                    }
                />
            )}
            {formik.values.treatments?.length > 0 && (
                <>
                    <div className="sds_label font-semi-bold--uppercase margin__bottom--small">
                        Select Treatments(s)
                    </div>
                    <CheckboxListFormik
                        name="selectedTreatments"
                        label=""
                        disabled={openedFromTreatment}
                        verticalSpacingSmall
                        options={formik.values.treatments.map((treatment) => ({
                            value: treatment.treatment_id,
                            component: (
                                <div
                                    className="flex__gap--small"
                                    style={{ display: 'inline-flex', alignItems: 'center' }}
                                >
                                    <span>
                                        {products?.productServiceMap[treatment.product_service_oid]}
                                    </span>
                                    <Hyperlink
                                        navUrl={AppRoutes.treatmentDetails(treatment.treatment_id)}
                                        openInNewTab
                                        linkText={<ActionButtonText label="View Treatment" />}
                                    />
                                </div>
                            ),
                        }))}
                    />
                </>
            )}
        </div>
    ) : null;
};

MemberEscalation.propTypes = {
    openedFromTreatment: PropTypes.bool.isRequired,
};

MemberEscalation.initialValues = (treatmentDetails) => ({
    memberId: treatmentDetails.memberDetails.memberId,
    firstName: treatmentDetails.memberDetails.firstName,
    lastName: treatmentDetails.memberDetails.lastName,
    dob: treatmentDetails.memberDetails.dateOfBirth,
    treatments: [
        {
            treatment_id: treatmentDetails.treatmentId,
            product_service_oid: treatmentDetails.productServiceOid,
        },
    ],
    selectedTreatments: [treatmentDetails.treatmentId],
    groupId: treatmentDetails.memberDetails?.benefitGroup,
});

MemberEscalation.validationSchema = ({ restrictedAccess }) => ({
    memberId: Yup.string().when('requestType', {
        is: RequestType.ESCALATION,
        then: (schema) => schema.required(validationStrings.requiredField.errorMessage),
    }),
    firstName: Yup.string().when('requestType', {
        is: RequestType.ESCALATION,
        then: (schema) => schema.required(validationStrings.requiredField.errorMessage),
    }),
    lastName: Yup.string().when('requestType', {
        is: RequestType.ESCALATION,
        then: (schema) => schema.required(validationStrings.requiredField.errorMessage),
    }),
    dob: DateInputFormik.validator().when('requestType', {
        is: RequestType.ESCALATION,
        then: (schema) => schema.required(validationStrings.requiredField.errorMessage),
    }),
    treatments: Yup.array().when('requestType', {
        is: RequestType.ESCALATION,
        then: (schema) => schema.required(validationStrings.requiredField.errorMessage),
    }),
    selectedTreatments: Yup.array(),
    ...(!restrictedAccess
        ? {
              groupId: Yup.string().when(['requestType', 'treatments'], {
                  is: (requestType, treatments) =>
                      requestType === RequestType.ESCALATION && treatments?.length === 0,
                  then: (schema) => schema.required(validationStrings.requiredField.errorMessage),
              }),
              restrictedGroup: Yup.bool().when(['requestType', 'treatments'], {
                  is: (requestType, treatments) =>
                      requestType === RequestType.ESCALATION && treatments?.length === 0,
                  then: (schema) => schema.required().notOneOf([true]),
              }),
          }
        : undefined),
});
