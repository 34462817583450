import { IconNames } from '@blueprintjs/icons';
import { DateFormats, formatDate, formatNameLastFirst } from '@strata/helper-functions';
import { ActionButtonText, Button } from '@strata/react-buttons';
import { DateInputFormik } from '@strata/react-date-time';
import { FormPageContainer } from '@strata/react-form-header-and-content';
import { FormSection } from '@strata/react-form-section';
import { Grid, PrimaryData } from '@strata/react-grid';
import { TextInputFormik } from '@strata/react-inputs';
import { MessagingBlock } from '@strata/react-messaging-block';
import { PageTitle } from '@strata/react-page-title';
import { TreatmentStatusIndicator } from '@strata/react-status-indicators';
import { validationStrings } from '@strata/strata-ui-strings';
import dayjs from 'dayjs';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { AdditionalSearchInfoDialog } from 'components/treatments/dialogs/AdditionalSearchInfoDialog';
import { AppRoutes } from 'services/routes';
import { useGetAllProductServicesQuery } from 'store/api/referenceApi';
import { resetTreatmentSearchData, searchTreatments } from 'store/treatments/treatmentListSlice';

const columns = [
    {
        accessorKey: 'member',
        header: 'Member',
        size: 100,
        cell: ({ getValue }) => <PrimaryData data={getValue()} />,
    },
    {
        accessorKey: 'memberId',
        header: 'Member ID',
        size: 100,
    },
    {
        accessorKey: 'product',
        header: 'Product',
        size: 100,
    },
    {
        accessorKey: 'treatmentStatus',
        header: 'Status',
        size: 100,
        cell: ({ getValue }) => <TreatmentStatusIndicator status={getValue()} />,
    },
];

export const MemberSearchPage = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { data: products } = useGetAllProductServicesQuery();

    const { lastSearchParams, searchResults } = useSelector((state) => state.treatmentList);

    const mapTreatment = (treatment) => ({
        treatmentId: treatment.treatment_id,
        payerId: treatment.payer_id,
        memberId: treatment.member_id,
        member: formatNameLastFirst(treatment.member_last_name, treatment.member_first_name),
        product: products?.productServiceMap?.[treatment.product_service_oid],
        treatmentStatus: treatment.treatment_status,
    });

    return (
        <FormPageContainer>
            <AdditionalSearchInfoDialog />
            <Formik
                initialValues={lastSearchParams}
                validationSchema={Yup.object().shape(
                    {
                        memberFirstName: Yup.string().when('memberId', {
                            is: (memberId) => !memberId,
                            then: (schema) =>
                                schema.required(validationStrings.requiredField.errorMessage),
                        }),
                        memberLastName: Yup.string().when('memberId', {
                            is: (memberId) => !memberId,
                            then: (schema) =>
                                schema.required(validationStrings.requiredField.errorMessage),
                        }),
                        memberDob: DateInputFormik.validator().when('memberId', {
                            is: (memberId) => !memberId,
                            then: (schema) =>
                                schema
                                    .required(validationStrings.requiredField.errorMessage)
                                    .max(
                                        dayjs().endOf('day'),
                                        'Date of birth cannot be in the future.',
                                    ),
                        }),
                        memberId: Yup.string().when(
                            ['memberFirstName', 'memberLastName', 'memberDob'],
                            {
                                is: (memberFirstName, memberLastName, memberDob) =>
                                    !(memberFirstName || memberLastName || memberDob),
                                then: (schema) =>
                                    schema.required(validationStrings.requiredField.errorMessage),
                            },
                        ),
                    },
                    [
                        ['memberId', 'memberFirstName'],
                        ['memberId', 'memberLastName'],
                        ['memberId', 'memberDob'],
                    ],
                )}
                onSubmit={async (values) => {
                    await dispatch(
                        searchTreatments({
                            ...values,
                            memberDob: formatDate(values.memberDob, DateFormats.HYPHEN_DATE),
                        }),
                    );
                }}
                enableReinitialize
                validateOnMount
            >
                {(formik) => (
                    <div className="sds_member-search">
                        <FormSection
                            sectionTitle="Member Search"
                            content={
                                <Form className="sds_member-search__form">
                                    <TextInputFormik
                                        id="memberId"
                                        label="Member ID"
                                        verticalSpacingSmall
                                        required
                                        onChange={(e) =>
                                            formik.setValues({
                                                memberId: e.target.value,
                                            })
                                        }
                                    />
                                    <span className="sds_member-search__form-separator">or</span>
                                    <TextInputFormik
                                        id="memberFirstName"
                                        label="Member First Name"
                                        verticalSpacingSmall
                                        required
                                        onChange={(e) =>
                                            formik.setValues({
                                                ...formik.values,
                                                memberFirstName: e.target.value,
                                                memberId: undefined,
                                            })
                                        }
                                    />
                                    <TextInputFormik
                                        id="memberLastName"
                                        label="Member Last Name"
                                        verticalSpacingSmall
                                        required
                                        onChange={(e) =>
                                            formik.setValues({
                                                ...formik.values,
                                                memberLastName: e.target.value,
                                                memberId: undefined,
                                            })
                                        }
                                    />
                                    <DateInputFormik
                                        id="memberDob"
                                        label="Member DOB"
                                        verticalSpacingSmall
                                        required
                                        hideCalendarPopover
                                        onChange={(memberDob) =>
                                            formik.setValues({
                                                ...formik.values,
                                                memberDob,
                                                memberId: undefined,
                                            })
                                        }
                                    />
                                    <Button
                                        className="sds_member-search__form-button"
                                        type="submit"
                                        label="Search"
                                        disabled={formik.isSubmitting || !formik.isValid}
                                        intent={Button.Intent.PRIMARY}
                                    />
                                    <ActionButtonText
                                        className="sds_member-search__form-button"
                                        style={{ height: 30 }}
                                        label="Clear Search"
                                        onClick={() => {
                                            dispatch(resetTreatmentSearchData());
                                            formik.resetForm();
                                        }}
                                    />
                                </Form>
                            }
                        />
                        <PageTitle title="Member Treatments" />
                        <div className="sds_member-search__results">
                            <Grid
                                enableSorting
                                sortProperties={[
                                    {
                                        id: 'member',
                                        desc: false,
                                    },
                                ]}
                                data={
                                    searchResults?.restrictedGroup
                                        ? []
                                        : searchResults?.treatments?.map(mapTreatment)
                                }
                                isLoading={formik.isSubmitting}
                                noResults={
                                    <div className="sds_no-results">
                                        {searchResults?.restrictedGroup ? (
                                            <MessagingBlock
                                                icon={IconNames.LOCK}
                                                size={MessagingBlock.Sizes.MEDIUM}
                                                primaryText="Restricted Access"
                                                secondaryText="No permission to view this member"
                                            />
                                        ) : (
                                            <MessagingBlock
                                                size={MessagingBlock.Sizes.MEDIUM}
                                                icon={IconNames.SEARCH}
                                                primaryText="No members to show"
                                                secondaryText="Use the search above to find members"
                                            />
                                        )}
                                    </div>
                                }
                                columns={columns}
                                onRowClick={(rowData) => {
                                    navigate(AppRoutes.treatmentDetails(rowData.treatmentId), {
                                        state: {
                                            backToDestination: {
                                                text: 'Member Search',
                                            },
                                        },
                                    });
                                }}
                            />
                        </div>
                    </div>
                )}
            </Formik>
        </FormPageContainer>
    );
};
