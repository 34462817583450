import { getCookie, saveCookie } from '@strata/cookies';
import { useEffect, useState } from 'react';
import wretch from 'wretch';

import { ENVIRONMENT_COOKIE_KEY } from 'services/constants';
import { handleError } from 'services/Error';

export const useAuth0Info = () => {
    const [auth0Info, setAuth0Info] = useState(getCookie(ENVIRONMENT_COOKIE_KEY));

    useEffect(() => {
        if (!auth0Info?.clientId) {
            (async () => {
                try {
                    setAuth0Info(
                        await wretch(
                            `${process.env.REACT_APP_BASE_URL}/authorization/authentication_info/aperture/${process.env.REACT_APP_ENV}`,
                        )
                            .get()
                            .json((result) => {
                                const info = {
                                    domain: result.domain,
                                    clientId: result.client_id,
                                    audience: result.audience,
                                };

                                saveCookie(ENVIRONMENT_COOKIE_KEY, info, 1);
                                return info;
                            }),
                    );
                } catch (err) {
                    handleError('Error loading environment.', err);
                }
            })();
        }
    }, [auth0Info]);

    return auth0Info;
};
